import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import norts from '../../../assets/images/norts.jpg';



const ClosingCeremony = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={8} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>Closing Ceremony of Android Apps Development Training Program at NSU</h5>
            <span className='date-title'>Dated : 15 January, 2014</span>
            <p className='line-h'>
            গত ১৫ জানুয়ারী ২০১৪, মঙ্গলবার নর্থ সাউথ ইউনিভার্সিটিতে অনুষ্ঠিত হয়ে গেল জাতীয় পর্যায়ে মোবাইল এ্যাপ্লিকেশন উন্নয়নে সচেতনতা ও দক্ষতা বৃদ্ধি কর্মসূচীর সমাপনী অনুষ্ঠান। এই অনুষ্ঠানে প্রধান অতিথি হিসেবে উপস্থিত ছিলেন তথ্য ও যোগাযোগ প্রযুক্তি মন্ত্রণালয়ের সচিব জনাব নজরুল ইসলাম খান, একই মন্ত্রণালয়ের ডেপুটি সেক্রেটারি ও প্রকল্প পরিচালক জনাব ড. মোহাম্মদ আবুল হাসান, নর্থ সাউথ ইউনিভার্সিটির প্রক্টর ড. কাজী আবু সাঈদ, স্কুল অফ ইঞ্জিনিয়ারিং এর ডিন ড. মিফতাউর রহমান, ইলেকট্রিক এন্ড কম্পিউটার ইঞ্জিনিয়ারিং বিভাগের চেয়ারম্যান ড. কাজী আবদুস সালাম, ইএটিএল-এর ব্যবস্থাপনা পরিচালক এম এ মুবিন খান সহ প্রমুখ। 
            </p>

            <p className='line-h'>
            ঢাকা জেলায় অবস্থিত বিভিন্ন বিশ্ববিদ্যালয়ের ছাত্র-ছাত্রীরা প্রতিযোগীতামূলক পরীক্ষার মাধ্যমে প্রায় ৪২ জন এই কোর্সের জন্য নির্বাচিত হয়েছিলেন। কর্মসূচি শেষে তাদের ৩২ জনকে সার্টিফিকেট প্রদান করা হয়। এছাড়াও ব্যবহারিক ক্লাশের মাধ্যমে কর্মশালার শেষ দিকে প্রশিক্ষণার্থীরা এ্যাপ্লিকেশন তৈরি করেন। জাতীয় পর্যায়ের এ্যাপ্লিকেশন নির্মাণ প্রতিযোগীতায় এ সকল প্রশিক্ষণার্থীরা অংশ গ্রহন করতে পারবে।
            </p>
            <p className='line-h'>
            পুরো প্রকল্পে তথ্য ও যোগাযোগ প্রযুক্তি মন্ত্রণালয়ের সহযোগী প্রতিষ্ঠান হিসেবে ইএটিএল ও এমসিসির সাথে কাজ করছে বেসিস, মাইক্রোসফট, গ্রামীণফোন, রবি, নোকিয়া, সিম্ফনি, এসওএল কোয়েস্ট ও গুগল ডেভলাপার গ্রুপ ঢাকা।
            </p>
         </div>
         </Col>
         <Col md={4} data-aos="fade-left">
         <img className='news-details' src={norts} alt="com" />
         </Col>
      </Row>

   </Container>
</Common>
);
};
export default ClosingCeremony;