import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import efdms from '../../../assets/images/nbrnew.jpg.png';

const EFDMS = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Supply, Installation and
                                Commissioning and
                                Operation of Electronic
                                Fiscal Device
                                Management Systems
                                (EFDMS) along with
                                Electronic Fiscal Device
                                (EFD), Sales Data
                                Controller (SDC) and
                                Managed Services</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Projects</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={8}>
                        <div className='about-company mt-70 left-0' data-aos="fade-right">
                            <h5>Supply, Installation and Commissioning and Operation of Electronic Fiscal Device Management Systems (EFDMS) along with Electronic Fiscal Device (EFD), Sales Data Controller (SDC) and Managed Services</h5>
                            <p>
                                EATL-SZZT-KMMT-Synesis IT JV started a nationwide project titled "Supply, Installation and Commissioning and Operation of Electronic Fiscal Device Management Systems (EFDMS) along with Electronic Fiscal Device (EFD), Sales Data Controller (SDC) and Managed Services" for National Board of Revenue (NBR) of Ministry of Finance of Bangladesh on September 2019. This micro-service architecture-based software development was the first ever in Bangladesh and was successfully completed in August 2020. Total value of this project was about $36 million USD. The purpose of this assignment was to capture and store the transaction data of the retail/ wholesale outlets in the country.
                            </p>

                            <h5 className='mt-4'>Name of Client:</h5>
                            <p>
                                National Board of
                                Revenue (NBR)
                            </p>

                        </div>

                    </Col>
                    <Col md={4} data-aos="fade-left">

                        <div className='about-company mt-70'>
                            <img className='nu-all mt-5' src={efdms} alt="efdms" />

                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default EFDMS;