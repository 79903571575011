import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import locb from '../../../assets/images/locb.png';
import lob2 from '../../../assets/images/lob2.png';
const LBS = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>LBS (Location Based Services)</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Service</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>LBS (Location Based Services)</h5>
                            <p className='line-h'>
                                Location-based services (LBS) are a general class of computer program-level services used to include specific controls for location and time data as control features in computer programs. LBS are used in a variety of contexts, such as health, indoor object search, entertainment, work, personal life, etc. With over 770 million GPS-enabled smartphones, location data has begun to permeate the entire mobile space. The possibilities for location-based services or LBS on mobile go beyond the apps like Google maps or Location based reminder.  The ability to collect user location data and track it has given us a good opportunity to develop mobile applications for different organizations those collect data from remote places. Since we have been working on mobile applications for a long time in Bangladesh, our expertise on providing location based service is much better than others.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} data-aos="fade-left ">
                        <Row className='mt-70'>
                            <Col md={6}>
                                <img className='services-box' src={locb} alt="locb" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={lob2} alt="lob2" />
                            </Col>

                        </Row>
                    </Col>

                    <Col md={12} data-aos="fade-left ">
                    <p>
                    EATL has developed a project in collaboration with icddr,b to collect field level data from mobile applications from the field-workers in remote part of the countries. The health workers of several NGOs and icddr,b as well as some participants from MoHFW (field level) joined the project to send data from various locations of the country and the data was geo-tagged. This was a breakthrough 
                    </p>
                  
                    <p>
                    EATL has developed a project in collaboration with icddr,b to collect field level data from mobile applications from the field-workers in remote part of the countries. The health workers of several NGOs and icddr,b as well as some participants from MoHFW (field level) joined the project to send data from various locations of the country and the data was geo-tagged. This was a breakthrough 
                    </p>
                   <p>
                   EATL has also developed the EIIN module of BANBEIS, through which the geo-location of any educational institute can be located and updated with a mobile app. This is a part of the IEIMS project of Ministry of Education.
                   </p>
                   <p>
                   EATL developed the GIS module of BANBEIS as well for providing several geo-location-based services to the teachers and educators of Bangladesh through a web panel and mobile apps. 
                   </p>
                    </Col>
                   
                </Row>
            </Container>
        </Common>
    );
};
export default LBS;