import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Common from "../../../layouts/Common";
import ResponsiveGallery from "react-responsive-gallery";
import eac1 from "../../../assets/images/eac1.jpg";
import eac2 from "../../../assets/images/eac2.jpg";
import eac3 from "../../../assets/images/eac3.jpg";
import eac4 from "../../../assets/images/eac4.jpg";
import eac5 from "../../../assets/images/eac5.jpg";
import eac6 from "../../../assets/images/eac6.jpg";
import eac7 from "../../../assets/images/eac7.jpg";
import eac8 from "../../../assets/images/eac8.jpg";
import eac9 from "../../../assets/images/eac9.jpg";
import eac10 from "../../../assets/images/eac10.jpg";






const AgreementWithEatl = () => {
  return (
    <Common>
      <section className="sub-page-banner newsr-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <h2>Media section</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Media section</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="text-start mt-3">
          <Col md={12}>
            <section className="work-process mt-5">
              <h4 data-aos="flip-up">
              ইএটিএলের সঙ্গে চুক্তিবদ্ধ হলো চুয়েট

              </h4>
              <label className="text-gray mb-2 w-100">
              Dated : 16 February, 2014
              </label>
            </section>
          </Col>
        </Row>
        <Col md={12} className="mb-5 border-topn pt-3 media-galery">
          <ResponsiveGallery
            useLightBox
            images={[
              {
                src: `${eac1}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 1,
                title:
                "ইএটিএলের সঙ্গে চুক্তিবদ্ধ হলো চুয়েট",
              },
         
              
              {
                src: `${eac2}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 2,
                title:
                "ইএটিএলের সঙ্গে চুক্তিবদ্ধ হলো চুয়েট",
              },
              {
                src: `${eac3}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 3,
                title:
                "ইএটিএলের সঙ্গে চুক্তিবদ্ধ হলো চুয়েট",
              },

              {
                src: `${eac4}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 4,
                title:
                "ইএটিএলের সঙ্গে চুক্তিবদ্ধ হলো চুয়েট",
              },
              {
                src: `${eac5}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 5,
                // imgClassName: 'img6-style',
                title:
                "ইএটিএলের সঙ্গে চুক্তিবদ্ধ হলো চুয়েট",
              },

              {
                src: `${eac6}`,
                alt: "gallery",
                orderS: 1,
                orderM:1,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "ইএটিএলের সঙ্গে চুক্তিবদ্ধ হলো চুয়েট",
              },

              {
                src: `${eac7}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL:7,
                // imgClassName: 'img6-style',
                title:
                  "ইএটিএলের সঙ্গে চুক্তিবদ্ধ হলো চুয়েট",
              },

             
              {
                src: `${eac8}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 8,
                // imgClassName: 'img6-style',
                title:
                  "ইএটিএলের সঙ্গে চুক্তিবদ্ধ হলো চুয়েট",
              },

              {
                src: `${eac9}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 9,
                // imgClassName: 'img6-style',
                title:
                  "ইএটিএলের সঙ্গে চুক্তিবদ্ধ হলো চুয়েট",
              },
              {
                src: `${eac10}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 10,
                // imgClassName: 'img6-style',
                title:
                  "ইএটিএলের সঙ্গে চুক্তিবদ্ধ হলো চুয়েট",
              },

              
              
            ]}
          />
        </Col>
      </Container>
    </Common>
  );
};

export default AgreementWithEatl;
