import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Common from "../../../layouts/Common";
import ResponsiveGallery from "react-responsive-gallery";
import app1 from "../../../assets/images/app1.jpg";
import app2 from "../../../assets/images/app2.jpg";
import app3 from "../../../assets/images/app3.jpg";
import app4 from "../../../assets/images/app4.jpg";
import app5 from "../../../assets/images/app5.jpg";
import app6 from "../../../assets/images/app6.jpg";
import app7 from "../../../assets/images/app7.jpg";
import app8 from "../../../assets/images/app8.jpg";




const NewAppleSupport = () => {
  return (
    <Common>
      <section className="sub-page-banner newsr-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <h2>Media section</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Media section</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="text-start mt-3">
          <Col md={12}>
            <section className="work-process mt-5">
              <h4 data-aos="flip-up">
              নতুন অ্যাপস ‘ল সাপোর্ট’ ও ‘টিউশন ম্যাপ’
              </h4>
              <label className="text-gray mb-2 w-100">
              Dated : 19 February, 2014
              </label>
            </section>
          </Col>
        </Row>
        <Col md={12} className="mb-5 border-topn pt-3 media-galery">
          <ResponsiveGallery
            useLightBox
            images={[
              {
                src: `${app1}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 1,
                title:
                "নতুন অ্যাপস ‘ল সাপোর্ট’ ও ‘টিউশন ম্যাপ’’",
              },
         
              
              {
                src: `${app2}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 3,
                title:
                "নতুন অ্যাপস ‘ল সাপোর্ট’ ও ‘টিউশন ম্যাপ’’",
              },
              {
                src: `${app3}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 4,
                title:
                "নতুন অ্যাপস ‘ল সাপোর্ট’ ও ‘টিউশন ম্যাপ’’",
              },

              {
                src: `${app4}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 5,
                title:
                "নতুন অ্যাপস ‘ল সাপোর্ট’ ও ‘টিউশন ম্যাপ’’",
              },
              {
                src: `${app5}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                "নতুন অ্যাপস ‘ল সাপোর্ট’ ও ‘টিউশন ম্যাপ’’",
              },

              {
                src: `${app6}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "নতুন অ্যাপস ‘ল সাপোর্ট’ ও ‘টিউশন ম্যাপ’’",
              },

              {
                src: `${app7}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "নতুন অ্যাপস ‘ল সাপোর্ট’ ও ‘টিউশন ম্যাপ’’",
              },
              {
                src: `${app8}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "নতুন অ্যাপস ‘ল সাপোর্ট’ ও ‘টিউশন ম্যাপ’’",
              },
             
             
              
            ]}
          />
        </Col>
      </Container>
    </Common>
  );
};

export default NewAppleSupport;
