import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import ns4 from '../../../assets/images/ns4.jpg';



const BracUniversity = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={6} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>EATL has signed an agreement with BRAC University.</h5>
            <span className='date-title'>Dated : 23 January, 2014</span>
            <p className='line-h'>
            Ethics Advanced Technology Limited (EATL) has signed an agreement with BRAC University today for collaboration in ICT, for its utilization and development. It was signed by Professor Ainun Nishat, Vice Chancellor of BRAC University and Mr. M A Mubin Khan, Chairman & MD of EATL.
            </p>
            <p className='line-h'>
            Dr. Nizam Uddin Ahmed, CEO of EATL, Mr. Khan Ahmed Murshid, Jt. Registrar of BRAC University, Professor Mohammad Zahidur Rahman, Chairperson of Department of Computer Science & Engineering (CSE) and Md. Zahangir Alom, Lecturer of Department of Computer Science & Engineering (CSE) were also present on this occasion. 
        </p>

        <p className='line-h'>
        This is an excellent collaboration between academia and ICT industry for engagement of students and teachers in training, mobile apps development, Research & Development activities. EATL and BRAC University will work together for utilization of knowledge and skills in socio-economic development and supporting business sectors.
        </p>
         </div>
         </Col>
         <Col md={6} data-aos="fade-left">
         <img className='news-details' src={ns4} alt="com" />
         </Col>
      </Row>

   </Container>
</Common>
);
};
export default BracUniversity;