import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import cme from '../../../assets/images/cme.png';

const OnlineTrainingConsulting = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Online Training Consulting Firm for Subject Based Training</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Projects</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={8}>
                        <div className='about-company mt-70 left-0' data-aos="fade-right">
                            
                            <p>
                                {/* EATL started the "Software design and development for Educational Information Management to be used by MOE, BANBEIS, DSHE, DTE, DME, NTRCA and others with maintenance support for 3 (three) years" project for IEIMS, Bangladesh Bureau of Educational Information & Statistics (BANBEIS), MoE on February 2021. Total value of this project is $535 Thousand USD. */}
                    
                                The major objective of Online Training for Subject Based Training is to organize on line Subject Based training for the teachers of the government and non-government Honours teaching colleges under National University. The training platform will also ensure recording the sessions and use it for future purposes. It will also measure the trainees/trainer's performance. Following objectives are required to be achieved through this platform                    </p>


                    <Col md={12} data-aos="fade-left">
                        <div className=' '>
                            
                            <ul className='list-style-one mt-2'>
                                <li>Identify an appropriate and convenient (user-friendly) eLearning platform which can be used to organize training for the teachers of the government and non-government Honours teaching colleges under National University</li>
                                <li>Conduct effectively and efficiently online Subject Based Training without any interruption so that purpose of the training is achieved</li>
                                <li>Archive and make available all sessions conducted for later use by the trainees and stakeholders</li>
                                <li>Measure the performance of the trainees/trainers</li>
                                <li>NU teachers training management software solution from trainee selection to certificate award, resource person evaluation, pre-test and post-test, attendance, various reports, training records etc</li>
                                <li>
                                Online training management operation and services Analytics and reporting
                                </li>
                               
                            </ul>
                        </div>
                    </Col>

                            
                        </div>

                    </Col>
                    <Col md={4} data-aos="fade-left">

                        <div className='about-company mt-70'>
                            <img className='nu-all mt-5' src={cme} alt="cme" />

                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default OnlineTrainingConsulting;