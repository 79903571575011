import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import clinc1 from '../../../assets/images/clinc1.png';
import clim2 from '../../../assets/images/clim2.png';
const ClinicBasedmis = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Community Clinic Based MIS</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Products</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>Community Clinic Based MIS</h5>
                            <p className='line-h'>
                                EATL developed database management system and dynamic website (Content management System-CMS) for community clinic and deployed the clinic management core software solution in 6 different community clinics as a pilot project.                            </p>
                            <p className='line-h'>
                                This is a complete real time clinic management system for community clinic project.                            </p>
                        </div>

                    </Col>
                    <Col md={6} data-aos="fade-left">

                        <Row className='mt-70'>
                            <Col md={6}>
                                <img className='services-box' src={clinc1} alt="clinc1" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={clim2} alt="clim2" />
                            </Col>

                        </Row>
                    </Col>
                    <Col md={12} data-aos="fade-left">
                        <div className=' '>
                            <h5 class="mt-5">Key Features :</h5>
                            <ul className='list-style-one mt-2'>
                                <li>A complete Web based Community Clinic Management Information System Software Solution.</li>
                                <li>Can connect and store data from remote locations to the main server.</li>
                                <li>Can manage many clinics from a central server or system.</li>
                                <li>Robust architecture for Database Management System.    </li>
                                <li>Provides GIS, MIS, EIS for automation of health service delivery at community clinics (18,000) and monitoring of services.</li>
                                <li>Supported for larger and smaller screen (PC, mobile phone etc.) for easy access at any level.</li>
                                <li>Capture video stream through built-in web camera for supervision and monitoring of community clinics.</li>
                                <li>An automated software system, funded by MOHFW and Multi-Trust Fund.</li>
                            </ul>
                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default ClinicBasedmis;