import React from 'react';
import Common from "../../layouts/Common"


const Nofound = () => {
    return (
      <Common></Common>     
    );
};

export default Nofound;