import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import cons1 from '../../../assets/images/cons1.png';
import con2 from '../../../assets/images/con2.png';
import Table from "react-bootstrap/Table";
const ITConsultancy = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>IT Consultancy</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Service</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>IT Consultancy</h5>
                            <p className='line-h'>
                                EATL is one of the pioneers in providing IT infrastructure and solutions on various platforms, leading provider of information technology, consulting and business process services. We are one of the best IT solutions provider in managing, building and implementing IT environments of business systems critical to success in today's challenging and changing market.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} data-aos="fade-left ">
                        <Row className='mt-70'>
                            <Col md={6}>
                                <img className='services-box' src={cons1} alt="cons1" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={con2} alt="con2" />
                            </Col>

                        </Row>
                    </Col>

                    <Col md={6} data-aos="fade-left">
                        <div className='about-company left-0 mt-4'>
                            <h3>
                                List of consultancy services:
                            </h3>
                            <ul className='list-style-one'>
                                <li>EATL does implementation and management of quality IT services</li>
                                <li>We are one of the best IT services company in industry.</li>
                                <li>We provide IT solution services.</li>
                                <li>We provide solution and maintenance for personal and commercial website, static and dynamic website.</li>
                                <li>We help to grow your business, achieve to target in your industry by step by step and strategic planning.</li>
                                <li>As we have IT skills and experienced professional IT team, we can make and follow to achieve tour targets business within time, budget and with quality standards.</li>
                            </ul>
                        </div>
                    </Col>

                    <Col md={12} data-aos="fade-left">
            <div className=" left-0">
              <h3 className="mt-4">
              A list of IT Consultancy Services that EATL has carried out over the years is given below:
              </h3>
              <Col md={12} data-aos="fade-left">
                <Table className="table-custom mt-3" bordered hover size="sm">
                  <thead>
                    <tr>
                      <th className="sl-th">Sl.</th>
                      <th className="project-name-th">Project Name</th>
                      <th className="client-name-th">Client</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                      ICT-based Students Competitions
Management Consultant -- Creating an enabling understanding among the students to discover green growth leaders for the next generations in Bangladesh


                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>World Bank Group</p>
                      
                      </td>
                    </tr>


                    <tr>
                      <td>2</td>
                      <td>
                      Online Training Consulting Firm for Subject Based Training


                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>College Education Development Project (CEDP)</p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>3</td>
                      <td>
                      Support conducting online teaching-learning of undergraduate students of the Government Medical Colleges in Bangladesh

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>
                    World Health Organization (WHO), Bangladesh<br/>
                    

                      </p>
                      <strong> User Organization:</strong><br/>
                   <p>
                   Centre for Medical Education (CME), DGME, MoHFW 
                   </p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>4</td>
                      <td>
                    
                      Centre for Medical Education (CME), DGME, MoHFW 
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Secondary Education Sector Investment Program (SESIP)</p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>5</td>
                      <td>
                    
                      Better Health in Bangladesh: Interim NGO TA to the 4th Health, Population, and Nutrition Sector Programme
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Management Sciences for Health, Inc. (MSH) </p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>6</td>
                      <td>
                    
                      Prepare E-books on "Switchgear and Protection" both in English and Bengali of 7th semester Electrical and Power Technology of Diploma Engineering Course under BTEB
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>
                      Skills and Training Enhancement Project (STEP), Directorate of Technical Education (DTE)
                      </p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>7</td>
                      <td>
                      E-learning to right skills to manage NCDs

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>
                      Non-Communicable Disease Control (NCDs)<br/>
Directorate General of Health Services (DGHS), MoHFW

                      </p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>8</td>
                      <td>
                      Development of E-learning Campaign

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Secondary Education Sector Investment Program (SESIP) </p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>9</td>
                      <td>
                      Procurement of Development and supply of e-Manuals in Physics, Chemistry and Bangladesh & Global Studies


                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>
                      TQI-II Project of Directorate of Secondary and Higher Education, Ministry of Education 
                      </p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>10</td>
                      <td>
                      Procurement of Contextualization and Supply of e-Learning Resources for Grade 9-10 Subjects Physics, Chemistry and Biology
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>
                      TQI-II Project of Directorate of Secondary and Higher Education, Ministry of Education 

                      </p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>11</td>
                      <td>
                      Procurement of Contextualization and Supply of e-Learning Resources for Grade 9-10 Subjects English, Mathematics and Accounting

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>
                      TQI-II Project of DSHE 

                      </p>
                      
                      </td>
                    </tr>


                    <tr>
                      <td>12</td>
                      <td>
                      National Mobile Application Trainer and Innovative Application Development Program

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Ministry of Posts, Telecommunication and Information Technology   </p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>
                      15-day training program on ‘Basic IT / ICT Literacy’ under Learning & Earning Development Project

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Ministry of Posts, Telecommunication and Information Technology </p>
                      
                      </td>
                    </tr>
                   
                    

                    
                  </tbody>
                </Table>
              </Col>
            </div>
          </Col>
                </Row>
            </Container>
        </Common>
    );
};
export default ITConsultancy;