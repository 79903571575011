import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from 'react-router-dom';
import '../../common/nav/Nav.css';
import { FaHome} from 'react-icons/fa';
import mainlogo from '../../assets/images/mainlogo.svg';
import { FaMobileAlt, FaRegEnvelope } from 'react-icons/fa';




const Header = () => {
  const [stickyClass, setStickyClass] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight > 150 ? setStickyClass('sticky-nav') : setStickyClass('');
    }
  };


    return (
<>
<div className='header-address'>
<Container className='full-w-two'>
<Row>
    <Col md={7}>
    <div className="header-info">
					<ul>
						<li>
            <span>
          <FaRegEnvelope/>
          </span>
          <label>info@eatlbd.com </label>
            </li>
						<li>
            <span>
          <FaMobileAlt/>
          </span>
          <label>+88-09614-444000 </label>
            </li>
					</ul>
				</div>
    </Col>
    <Col md={5}>
     <div className='menu-top-right'>
      <ul>
        <li>
        <NavLink exact to="/newsroom">Newsroom</NavLink>
        </li>
        <li>
            <NavLink exact to="/vlog">Vlog</NavLink>
        </li>
        <li>
        <NavLink  exact to="/eatl-innovation-hub">Innovation HUB</NavLink>
        </li>
      </ul>
     </div>
    </Col>
   </Row>
   </Container>

  </div>
<div className="tolp-header header-top-two">
<Container>
<Row>
    <Col md={3}>
    <NavLink exact to="/home">
    <img className='logo-top' src={mainlogo} alt="logo" />
    </NavLink>
    </Col>
    <Col md={9} className='text-end  p-0'>
    <Navbar className={`customHeader ${stickyClass}`} expand="lg" >
    <Container className='position-relative'>
    <Row>
    <Col md={12}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto custom-navbar">
            <NavLink exact to="/home">
              <FaHome className="me-1 home-mar"/>
              Home</NavLink>
              {/* <NavLink exact to="/about-us">About</NavLink> */}

            <NavDropdown title="About" id="basic-nav-dropdown" className="all-drop-down drop-down-nav custom-navbar-list">
            <NavDropdown.Item>
              <NavLink exact to="/about-us" >About The EATL</NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <NavLink exact to="/company-profile" >Company Profile</NavLink>
            </NavDropdown.Item>
            <NavDropdown.Item>
              <NavLink exact to="/team">Our Team</NavLink>
            </NavDropdown.Item>
            </NavDropdown>
         
         
            <NavLink exact to="/services">Services</NavLink>
            <NavLink exact to="/products-list">Products</NavLink>
            <NavLink exact to="/projects">Projects</NavLink>
            <NavLink exact to="/technologies">Technologies</NavLink>
            <NavLink exact to="/partners-clients">Partners & Clients</NavLink>
            <NavLink className="display-stk-none" exact to="/newsroom">Newsroom</NavLink>
            <NavLink exact to="/gallery">Gallery</NavLink>
            
            <NavLink className="display-stk-none" exact to="/vlog">Vlog</NavLink>

            <NavLink exact to="/contact">Contact</NavLink>
            <NavLink className="invlink display-stk-none" exact to="/eatl-innovation-hub"><button className='btn btn-eatlhu'>Innovation HUB</button></NavLink>
          </Nav>
        </Navbar.Collapse>
        </Col>
       </Row>
    
    </Container>
  </Navbar>
      </Col>
       </Row>
</Container>
</div>

 

</>
);
};

export default Header; 












