import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import nua from '../../../assets/images/nbr.jpg';

const Nbr = () => {
return (
<Common>
   <section className='sub-page-banner'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>NBR</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Projects</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row>
         <Col md={8}>
         <div className='about-company mt-70 left-0' data-aos="fade-right">
            <h5>Supply, Installation and Commissioning and Operation of Electronic Fiscal Device Management Systems (EFDMS) along with Electronic Fiscal Device (EFD), Sales Data Controller (SDC) and Managed Services</h5>
            <p>
            The purpose of this assignment is to capture and store the transaction data of the retail/ wholesale outlets in Bangladesh. Electronic Fiscal Devices (EFD) are distributed among the outlets that will be connected to a central MIS with real-time secure data connectivity. The VAT payers will be able to make sure that the government has been informed about their VAT payment.
            </p>

     

          

            <h5 className='mt-4'>The following specific objective will be achieved after completing this assignment:</h5>
            <ul className='list-style-one'>
            <li>
            An online VAT collection MIS has been established where the government has up-to-date information about the collected VAT amount by the outlets throughout the country
            </li>

            <li>
            The government is able to identify the VAT amount owed by the outlets, thus, the gap
between collected and deposited VAT is reduced

            </li>

            <li>
            The dispute, arrear collection, and refund issuance process become expedited. Thus, outlet owners get better service from NBR

            </li>
          
            <li>
            The entire VAT collection process becomes more transparent and accountable
            </li>

            <li>
            People become aware of VAT payment and be willing to pay VAT.


            </li>

           
           </ul>
         </div>
     
         </Col>
         <Col md={4} data-aos="fade-left">
       
         <div className='about-company mt-70'>
         <img className='nu-all mt-5' src={nua} alt="nua" />

         </div>
         </Col>
      
      </Row>
   </Container>
</Common>
);
};
export default Nbr;