import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import client0 from '../../assets/images/tech1.jpg';
import client1 from '../../assets/images/tech2.jpg';
import client2 from '../../assets/images/tech3.jpg';
import client4 from '../../assets/images/tech4.jpg';
import client5 from '../../assets/images/tech5.jpg';
import client6 from '../../assets/images/tech6.jpg';
import client7 from '../../assets/images/tech7.jpg';
import client8 from '../../assets/images/tech8.jpg';
import client9 from '../../assets/images/tech9.jpg'
import clee from '../../assets/images/tech10.jpg';
import cld from '../../assets/images/tech11.jpg';
import clc from '../../assets/images/tech12.jpg';
import cle from '../../assets/images/tech13.jpg';
import cla from '../../assets/images/tech14.jpg';
import clb from '../../assets/images/tech15.jpg';
import clf from '../../assets/images/tech16.jpg';
import clff from '../../assets/images/tech17.jpg';
import clg from '../../assets/images/tech18.jpg';

import tech19 from '../../assets/images/tech19.jpg';
import tech20 from '../../assets/images/tech20.jpg';
import tech21 from '../../assets/images/tech21.jpg';
import tech22 from '../../assets/images/tech22.jpg';
import vuejs from '../../assets/images/vuejs.png';
import ios from '../../assets/images/ios.jpg';
import flt from '../../assets/images/flt.jpg';
import sql from '../../assets/images/sql.jpg';
 import jsi from '../../assets/images/jsi.jpg';
 import qubn from '../../assets/images/qubn.jpg';
 import ng from '../../assets/images/ng.jpg';

const Technology = () => {
return (
<Common>
   <section className='sub-page-banner  part-client-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Technologies</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>
               Technologies
               </Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container>
      <Row>
 
      <Row className='text-center ms-0'>
         <Col md={12}>
         <section className='work-process'>
           
            <h2 data-aos="flip-up">Our Technologies</h2>
         </section>
         </Col>
      </Row>
         <Col md={12} className='par-client mb-5 pb-5 pe-0'>
         <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client1} alt="client1" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client8} alt="client8" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clb} alt="client6" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clc} alt="client2" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={vuejs} alt="client9" /> </div>
               </div>
            </div>

            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client2} alt="client2" /> </div>
               </div>
            </div>

            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client9} alt="client9" /> </div>
               </div>
            </div>

            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={jsi} alt="client9" /> </div>
               </div>
            </div>
            
           

         <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client0} alt="client0" /> </div>
               </div>
            </div>
         
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client7} alt="client7" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={sql} alt="client9" /> </div>
               </div>
            </div>
            
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={tech19} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clff} alt="client8" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clg} alt="client9" /> </div>
               </div>
            </div>

            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cle} alt="client3" /> </div>
               </div>
            </div>

            

            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client4} alt="client3" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client5} alt="client5" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client6} alt="client6" /> </div>
               </div>
            </div>
           
      
            
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clee} alt="client0" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cld} alt="client1" /> </div>
               </div>
            </div>
           

 

            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cla} alt="client5" /> </div>
               </div>
            </div>
          
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clf} alt="client7" /> </div>
               </div>
            </div>
     
           
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={ios} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={flt} alt="client9" /> </div>
               </div>
            </div>
         

            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={tech20} alt="client9" /> </div>
               </div>
            </div>

            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={tech21} alt="client9" /> </div>
               </div>
            </div>

            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={tech22} alt="client9" /> </div>
               </div>
            </div>

            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={qubn} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={ng} alt="client9" /> </div>
               </div>
            </div>
           
         </Col>
{/* 
         <Row className='text-center'>
         <Col md={12}>
         <section className='work-process'>
           
            <h2 data-aos="flip-up">Our Clients</h2>
         </section>
         </Col>
      </Row> */}
         {/* <Col md={12}  className='par-client mb-5'>
        
         <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clee} alt="client0" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cld} alt="client1" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clc} alt="client2" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cle} alt="client3" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cla} alt="client5" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clb} alt="client6" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clf} alt="client7" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clff} alt="client8" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clg} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clh} alt="client9" /> </div>
               </div>
            </div>
         </Col> */}
      </Row>


      

   </Container>
</Common>
);
};
export default Technology;