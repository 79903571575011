import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Common from "../../../layouts/Common";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import csoft from "../../../assets/images/csoft.png";
import cos2 from "../../../assets/images/cos2.png";
import Table from "react-bootstrap/Table";
const CustomizedSoftware = () => {
  return (
    <Common>
      <section className="sub-page-banner">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <h2>Customized Software</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Service</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container className="mb-5">
        <Row>
          <Col md={6} data-aos="fade-right">
            <div className="about-company mt-70 left-0">
              <h5>Customized Software</h5>
              <p className="line-h">
                EATL is a custom software development company that provides
                customized software. It works exactly the way you want them to.
              </p>
              <p className="line-h">
                Differences in user interfaces of custom software solutions may
                be significant ranging from one industry to another. But the
                main targets remain unchanged – enhancement of internal
                operations, accountability, efficiency due to use of intuitive
                software with easy-to-use and friendly user interfaces. The
                characteristic features and functionalities of solutions are
                determined by standards of the industry.
              </p>
            </div>
          </Col>

          <Col md={6} data-aos="fade-right">
            <Row className=" mt-70 ">
              <Col md={6}>
                <img className="services-box" src={csoft} alt="csoft" />
              </Col>
              <Col md={6}>
                <img className="services-box" src={cos2} alt="cos2" />
              </Col>
            </Row>
          </Col>
          <Col md={12} data-aos="fade-leftt">
            <div className="about-company mt-4 left-0">
              <h3>What You Need in Custom Software Solutions</h3>
              <p className=" line-h">
                Custom software solutions may have industry specific features in
                the sets of interface features. Dependent on the kind of
                activities, different businesses may require integrated
                knowledge of safety standards, volatility of product or
                government issues. Design of layouts and container maps for
                packaging industry requires maximum of flexibility and accuracy.
                Thus such specific elements condition the implementation of
                functionalities which will facilitate proper product design and
                accountability.
              </p>
              <p className=" line-h">
                It is anticipated that custom software solutions, as any other
                software, will evolve in the context of streamlining and
                automating complex processes.
              </p>
            </div>
          </Col>
          <Col md={12} data-aos="fade-left">
            <div className=" left-0">
              <h3 className="mt-2">Advantages:</h3>
              <ul className="list-style-one">
                <li>
                  Industry specific features and functionalities, use of
                  multiple networks and systems integration.
                </li>
                <li>
                  The application is built to your requirements, you’ll save
                  time and money when it comes to teaching your staff how to use
                  it.
                </li>
                <li>
                  An application developed according to your own specification
                  will probably require much less configuration work and much
                  less training. It’s replicating your existing business
                  process(es) and should be intuitive to your staff.
                </li>
                <li>
                  A good custom application can also bring efficiencies by
                  automating manual tasks and a good developer can even point
                  out further efficiencies to be gained.
                </li>
                <li>
                  Custom-made software was once the preserve of large companies,
                  but the cost of developing custom-made applications has fallen
                  because of robust application frameworks, a vast array of
                  off-the-shelf modules and rapid development tools, putting it
                  within reach of SMEs.
                </li>
                <li>
                  Custom solutions possess notable degree of flexibility which
                  will add to the infrastructure of your business and allow for
                  comprehensive level of customization.
                </li>
              </ul>
            </div>
          </Col>

          <Col md={12} data-aos="fade-left">
            <div className=" left-0">
              <h3 className="mt-4">
                A list of customized software that EATL has developed over the
                years is given below:
              </h3>
              <Col md={12} data-aos="fade-left">
                <Table className="table-custom mt-3" bordered hover size="sm">
                  <thead>
                    <tr>
                      <th className="sl-th">Sl.</th>
                      <th className="project-name-th">Project Name</th>
                      <th className="client-name-th">Client</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>
                    ICT-based Students Competitions
Management Consultant -- Creating an enabling understanding among the students to discover green growth leaders for the next generations in Bangladesh	Name of Client:
World Bank Group

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>World Bank Group</p>
                      
                      </td>
                    </tr>


                    <tr>
                      <td>2</td>
                      <td>
                   Design, Development, Implementation and Maintenance of Learning Management System (LMS) for National University, Bangladesh	Name of Client:
College Education Development Project (CEDP), Bangladesh


                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>College Education Development Project (CEDP), Bangladesh</p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>3</td>
                      <td>
                      Online Training Consulting Firm for Subject Based Training

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>College Education Development Project (CEDP)</p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>4</td>
                      <td>
                    
                      Procurement of / Burn, Casualty, Diabetic Foot Care, Chest Tube, IVF, Sexual Assault Etc. Manikin, Model and Trainers for MC
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Directorate General of Medical Education (DGME), MoHFW, Bangladesh.</p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>5</td>
                      <td>
                    
                      Development web-based Program Management Information System (PMIS) of UCEP Bangladesh
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>UCEP Bangladesh </p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>6</td>
                      <td>
                    
                      Information Management to be used by MOE, BANBEIS, DSHE, DTE, DME, NTRCA and others with maintenance support for 3 (three) years
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>
                      IEIMS, Bangladesh Bureau of Educational Information & Statistics (BANBEIS), MoE
                      </p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>7</td>
                      <td>
                      Development & Implementation of Examination Management System for National University

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>National University, Bangladesh</p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>8</td>
                      <td>
                      Establish e-Library Management and Set up web based Medical Student Facilities Management

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Directorate General of Medical Education (DGME), MoHFW</p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>9</td>
                      <td>
                    Support conducting online teaching-learning of undergraduate students of the Government Medical Colleges in Bangladesh	Name of Client:
World Health Organization (WHO), Bangladesh
User Organization:
Centre for Medical Education (CME), DGME, MoHFW 


                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>
                      World Health Organization (WHO), Bangladesh	
                      </p>
                      <strong>User Organization:</strong>
                      <p>
                      Centre for Medical Education (CME), DGME, MoHFW 
                      </p>
                      </td>
                    </tr>

                    <tr>
                      <td>10</td>
                      <td>
                    
                      Learning Management System (LMS) with online Class
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>
                    a.	BGC Trust Medical College<br/>
                    b.	International Medical College & Hospital (IMCH)<br/>
                    c.	Tairunnessa Memorial Medical College & Hospital)<br/>
                    d.	Tairunnessa Nursing College <br/>
                    e.	Dhaka National Medical College<br/>
                    f.	Armed Forces Medical College<br/>
                    g.	Chattogram Maa-O-Shishu Hospital<br/>

                      </p>
                      
                      </td>
                    </tr>

                    <tr>
                      <td>11</td>
                      <td>
                      Establishing Digital Birth and Death Notification System and Capacity Building in Health System for Strengthening EPI and CVRS in Bangladesh

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>
                      Management Information System (MIS),<br/> 
Directorate General of Health Services (DGHS), MoHFW

                      </p>
                      
                      </td>
                    </tr>


                    <tr>
                      <td>12</td>
                      <td>
                      Supply, Installation and Commissioning and Operation of Electronic Fiscal Device Management Systems (EFDMS) along with Electronic Fiscal Device (EFD), Sales Data Controller (SDC) and Managed Services

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>National Board of Revenue (NBR)  </p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>13</td>
                      <td>
                      Development of Web Based Automation System for the Educational Institutions & Directorate of Inspection and Audit (DIA)

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Directorate of Inspection and Audit (DIA), MoE</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>14</td>
                      <td>
                     
                      Better Health in Bangladesh: Interim NGO TA to the 4th Health, Population, and Nutrition Sector Programme
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Management Sciences for Health, Inc. (MSH)</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>15</td>
                      <td>
                      Prepare E-books on "Switchgear and Protection" both in English and Bengali of 7th semester Electrical and Power Technology of Diploma Engineering Course under BTEB

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Skills and Training Enhancement Project (STEP), Directorate of Technical Education (DTE)</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>16</td>
                      <td>
                      Development of 6 Mobile app for the Daily Prothom-Alo

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>The Daily Prothom-Alo</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>17</td>
                      <td>
                Studying the challenges and the proven ICT based solutions for empowering Bangladeshi farmers (Development of 5 Mobile applications for agriculture sector)	Name of Client:
Department of Agriculture Extension (DAE), coordinated by BTRC


                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Department of Agriculture Extension (DAE), coordinated by BTRC</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>18</td>
                      <td>
                     
                      Procurement of Development and supply of e-Manuals in Physics, Chemistry and Bangladesh & Global Studies
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>TQI-II Project of Directorate of Secondary and Higher Education, Ministry of Education </p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>19</td>
                      <td>
                     
                      Procurement of Contextualization and Supply of e-Learning Resources for Grade 9-10 Subjects Physics, Chemistry and Biology
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>TQI-II Project of Directorate of Secondary and Higher Education, Ministry of Education </p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>20</td>
                      <td>
                      Procurement of Contextualization and Supply of e-Learning Resources for Grade 9-10 Subjects English, Mathematics and Accounting

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>TQI-II Project of DSHE </p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>21</td>
                      <td>
                     
                      PKSF Web Portal 
e-Recruitment and online visitor management

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Palli Karma-Sahayak Foundation (PKSF)</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>22</td>
                      <td>
                      Digital Birth Registration: Evidence- based Technical Analysis Phase

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Plan International Bangladesh </p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>23</td>
                      <td>
                     
                      Utilization of mobile phone technology to improve HNP service Phase – 1
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Directorate General of Health Services (DGHS), MoHFW
JV Partner: icddr,b
</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>24</td>
                      <td>
                     
                      Identification and Development of 50 Mobile Apps for MOHFW
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Management Information System (MIS), DGHS, MOHFW</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>25</td>
                      <td>
                      Academic and Hospital Information Management Solutions for Private Medical Colleges & Hospitals

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>International Medical College & Hospital (IMCH)</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>26</td>
                      <td>
                      International Medical College & Hospital (IMCH)

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>
                      Ministry of Posts, Telecommunication and Information Technology 
                      </p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>27</td>
                      <td>
                     
                      Use of ERP Solutions for Efficient and Cost-Effective Delivery of Products in Pharmaceutical Industries
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Essential Drugs Company Limited (EDCL)</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>28</td>
                      <td>
                      Web based Application and Data Management Software for SEQAEP

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Secondary Education Quality Access Enhancement Project (SEQAEP)</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>29</td>
                      <td>
                     
                      Automated Patient Reminder Service for ORBIS Int’l
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Palli Karma-Sahayak Foundation (PKSF)</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>30</td>
                      <td>
                      Website & Web based MIS for Community Clinic Project

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Community Clinic </p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>31</td>
                      <td>
                      Website & Web based MIS for Community Clinic Project

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Community Clinic </p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>32</td>
                      <td>
                      Doctor’s Profile Management System

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>ZISKA Pharmaceuticals Limited </p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>33</td>
                      <td>
                      Interactive Website and Intranet Application of President’s Office

                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Banga Bhaban</p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>34</td>
                      <td>
                     
                      Training & Library management system
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Bangladesh Shilpa Bank </p>
                      
                      </td>
                    </tr>
                    <tr>
                      <td>35</td>
                      <td>
                     
                      Development banking application software (Integrated Banking application with one stop service) in two branches (Basabo and Goran, Dhaka).
                      </td>
                      <td>
                      <strong>Name of Client:</strong>
                      <p>Sonali Bank Limited</p>
                      
                      </td>
                    </tr>
                    

                    
                  </tbody>
                </Table>
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </Common>
  );
};
export default CustomizedSoftware;
