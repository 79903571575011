import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import edc1 from '../../../assets/images/edc1.png';
import edc2 from '../../../assets/images/edc2.png';
const WebMIS = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Web MIS for Education</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Products</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>Web MIS for Education</h5>
                            <p className='line-h'>
                                EATL has expertise on Education MIS development and management. One of the biggest projects of ETAL is the Education MIS developed for the client named SEQAEP- Secondary Education Quality and Access Enhancement Project.
                            </p>
                            <p className='line-h'>
                                The Education MIS system developed for SEQAEP is a Web based MIS solution which is designed to enhance the quality of education at the secondary education level in Bangladesh, by facilitating access to information through quality teaching materials. The goal is to bring all upzilas of the entire project into internet accessibility to facilitate the performance of various activities of SEQAEP.
                            </p>
                        </div>

                    </Col>
                    <Col md={6} data-aos="fade-left">

                        <Row className='mt-70'>
                            <Col md={6}>
                                <img className='services-box' src={edc1} alt="edc1" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={edc2} alt="edc2" />
                            </Col>

                        </Row>
                    </Col>
                    <Col md={12} data-aos="fade-left">
                        <div className=' '>
                            <h5 class="mt-5">Key Features :</h5>
                            <ul className='list-style-one mt-2'>
                                <li>Web-based  MIS and database system.</li>
                                <li>Robust and dynamic architecture with strong database design for Data security and handling.</li>
                                <li>Able to integrate and handle database from multiple institutions, such as PMTA, BANBEIS, DPHE, Education boards, MoE etc.</li>
                                <li>Storing and reporting the data of all schools under SEQAEP.</li>
                                <li>An effective system for processing, management and compilation of data received from and transferred to various sources.</li>
                                <li>Database software for different categories of awards for students, institutions, teachers, reading habit monitoring, band wise teacher’s entry, enrollment of students and tracking them throughout the secondary education cycle.</li>
                                <li>Calculate and disburse payment of resource teachers and data entry operators.</li>
                             </ul>
                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default WebMIS;