import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import onlineclass from '../../../assets/images/onlineclass.jpg';
const LearningManagementSystem = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Edutube E-Learning Content Portal and Learning Management System (LMS) </h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Projects</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={8}>
                        <div className='about-company mt-70 left-0' data-aos="fade-right">
                            
                            <p>
                                {/* EATL started the "Software design and development for Educational Information Management to be used by MOE, BANBEIS, DSHE, DTE, DME, NTRCA and others with maintenance support for 3 (three) years" project for IEIMS, Bangladesh Bureau of Educational Information & Statistics (BANBEIS), MoE on February 2021. Total value of this project is $535 Thousand USD. */}
                    
                                Edutube E-Learning Content Portal and Learning Management System (LMS) 
EATL launched country’s first e-learning web portal edutubebd.com in April 2015 where students of different academic levels and candidates of competitive examinations find supplementary educational contents. Laster on, we have integrated learning management system (LMS) with it. Edutube LMS enables taking online classes and recording attendance during the online classes. 
The following are the major features of Edutube platform:
   </p>


                    <Col md={12} data-aos="fade-left">
                        <div className=' '>
                            
                            <ul className='list-style-one mt-2'>
                                <li>Teacher, student and management staff profile information</li>
                                <li>Course and offered class information</li>
                                <li>Sharing of course material with students along with hosting and download option</li>
                                <li>Assignment upload and submission facility</li>
                                <li>Real-time online quiz (MCQ or Question based) option</li>
                                <li>
                                Evaluation of assignment and quiz with marks recording
                                </li>
                                <li>
                                Virtual classroom with moderation facility and class recording
                                </li>
                                <li>Attendance tracking based on students' virtual classroom presence</li>
                                <li>Result processing with detail marks distribution in different components</li>
                                <li>Performance Tracking of students</li>
                                <li>Routine, online class scheduling and other material sharing</li>
                                <li>Dashboard for different groups of users, secure, privacy preserving and customizable</li>
                                <li>SMS and Email Notifications</li>
                                <li>Bangla and English language support</li>
                                <li>Availability of local support and maintenance service</li>
                            </ul>

                            <p>
                                <strong>Already 7 medical colleges are using our LMS solution during this pandemic situation.</strong>
                            </p>
                        </div>
                    </Col>

                            
                        </div>

                    </Col>
                    <Col md={4} data-aos="fade-left">

                        <div className='about-company mt-70'>
                            <img className='nu-all mt-5' src={onlineclass} alt="onlineclass" />

                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default LearningManagementSystem;