import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import cme from '../../../assets/images/cme.png';

const CME = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>
                                Support conducting online teaching-learning of undergraduate students of the Government Medical Colleges in Bangladesh                            </h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Projects</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={8}>
                        <div className='about-company mt-70 left-0' data-aos="fade-right">
                            <h5>
                                Support conducting online teaching-learning of undergraduate students of the Government Medical Colleges in Bangladesh                            </h5>
                            <p>
                                EATL successfully completed the "Support conducting online teaching-learning of undergraduate students of the Government Medical Colleges in Bangladesh" project for the Centre for Medical Education (CME) in March 2021. The main purpose of WHO funded Centre for Medical Education (CME) assignment is to facilitate online teaching-learning for undergraduate medical college students.                                    </p>
                            <h5 className='mt-4'>Name of Client:</h5>
                            <p>
                                World Health Organization (WHO), Bangladesh
                                <br></br>
                                User Org:<br></br>
                                Centre for Medical Education (CME), Ministry of Health and Family Welfare (MoHFW)
                            </p>

                        </div>

                    </Col>
                    <Col md={4} data-aos="fade-left">

                        <div className='about-company mt-70'>
                            <img className='nu-all mt-5' src={cme} alt="cme" />

                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default CME;