import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import nws2 from '../../../assets/images/nws2.jpg';



const IctMobileApp = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={8} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>আইসিটি বিভাগের উদ্যোগে ৫০০ মোবাইল এ্যাপস এর উদ্বোধন</h5>
            <span className='date-title'>Dated : 02 January, 2017</span>
            <p className='line-h'>
            ঢাকা:(২৬ জুলাই): মোবাইল আমাদের পথচলার নিত্য সঙ্গী। জীবন বাঁচাতে আর জীবন সাজাতে মোবাইলের প্রয়োজনীয়তা অনস্বীকার্য। তাই অর্থনৈতিক সমৃদ্ধি ও জীবনের স্বাচ্ছন্দ্য আনতে মোবাইল অ্যাপসকে হাতিয়ারে পরিণত করতে  তথ্য ও যোগাযোগ প্রযুক্তি বিভাগের উদ্যোগে দেশের ইতিহাসে প্রথমবারের মতো সরকারি তথ্য, সেবা ও  সৃজনশীল ধারণার উপর উন্নয়নকৃত ৫০০  মোবাইল অ্যাপস উদ্বোধন করা হয়েছে। এর মধ্য দিয়ে সহজে সুলভে নাগরিক সেবা প্রাপ্তির ক্ষেত্রে এক নতুন অধ্যায়ের সূচন হল।
            </p>
            <p className='line-h'>

          মাননীয় প্রধানমন্ত্রীর তথ্য ও যোগাযোগ প্রযুক্তি (আইসিটি) বিষয়ক মাননীয় উপদেষ্টা সজীব ওয়াজেদ আজ সকালে রাজধানীর আগারগাঁয়ের বিসিসি (বাংলাদেশ কম্পিউটার কাউন্সিল) অডিটোরিয়ামে এক অনুষ্ঠানে প্রধান অতিথি হিসেবে এ্যাপসগুলো উদ্বোধন করেন।
          </p>
          <p className='line-h'>

          আইসিটি সচিব শ্যাম সুন্দর সিকদারের সভাপতিত্বে অনুষ্ঠানে বিশেষ অতিথির বক্তৃতা করেন তথ্য ও যোগাযোগ বিভাগের প্রতিমন্ত্রী জুনাইদ আহমেদ পলক এমপি। স্বাগত বক্তব্য রাখেন কর্মসূচি পরিচালক ও সিসিএর (কন্ট্রোলার অফ সার্টিফায়িং অথিরিটি) নিয়ন্ত্রক জি ফখরুদ্দিন আহমেদ চৌধুরী, স্বাগত বক্তব্য রাখেন কর্মসূচি বাস্তবায়নের সহযোগী প্রতিষ্ঠান ইএটিএল এর ব্যবস্থাপনা পরিচালক এম এ মুবিন খান।
            </p>
            
         </div>
         </Col>
         <Col md={4} data-aos="fade-left">
         <img className='news-details' src={nws2} alt="com" />
         </Col>
         <Col md={12} data-aos="fade-left">
         <p className='line-h'>
           গুরুত্বপূর্ণ সার্ভিস এ্যাপসগুলোর মধ্যে রয়েছে  ডেসকো, ডিপিডিসি ও ওয়াসার বিল চেক, বিএসটিআই এর পণ্য  ভেরিপিকেশন। এনআরবি এর ই-টিআইএন ও ভ্যাট, নন ভ্যাট পণ্য যাচাই, বাংলাদেশ বিমান ও বাংলাদেশ রেলওয়ের সসয়সূচি চেক,  বিনিয়োগ বোর্ড ও জয়েন স্টক কোম্পানির রেজিস্ট্রশন স্ট্যাটাস চেক প্রভৃতি। এ ছাড়াও বঙ্গভবন, প্রধানমন্ত্রীর কার্যালয়, নির্বাচন কমিশন, বিভিন্ন মন্ত্রণালয়, রাজউক, পাবলিক সার্ভিস কমিশন, হাই-টেক পার্কসহ বিভিন্ন সরকারি প্রতষ্ঠান সম্পর্কিত এ্যাপসও তৈরি হয়েছে।

উন্নয়নকৃত মোবাইল অ্যাপসগুলো দেশে BangladeshICTD apps ও আন্তর্জাতিকভাবে GOOGLE PLAY – তে রাখা হবে যেখান থেকে ব্যবহারকারীরা নিজ নিজ মোবাইলে বিনামূল্যে ডাউনলোড ও ইনস্টল করতে পারবেন।

৫০০ মোবাইল অ্যাপ্স এর মধ্যে ৩০০ অ্যাপ্স সরকারের বিভিন্ন মন্ত্রণালয় ও সংস্থাসমূহের তথ্য ও সেবার উপর এবং বাকী ২০০ অ্যাপ্স প্রতিযোগিতামূলক কার্যক্রমের মাধ্যমে পাওয়া নতুন সৃজনশীল ধারণার উপর তৈরি হয়েছে।

‘জাতীয় পর্যায়ে মোবাইল অ্যাপস প্রশিক্ষক ও সৃজনশীল অ্যাপস উন্নয়ন’ শীর্ষক কর্মসূচির আওতায় তথ্য ও যোগাযোগ প্রযুক্তি বিভাগ এ এ্যাপসগুলো উন্নয়ন করে। এ কর্মসূচি বাস্তবায়নের সহযোগি প্রতিস্ঠান এথিকস এডভান্স টেকনোলজি লি. (ইএটিএল)।

রাজধানী থেকে প্রান্তিক জনপদ পর্যন্ত এখন থেকে স্বাস্থ্য, শিক্ষা, বিনোদনসহ দৈনন্দিন জীবনের প্রয়োজনীয় চাহিদাগুলো হাতের মুঠোয় পাওয়া যাবে। ডিজিটাল বাংলাদেশ বাস্তবায়নের অগ্রযাত্রায় এ পদক্ষেপ মাইলফলক হয়ে থাকবে। হাতের মুঠোয় সেবা প্রাপ্তি নিশ্চিত হওয়ায় নাগরিক ভোগান্তি দূর হওয়ার দরুন স্বাচ্ছন্দ্য বোধের পাশাপাশি সময় ও অর্থের সাশ্রয় হবে।

প্রধান অতিথির ভাষণে প্রধান মন্ত্রীর আইসিটি উপদেষ্টা সজীব ওয়াজেদ জয় বলেন, বাংলাদেশ এখন মধ্যম আয়ের দেশের পর্যায়ে চলে এসেছে। আওয়ামী লীগ সরকার যত দিন থাকবে, দেশ এগিয়ে যাবে। এ দেশের তরুণরা দেশকে এগিয়ে নিয়ে যাবে। সরকার, দেশ এমনি এমনি চলে না। বর্তমান সরকারের নেতৃত্বে উন্নয়নের গতি এমনি এমনিই আসেনি। প্রজাতন্ত্রের প্রত্যেক কর্মচারী, কর্মকর্তা, মন্ত্রী-প্রতিমন্ত্রী, সংসদ সদস্যের নিরলস পরিশ্রমে উন্নয়নের এ ধারা অব্যাহত আছে।

তিনি বলেন, এ সরকারের মেয়াদেই সারা দেশে সাতটি আইটি ভোকেশনাল ট্রেনিং ইনস্টিটিউট গড়ে তোলা হবে। গত ১৮ মাসে তথ্য ও প্রযুক্তি মন্ত্রণালয় যতটুকু এগিয়েছে তা কল্পনাতীত। ডিজিটাল বাংলাদেশ গড়ার লক্ষ্যে আমাদের কাজ কেবল শুরু হয়েছে। দেশের এক হাজার ইউনিয়ন পরিষদে ফাইবার অপটিক্যাল কেবল স্থাপনের কাজ চলছে। আশা করি, বর্তমান সরকারের মেয়াদেই দেশের সাড়ে চার হাজার ইউনিয়ন পরিষদকে ফাইবার অপটিক্যাল কেবলের আওতায় আনতে পারব।

 

বিশেষ অতিথির বক্তৃতায় তথ্য ও যোগাযোগ প্রযুক্তি প্রতিমন্ত্রী জুনাইদ আহমেদ পলক এমপি বলেন বিগত ছয় বছরে আমরা অনেক দূর এগিয়েছি। এ সময় কালিয়াকৈরে হাইটেক পার্ককে কেন্দ্র করে ৬০ কোটি টাকা ব্যয়ে আন্তর্জাতিক মানের একটি রেলওয়ে স্টেশন গড়ে তোলা হবে বলে জানান প্রতিমন্ত্রী।

 

চালু করা ৫০০ মোবাইল অ্যাপ্লিকেশনের মধ্যে রয়েছে— ডেসকো, ডিপিডিসি ও ওয়াসার বিল চেক, বিএসটিআইয়ের পণ্য ভেরিফিকেশন, এনআরবির ই-টিআইএন ও ভ্যাট, নন ভ্যাট পণ্য যাচাই, বাংলাদেশ বিমান ও বাংলাদেশ রেলওয়ের সময়সূচি চেক, বিনিয়োগ বোর্ড ও জয়েন্ট স্টক কোম্পানির রেজিস্ট্রেশন স্ট্যাটাস চেক।

 

এছাড়া বঙ্গভবন, প্রধানমন্ত্রীর কার্যালয়, নির্বাচন কমিশন, বিভিন্ন মন্ত্রণালয়, পাবলিক সার্ভিস কমিশন, হাইটেক পার্কসহ বিভিন্ন সরকারি প্রতিষ্ঠান ও সংস্থার অ্যাপস রয়েছে। উন্নয়নকৃত মোবাইল অ্যাপগুলো প্লে স্টোর থেকে ব্যবহারকারীরা বিনামূল্যে ডাউনলোড বা ইনস্টল করতে পারবে।

 

৫০০ মোবাইল অ্যাপের মধ্যে ৩০০ অ্যাপ সরকারের বিভিন্ন মন্ত্রণালয় ও সংস্থার তথ্য ও সেবার ওপর। বাকিগুলো প্রতিযোগিতামূলক কার্যক্রমের মাধ্যমে পাওয়া সৃজনশীল ধারণার ওপর তৈরি হয়েছে। জাতীয় পর্যায়ে ‘মোবাইল অ্যাপস প্রশিক্ষক ও সৃজনশীল অ্যাপস উন্নয়ন’ শীর্ষক কর্মসূচির আওতায় তথ্য ও যোগাযোগপ্রযুক্তি বিভাগ এ অ্যাপগুলো উন্নয়ন করে।</p> 
       
     
       <div className='about-company mt-4 left-0'>
                     <h3 className='mt-0'>
                     ৫০০ মোবাইল অ্যাপস উন্নয়ন ছাড়াও এ কর্মসূচির মাধ্যমে-
                     </h3>
                     <ul className='list-style-one'>
                        <li>   মোবাইল অ্যাপ্স তৈরিতে দক্ষ প্রজন্ম গড়ে তোলার জন্য প্রশিক্ষণের মাধ্যমে দক্ষ প্রশিক্ষক তৈরি এবং তাদের জন্য চাকুরী ও স্বাধীনভাবে কাজ করার কৌশল প্রদান করা হয়েছে;</li>
                        <li>বাংলায় মোবাইল অ্যাপসের মাধ্যমে দেশের জনসাধারণকে, বিশেষতঃ প্রান্তিক জনগোষ্ঠিকে সরকারী সেবা, নীতি ও নিজেদের অধিকার সম্পর্কে অবহিত করে সচেতন করা হয়েছে ;</li>
                        <li>উন্নয়নকৃত মোবাইল অ্যাপ্স ব্যবহারকারীদের মধ্যে বিতরণ ও যোগসূত্র স্থাপন এবং ব্যবহারকারীর প্রতিক্রিয়ার উপর ভিত্তি করে একটি মোবাইল অ্যাপ্স উন্নয়নের নির্দেশিকা ও নীতিমালা  তৈরি করা হয়েছে;</li>
                        <li>শিশুদের জন্য আকর্ষণীয় অ্যাপ্স তৈরী করে তাদের শিক্ষার প্রতি আগ্রহী করে তোলা চেষ্টা করা হবে;</li>
                       
                     </ul>
                     <p>
                        <strong>এ কর্মসূচি সম্পর্কে আরও জানতে চাইলে ভিজিট করুন www.ictd.gov.bd অথবা  www.national500apps.com</strong>
                     </p>
                     <p>
                        <strong>
                        অনুষ্ঠানে উন্নয়নকৃত অ্যাপস সমূহ এবং কর্মসূচির উপর নির্মিত ভিডিও চিত্র প্রদর্শন করা হয়।
                        </strong>
                     </p>
                     <p>
                        <strong>
                        সরকারী-বেসরকারী বিভিন্ন প্রতিষ্ঠানের কর্মকর্তা, পেশাজীবী, তথ্যপ্রযুক্তিবিদ, সুধীজন ও গণমাধ্যমকর্মীবৃন্দ  অনুষ্ঠানে উপস্থিত ছিলেন।
                        </strong>
                     </p>
                  </div>
         </Col>
      </Row>

   </Container>
</Common>
);
};
export default IctMobileApp;