import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import erppr from '../../../assets/images/erppr.png';
import prm2 from '../../../assets/images/prm2.png';
const ERPPharma = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>ERP Pharma</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Products</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>ERP Pharma</h5>
                            <p className='line-h'>
                                Pharmaceutical ERP software is an enterprise resource planning solution especially designed and developed for pharmaceutical companies. It gives you a real time view of the business and the information needed to make effective decisions.
                            </p>
                        </div>

                    </Col>
                    <Col md={6} data-aos="fade-left">
                        <Row className='mt-70'>
                            <Col md={6}>
                                <img className='services-box' src={erppr} alt="erppr" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={prm2} alt="prm2" />
                            </Col>

                        </Row>
                    </Col>
                    <Col md={12} data-aos="fade-left">
                        <div className=''>
                            <p>
                                The modules are:
                            </p>
                            <h5>
                                HR Management :
                            </h5>
                            <p>
                                HR department manages all Human Resource related activities like Leave, Appointment, Training, Promotion, Transfer, Increment, Holidays etc. Leave application setup, process, appointment full process, training schedule, training details, employee full details, employee promotion, employee transfer etc. are also features of this particular module.
                            </p>
                            <h5>
                                Planning Management :
                            </h5>
                            <p>
                                Planning department creates the total production plan. Then the plans are sent for approval and this plan can be approved or canceled. Sometimes the production plan can be made for an individual product as well.
                            </p>
                            <h5>
                                Procurement Management :
                            </h5>
                            <p>
                                The main purpose of this module is purchasing products. Sometimes, it purchases the raw materials. Product purchase can be local or foreign. The responsible person approves local & foreign purchase requisition list, local & foreign tender requisition, purchase order list etc. through this module.
                            </p>
                            <h5>
                                Sales & Marketing Management :
                            </h5>
                            <p>
                                Sales and marketing persons prepare the indent list, approved indent list, complete  the delivery, prepare Challan report, prepare indent report, prepare indent wise delivery report etc. Then the indent is sent to the inventory department for sales invoice. Sometimes, sales persons prepare the sales invoice as well using this module.
                            </p>
                            <h5>
                                Inventory Management :
                            </h5>
                            <p>
                                Responsible person of Inventory Department checks the re-order / stock position when products or materials are needed. He /she delivers the Challan, prepare sales invoice when needed, delivers raw materials and packing materials, approves store transaction, prepares MRR list, receives CPT list, receives production Challan and processes the material receiving report. All these functionalities can be managed easily using this module.
                            </p>

                            <h5>
                                Costing Management :
                            </h5>
                            <p>
                                Costing-in-charge manages the whole costing of the company like transport cost, C & F cost etc. He/she manages the costing report using this module of ERP Pharma. All costing related reports can be prepared with great ease in a minimum amount of time.
                            </p>
                            <h5>
                                QC Management :
                            </h5>
                            <p>
                                QC responsible person manages the quality of all products (raw, finished good). When tender is submitted, QC tests the sample. On the other hand, in the time of production, QC department tests the product step by step and calculates the materials for a product.
                            </p>
                            <h5>
                                Production Management:
                            </h5>
                            <p>
                                Production manager manages all operations of production like production composition, manufacture composition, whole manufacturing procedure, packing procedure, manufacturing and packing order, production completion, product delivery and finally, the production report. All these features are available in Production Management module.
                            </p>
                            <h5>
                                Engineering Management:
                            </h5>
                            <p>
                                Engineering department is mainly a service-oriented department. When the department needs to purchase any engineering vehicles/parts, this module helps manage different phases during the purchase. In some of these purchases, tender handling, tsc preparation etc. need to be done by Engineering department. This module supports these services efficiently. Even, engineering vehicles can be cleaned or checked based on weekly or monthly schedules using this module.
                            </p>
                            <h5>
                                Budgetary Control Management:
                            </h5>
                            <p>
                                Budgetary control department prepares whole budget plan for a year. At the end of a year, it prepares the budget report, budget mapping with accounts, budget variance report etc. This module enables all these budget related sophisticated activities.
                            </p>
                            <h5>
                                Accounts Management:
                            </h5>
                            <p>
                                Accounts department controls all financial transactions in ERP modules. For that reason, this module is integrated with other ERP modules. Financial accounting being a key element of any business, an automated financial accounting system ensures fast, safe and streamlined financial operations in a business, with minimal errors, due to entry-level validations and checks.
                            </p>
                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default ERPPharma;