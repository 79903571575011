import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import anapp from '../../../assets/images/anapp.jpg';



const TrainingProgram = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={8} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>Android Apps Development Workshop and Training Program at UITS</h5>
            <span className='date-title'>Dated : 19 February, 2014</span>
            <p className='line-h'>
            The CSE&IT department of UITS in collaboration with the EATL & Ministry of ICT held a workshop on android application development in smartphone for local students. The department of CSE&IT of UITS invited EATL (Ethics Advanced Technology Ltd.) to organize a 5 days long workshop on mobile application development. 
            </p>

            <p className='line-h'>
            thics Advanced Technology Limited (EATL) is a private company and a leading enterprise with innovative ICT services and solutions provider in Bangladesh and they have introduced the first mobile application software store in Bangladesh. 
            </p>
            <p className='line-h'>
            A total of 24 students participated in this project, out of which, 10 are the UITS students. Mahathir Hasan Bindu, an UITS student, got an award among all the students for his application on online income tax calculation. In the inaugural session and concluding session, the speakers emphasized the needs for arranging this training workshop for students with the objective of enhancing their skills in online outsourcing. Among other, Mr. Mubin Khan, Managing Director of EATL, Mr. Abul Hasan and Mr Shaymaprasad Bapari, additional secretary and joint secretary respectively in the ministry of ICT, Dr. Rajesh Palit, Assistant Professor of North South University, Dr. Afzal Ahmed, Dean, School of Science & Engineering, Dr. Supratip Ghose, Head, CSE&IT and most of the faculties in the department of CSE&IT graced the occasion. Dr. Supratip Ghose and Mr. Imtiaz thanked all the guests, participants and organizers for making this event successful and in his speech the Head of CSE&UIT, Dr. Supratip Ghose emphasized the need for local application software store and university industry collaboration for application development.Mr. Mubin Khan provides students with the opportunity of storing 50 applications in EATL local application store and through those, students can make online earning for themselves and he also promises to provide coaching for the UITS students on the development of the applications by their skilled staff. Mr.Abul Hasan also promises to give internship scope in Bangladesh Computer Council for UITS students.Mr. Shayama Prasad Bepari gives a vibrant message that encourages students to increase their wiling power for the development of Application Programming software. Finally, Dr. Afzal Ahmed thanked all the hosts for creating scope for the students of UITS.
                </p>
         </div>
         </Col>
         <Col md={4} data-aos="fade-left">
         <img className='news-details' src={anapp} alt="com" />
         </Col>
      </Row>

   </Container>
</Common>
);
};
export default TrainingProgram;