import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../layouts/Common";
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
import mdpic from '../../assets/images/mdpic.png';


const MdDetails = () => {
return (
<Common>
<section className='sub-page-banner'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Message</h2>
            
            </Col>
         </Row>
      </Container>
   </section>
<Container className='mb-5'>
      <Row>
         <Col md={4} className='text-center mt-5'>
         <img class="md mt-5" data-aos="fade-right" src={mdpic} alt="img" />
         </Col>
         <Col md={7} className='text-start'>
         <section className='work-process' data-aos="fade-left">
            
            <div className='md-mesaage'>
               <h3 className='mb-1'><i>M. A. Mubin Khan</i></h3>
               <h5><i>Managing Director</i></h5>
               <p className='p-text-justi mb-2'> <i>
               The drive for sustainability begins with desired performance and results, adhering to commitments and technological innovation. EATL has been working on these aspects for more than two decades and focusing on customer satisfaction.<br/><br/>
               I believe in the technically qualified resources that we manage. I believe in the products and software services that we offer to meet our client needs. I believe in our employees, whose energy and ingenuity continue to drive our company forward. I believe in quality as well as an approach to continuous improvement. I honor the commitments with our partners and community.<br/><br/>
               We understand that long-term success depends on our ability to generate superior returns to our customers and drive through innovations for sustained growth. Our vision is to create the best ICT products and services to serve the nation and the globe.


                 </i> </p>

                 {/* <p>
                    <i>
                   <strong>But belief is not enough to realize a vision. We must be willing and able to turn words into action and achieve results.</strong>
                    </i>
                 </p>'
          <p>
            <i>
            Each of our divisions/Practices/teams are working on executing the tasks not only to complete it but also to substantially improve the process with more benefits to end customer <br/>
            We understand that long-term success depends on our ability to generate superior returns to our customers and driving thru innovations for sustained growth.
            </i>
            </p> */}
               
            </div>
         </section>
         </Col>
      </Row>
   </Container>
</Common>
);
};
export default MdDetails;