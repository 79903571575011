import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Common from "../../../layouts/Common";
import ResponsiveGallery from "react-responsive-gallery";
import m16 from "../../../assets/images/m16.jpg";
import m17 from "../../../assets/images/m17.jpg";
import m18 from "../../../assets/images/m18.jpg";
import m19 from "../../../assets/images/m19.jpg";
import m20 from "../../../assets/images/m20.jpg";
import m21 from "../../../assets/images/m21.jpg";
import m22 from "../../../assets/images/m22.jpg";

import m23 from "../../../assets/images/m23.jpg";
import m24 from "../../../assets/images/m24.jpg";
import m25 from "../../../assets/images/m25.jpg";
import m26 from "../../../assets/images/m26.jpg";
import m27 from "../../../assets/images/m27.jpg";
import m28 from "../../../assets/images/m28.jpg";
import m29 from "../../../assets/images/m29.jpg";
import m30 from "../../../assets/images/m30.jpg";
import m31 from "../../../assets/images/m31.jpg";
import m32 from "../../../assets/images/m32.jpg";
import m33 from "../../../assets/images/m33.jpg";



const NewYear = () => {
  return (
    <Common>
      <section className="sub-page-banner newsr-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <h2>Media section</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Media section</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="text-start mt-3">
          <Col md={12}>
            <section className="work-process mt-5">
              <h4 data-aos="flip-up">
              এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’
              </h4>
              <label className="text-gray mb-2 w-100">
              Dated : 12 April, 2014
              </label>
            </section>
          </Col>
        </Row>
        <Col md={12} className="mb-5 border-topn pt-3 media-galery">
          <ResponsiveGallery
            useLightBox
            images={[
              {
                src: `${m16}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 1,
                title:
                "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m17}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 2,
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m18}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 3,
                title:
                "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m19}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 4,
                title:
                "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },

              {
                src: `${m20}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 5,
                title:
                "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m21}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },

              {
                src: `${m22}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 7,
                // imgClassName: 'img6-style',
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },

              {
                src: `${m23}`,
                alt: "gallery",
                orderS: 1,
                orderM:1,
                orderL: 8,
                // imgClassName: 'img6-style',
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m24}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 9,
                // imgClassName: 'img6-style',
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m25}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 10,
                // imgClassName: 'img6-style',
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m26}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 11,
                // imgClassName: 'img6-style',
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m27}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 12,
                // imgClassName: 'img6-style',
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m28}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 13,
                // imgClassName: 'img6-style',
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m29}`,
                alt: "gallery",
                orderS: 1,
                orderM:1,
                orderL: 14,
                // imgClassName: 'img6-style',
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m30}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 15,
                // imgClassName: 'img6-style',
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m31}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 16,
                // imgClassName: 'img6-style',
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m32}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 17,
                // imgClassName: 'img6-style',
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              {
                src: `${m33}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL:18,
                // imgClassName: 'img6-style',
                title:
                  "এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’",
              },
              
            ]}
          />
        </Col>
      </Container>
    </Common>
  );
};

export default NewYear;
