import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Common from "../../layouts/Common";
import ResponsiveGallery from 'react-responsive-gallery';
import gal2 from '../../assets/images/2_1691310830.jpg';
import gal3 from '../../assets/images/3_1691310841.jpg';
import gal4 from '../../assets/images/nws2.jpg';
import gal5 from '../../assets/images/4_1691310849.jpg';
import gal6 from '../../assets/images/IMG_3509.jpeg';



import norts from "../../assets/images/norts.jpg";
import nua from "../../assets/images/nua.jpg";
import korms from "../../assets/images/korms.jpeg";
import bracku from "../../assets/images/bracku.jpg";
import mou from "../../assets/images/mou.jpg";
import diu from "../../assets/images/diu.jpg";
import minapp from "../../assets/images/minapp.jpg";
import dmana from "../../assets/images/dmana.jpg";
import signd from "../../assets/images/signd.jpg";
import family from "../../assets/images/family.jpg";
import devpro from "../../assets/images/devpro.jpg";


import anapp from "../../assets/images/anapp.jpg";
import uits from "../../assets/images/uits.jpg";

import gall1 from "../../assets/images/gall1.jpg";
import gall2 from "../../assets/images/gall2.jpg";
import gall3 from "../../assets/images/gall3.jpg";

import gall4 from "../../assets/images/gall4.jpg";
import gall5 from "../../assets/images/gall5.jpg";
import gall6 from "../../assets/images/gall6.jpg";
import gall7 from "../../assets/images/galrr.jpeg";
import gall8 from "../../assets/images/gall8.jpg";
import gall9 from "../../assets/images/gall9.jpg";
import gall10 from "../../assets/images/gall10.jpg";
import gall11 from "../../assets/images/gall11.jpg";
import gall12 from "../../assets/images/gall12.jpg";
import gall13 from "../../assets/images/gall13.jpg";
import gall14 from "../../assets/images/gall14.jpg";
import invh5 from "../../assets/images/invh.png";

import gall15 from "../../assets/images/gall15.jpg";
import grf1 from "../../assets/images/grf1.jpg";
import grf2 from "../../assets/images/grf2.jpg";
import grf3 from "../../assets/images/grf3.jpg";
import grf4 from "../../assets/images/grf4.jpg";
import grf5 from "../../assets/images/grf5.jpg";

import agr1 from "../../assets/images/agr1.jpg";
import agr2 from "../../assets/images/agr2.jpg";
import agr3 from "../../assets/images/agr3.jpg";
import agr4 from "../../assets/images/agr4.jpg";
import agr5 from "../../assets/images/agr5.jpg";

import agr6 from "../../assets/images/agr6.jpg";
import agr7 from "../../assets/images/agr7.jpg";
import agr8 from "../../assets/images/agr8.jpg";
import agr9 from "../../assets/images/agr9.jpg";
import agr10 from "../../assets/images/agr10.jpg";

import agr11 from "../../assets/images/agr11.jpg";
import agr12 from "../../assets/images/agr12.jpg";
import agr13 from "../../assets/images/agr13.jpg";
import agr14 from "../../assets/images/agr14.jpg";
import agr15 from "../../assets/images/agr15.jpg";
import agr16 from "../../assets/images/agr16.jpg";
import '../contact/contact.css';

const Gallery = () => {
    
    return (
        <Common>
        <section className='sub-page-banner'>
           <Container>
              <Row>
                 <Col md={12} className='text-center sup-page-inner'>
                 <h2>Gallery</h2>
                 <Breadcrumb className='text-center custom-breadcrumb'>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>
                    Gallery
                    </Breadcrumb.Item>
                 </Breadcrumb>
                 </Col>
              </Row>
           </Container>
        </section>
        <Container>
    
        <Row className='text-center mt-3'>
         <Col md={12}>
         <section className='work-process mt-5'>
            <h4 data-aos="flip-up">EATL Gallery</h4>
            
         </section>
         </Col>
      </Row>
      <Col md={12} className='mb-5'>
      <ResponsiveGallery
        useLightBox
        images={[

          {
            src: `${grf1}`,
            alt: 'gallery',
            orderS: 1,
            orderM: 1,
            orderL: 1,
            title: "Grand Finale",
            
          },
          {
            src: `${ grf2}`,
            alt: 'gallery',
            orderS: 2,
            orderM: 2,
            orderL: 2,
            title: "Grand Finale",
            
          },
          {
            src: `${ grf3}`,
            alt: 'gallery',
            orderS: 3,
            orderM: 3,
            orderL:3,
            title: "Grand Finale",
            
          },
         
          {
            src: `${ grf4}`,
            alt: 'gallery',
            orderS: 4,
            orderM: 4,
            orderL:4,
            title: "Grand Finale",
            
          },
          {
            src: `${ invh5}`,
            alt: 'gallery',
            orderS: 5,
            orderM: 5,
            orderL:5,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
            
          },
          {
            src: `${ grf5}`,
            alt: 'gallery',
            orderS: 5,
            orderM: 5,
            orderL:5,
            title: "Grand Finale",
            
          },
          {
            src: `${ agr1}`,
            alt: 'gallery',
            orderS: 6,
            orderM: 6,
            orderL:6,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },

          {
            src: `${ agr2}`,
            alt: 'gallery',
            orderS: 7,
            orderM: 7,
            orderL:7,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr3}`,
            alt: 'gallery',
            orderS:8,
            orderM: 8,
            orderL:8,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr4}`,
            alt: 'gallery',
            orderS: 9,
            orderM: 9,
            orderL:9,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr5}`,
            alt: 'gallery',
            orderS: 10,
            orderM: 10,
            orderL:10,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr6}`,
            alt: 'gallery',
            orderS: 11,
            orderM: 11,
            orderL:11,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr7}`,
            alt: 'gallery',
            orderS: 12,
            orderM: 12,
            orderL:12,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr8}`,
            alt: 'gallery',
            orderS: 13,
            orderM: 13,
            orderL:13,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr9}`,
            alt: 'gallery',
            orderS: 14,
            orderM: 14,
            orderL:14,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr10}`,
            alt: 'gallery',
            orderS: 15,
            orderM: 15,
            orderL:15,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr11}`,
            alt: 'gallery',
            orderS: 16,
            orderM: 16,
            orderL:16,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr12}`,
            alt: 'gallery',
            orderS: 17,
            orderM: 17,
            orderL:17,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr13}`,
            alt: 'gallery',
            orderS: 18,
            orderM: 18,
            orderL:18,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr14}`,
            alt: 'gallery',
            orderS: 19,
            orderM: 19,
            orderL:19,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr15}`,
            alt: 'gallery',
            orderS: 20,
            orderM: 20,
            orderL:20,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
          {
            src: `${ agr16}`,
            alt: 'gallery',
            orderS: 21,
            orderM: 21,
            orderL:21,
            title: "Utilizing AI, Lot and RS for smart Agriculture in Bangladesh",
          },
         


          
          {
            src: `${gal2}`,
            alt: 'gallery',
            orderS: 22,
            orderM: 22,
            orderL: 22,
            title: "Green Earth Quest Contest Launching Ceremony",
            
          },
          {
            src: `${gal3}`,
            alt: 'gallery',
            orderS: 23,
            orderM: 23, 
            orderL: 23,
            title: "Green Earth Quest Contest Launching Ceremony",
          },
          {
            src: `${gal5}`,
            alt: 'gallery',
            orderS: 24,
            orderM: 24,
            orderL: 24,
            title: "Green Earth Quest Contest Launching Ceremony",
          },
          {
            src: `${gall1}`,
            alt: 'gallery',
            orderS:25,
            orderM: 25,
            orderL: 25,
            // imgClassName: 'img6-style',
            title: "Green Earth Quest Contest Launching Ceremony",
          },
          
          {
            src: `${gall2}`,
            alt: 'gallery',
            orderS:26,
            orderM: 26,
            orderL: 26,
            // imgClassName: 'img6-style',
            title: "Green Earth Quest Contest Launching Ceremony",
          },
          {
            src: `${norts}`,
            alt: 'gallery',
            orderS: 27,
            orderM: 27,
            orderL: 27,
            // imgClassName: 'img6-style',
            title: "Closing Ceremony of Android Apps Development Training Program at NSU",
          },
          {
            src: `${nua}`,
            alt: 'gallery',
            orderS: 28,
            orderM:28,
            orderL: 28,
            // imgClassName: 'img6-style',
            title: "MOU signed with NSU",
          },
          {
            src: `${korms}`,
            alt: 'gallery',
            orderS: 29,
            orderM: 29,
            orderL: 29,
            // imgClassName: 'img6-style',
            title: "ডিজিটাল জন্মনিবন্ধন নিয়ে কর্মশালা",
          },
          {
            src: `${bracku}`,
            alt: 'gallery',
            orderS: 30,
            orderM: 30,
            orderL: 30,
            // imgClassName: 'img6-style',
            title: "EATL has signed an agreement with BRAC University.",
          },
          {
            src: `${mou}`,
            alt: 'gallery',
            orderS: 31,
            orderM: 31,
            orderL: 31,
            // imgClassName: 'img6-style',
            title: "MoU signing between EATL & University of Asia Pasific (UAP)",
          },
          {
            src: `${diu}`,
            alt: 'gallery',
            orderS: 32,
            orderM: 32,
            orderL: 32,
            // imgClassName: 'img6-style',
            title: "Android Apps Development Training Program at DIU",
          },
          {
            src: `${minapp}`,
            alt: 'gallery',
            orderS:33,
            orderM: 33,
            orderL: 33,
            // imgClassName: 'img6-style',
            title: "Workshop with Ministry of Education",
          },

          {
            src: `${dmana}`,
            alt: 'gallery',
            orderS:34,
            orderM: 34,
            orderL: 34,
            // imgClassName: 'img6-style',
            title: "Workshop with Food and Disaster Management",
          },
          {
            src: `${signd}`,
            alt: 'gallery',
            orderS:35,
            orderM: 35,
            orderL: 35,
            // imgClassName: 'img6-style',
            title: "MOU signed with DIU",
          },
          {
            src: `${family}`,
            alt: 'gallery',
            orderS:36,
            orderM: 36,
            orderL: 36,
            // imgClassName: 'img6-style',
            title: "Workshop with Ministry of Health and Family Welfare",
          },
          
          {
            src: `${devpro}`,
            alt: 'gallery',
            orderS:37,
            orderM: 37,
            orderL: 37,
            // imgClassName: 'img6-style',
            title: "MOU signing for Mobile Application Development Program",
          },


          {
            src: `${anapp}`,
            alt: 'gallery',
            orderS:38,
            orderM: 38,
            orderL: 38,
            // imgClassName: 'img6-style',
            title: "MOU signing for Mobile Application Development Program",
          },
          {
            src: `${uits}`,
            alt: 'gallery',
            orderS:39,
            orderM: 39,
            orderL: 39,
            // imgClassName: 'img6-style',
            title: "MOU signing for Mobile Application Development Program",
          },
          
          {
            src: `${gal4}`,
            alt: 'gallery',
            orderS: 40,
            orderM: 40,
            orderL: 40,
            title: "আইসিটি বিভাগের উদ্যোগে ৫০০ মোবাইল এ্যাপস এর উদ্বোধন",
          },
         
          {
            src: `${gal6}`,
            alt: 'gallery',
            orderS: 41,
            orderM: 41,
            orderL: 41,
            title: "E-Learning fair at Jessore",
          },
          {
            src: `${gall3}`,
            alt: 'gallery',
            orderS: 42,
            orderM: 42,
            orderL: 42,
            title: "Innerside of the EATL",
          },


          {
            src: `${gall4}`,
            alt: 'gallery',
            orderS: 43,
            orderM: 43,
            orderL: 43,
            title: "",
          },
          {
            src: `${gall5}`,
            alt: 'gallery',
            orderS: 44,
            orderM: 44,
            orderL: 44,
            title: "",
          },
          {
            src: `${gall6}`,
            alt: 'gallery',
            orderS: 45,
            orderM: 45,
            orderL: 45,
            title: "",
          },
          {
            src: `${gall7}`,
            alt: 'gallery',
            orderS: 46,
            orderM: 46,
            orderL: 46,
            title: "",
          },
          {
            src: `${gall8}`,
            alt: 'gallery',
            orderS: 47,
            orderM: 47,
            orderL: 47,
            title: "",
          },
          {
            src: `${gall9}`,
            alt: 'gallery',
            orderS: 48,
            orderM: 48,
            orderL: 48,
            title: "",
          },
          {
            src: `${gall10}`,
            alt: 'gallery',
            orderS: 49,
            orderM: 49,
            orderL: 49,
            title: "",
          },
          {
            src: `${gall11}`,
            alt: 'gallery',
            orderS: 50,
            orderM: 50,
            orderL: 50,
            title: "",
          },
          {
            src: `${gall12}`,
            alt: 'gallery',
            orderS: 51,
            orderM: 51,
            orderL: 51,
            title: "",
          },
          {
            src: `${gall13}`,
            alt: 'gallery',
            orderS: 52,
            orderM: 52,
            orderL: 52,
            title: "",
          },
          {
            src: `${gall14}`,
            alt: 'gallery',
            orderS: 53,
            orderM: 53,
            orderL: 53,
            title: "",
          },
          {
            src: `${gall15}`,
            alt: 'gallery',
            orderS: 54,
            orderM: 54,
            orderL: 54,
            title: "",
          },
        ]}
      />
      </Col>
      

        </Container>
     </Common>
            
          
      
    );
};

export default Gallery;