import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import weba from '../../../assets/images/weba.png';
import weba2 from '../../../assets/images/weba2.png';
const CustomizedWebApplication = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Customized Web Application</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Service</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>Customized Web Application</h5>
                            <p className='line-h'>
                                On web based customized solutions, EATL has expertise in providing several types of solutions in Bangladesh as several application were developed for different sectors i.e. pharmaceutical sector, health sector, education sector etc. Especially, ERP solutions, Asset Management System etc. are some the elegant web based customized solutions that EATL provided to multiple clients as part of bundle of solutions.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} data-aos="fade-right">
                        <Row className='mt-70'>
                            <Col md={6}>
                                <img className='services-box' src={weba} alt="weba" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={weba2} alt="weba2" />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} data-aos="fade-left">
                        <div className='about-company left-0 mt-2'>
                            <p className='line-h mt-5'>
                                EATL has the experience to develop these solutions both in .NET and PHP using different frameworks. Some of the solutions are mentioned below:
                            </p>
                            <h5 className=''>
                                Management Information system for the Ministry of Education:
                            </h5>
                            <p className='line-h'>
                                Actually this is a WebMIS developed for Secondary Education Quality and Access Enhancement Project (SEQAEP), Ministry of education, Government of the Peoples’ Republic of Bangladesh covering 124 upazillas of Bangladesh. This application was customized to support a vast range of services for ensuring the quality of secondary education in different schools of Bangladesh
                            </p>
                            <h5 className=''>
                                Community Clinic Project for the Ministry of Health:
                            </h5>
                            <p className='line-h'>
                                This is completely online and accessed from the different geographical locations of Bangladesh including 12300 CCs. Ministry of Health, Government of the Peoples’ Republic of Bangladesh is the client of EATL for this web based application
                            </p>
                            <h5 className=''>
                                Appointment Management System for the Bangabhaban:
                            </h5>
                            <p className='line-h'>
                                It is a website and appointment management system for the office of the Honourable President of the Government of the Peoples’ Republic of Bangladesh
                            </p>
                            <h5 className=''>
                                Hospital Information Management System for IMCH:
                            </h5>
                            <p className='line-h'>
                                This product is functional in International Medical College and Hospital (IMCH), Tongi which is an online MIS. It manages the functionalities of any large medical college and hospital and its branches.
                            </p>
                        </div>
                    </Col>

                    <Col md={12} data-aos="fade-right">
                        <div className='about-company mt-4 left-0'>
                            
                            <p className='line-h'>
                            EATL has proficient experience in developing both monolithic and micro-services-based architecture. EATL has worked on some of the major micro-services based distributed systems of Bangladesh:
                            </p>

                         <h5 className=''>
                        	Supply, Installation and Commissioning and Operation of Electronic Fiscal Device Management Systems (EFDMS) along with Electronic Fiscal Device (EFD), Sales Data Controller (SDC) and Managed Services: 
                            </h5>
                            <p className='line-h'>
                            EATL started working on this project for National Board of Revenue (NBR) of Ministry of Finance of Bangladesh on September 2019. This micro-service architecture-based software development was the first ever in Bangladesh and was successfully completed in August 2020. The purpose of this assignment was to capture and store and transaction data of the retail/ wholesale outlets in the country.
                            </p>

                            <h5 className=''>
                            Development and Implementation of Examination Management System for National University:
                            </h5>
                            <p className='line-h'>
                            In May 2019 EATL began this project, which was successfully completed in June 2021. Currently, operations and maintenance are underway. The goal of the Examination Management System (EMS) was to automate the entire process of the Examination Division of National University.
                            </p>
                           
                            <h5 className=''>
                            Software design and development for Educational Information Management to be used by MOE, BANBEIS, DSHE, DTE, DME, NTRCA and others with maintenance support for 3 (three) years – IEMIS Project
                            </h5>
                            <p className='line-h'>
                            EATL is currently working on this project Bangladesh Bureau of Educational Information & Statistics (BANBEIS), Ministry of Education. This is also another large deployment with micro-services architecture.
                            </p>
                           
                            
                            </div>
                        </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default CustomizedWebApplication;