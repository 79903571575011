import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Common from "../../../layouts/Common";
import ResponsiveGallery from "react-responsive-gallery";
import ek1 from "../../../assets/images/ek1.jpg";
import ek2 from "../../../assets/images/ek2.jpg";
import ek3 from "../../../assets/images/ek3.jpg";
import ek4 from "../../../assets/images/ek4.jpg";
import ek5 from "../../../assets/images/ek5.jpg";
import ek6 from "../../../assets/images/ek6.jpg";
import ek7 from "../../../assets/images/ek7.jpg";
import ek8 from "../../../assets/images/ek8.jpg";
import ek9 from "../../../assets/images/ek9.jpg";
import ek10 from "../../../assets/images/ek10.jpg";


import ek11 from "../../../assets/images/ek11.jpg";
import ek12 from "../../../assets/images/ek12.jpg";
import ek13 from "../../../assets/images/ek13.jpg";
import ek14 from "../../../assets/images/ek14.jpg";
import ek15 from "../../../assets/images/ek15.jpg";
import ek16 from "../../../assets/images/ek16.jpg";
import ek17 from "../../../assets/images/ek17.jpg";
import ek18 from "../../../assets/images/ek18.jpg";
import ek19 from "../../../assets/images/ek19.jpg";



const TwentyOneApp = () => {
  return (
    <Common>
      <section className="sub-page-banner newsr-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <h2>Media section</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Media section</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="text-start mt-3">
          <Col md={12}>
            <section className="work-process mt-5">
              <h4 data-aos="flip-up">
              মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।
              </h4>
              <label className="text-gray mb-2 w-100">
              Dated : 02 February, 2014
              </label>
            </section>
          </Col>
        </Row>
        <Col md={12} className="mb-5 border-topn pt-3 media-galery">
          <ResponsiveGallery
            useLightBox
            images={[
              {
                src: `${ek1}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 1,
                title:
                "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
         
              
              {
                src: `${ek2}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 2,
                title:
                "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              {
                src: `${ek3}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 3,
                title:
                "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },

              {
                src: `${ek4}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 4,
                title:
                "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              {
                src: `${ek5}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 5,
                // imgClassName: 'img6-style',
                title:
                "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },

              {
                src: `${ek6}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },

              {
                src: `${ek7}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 7,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              {
                src: `${ek8}`,
                alt: "gallery",
                orderS:1,
                orderM: 1,
                orderL: 8,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              {
                src: `${ek9}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 9,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              {
                src: `${ek10}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 10,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              
              {
                src: `${ek11}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 11,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              
              {
                src: `${ek12}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 13,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              {
                src: `${ek13}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 14,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              {
                src: `${ek14}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 15,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              {
                src: `${ek15}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 16,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              {
                src: `${ek16}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 17,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              {
                src: `${ek17}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 18,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              {
                src: `${ek18}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 19,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
              {
                src: `${ek19}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 20,
                // imgClassName: 'img6-style',
                title:
                  "মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।",
              },
            ]}
          />
        </Col>
      </Container>
    </Common>
  );
};

export default TwentyOneApp;
