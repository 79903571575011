import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Button from 'react-bootstrap/Button';
import '../../common/footer/Footer.css';
import logo from '../../assets/images/mainlogo.svg';
import { NavLink } from 'react-router-dom';
import { FaPeriscope, FaRegEnvelope } from 'react-icons/fa';
import { FaMobileAlt } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { FaTwitter } from 'react-icons/fa';
import { FaLinkedinIn } from 'react-icons/fa';
import BackToTop from 'react-back-to-top-button';
const Footer = () => {
    return (
<div className='footer-area'>
  <Container>
    <Row>
      <Col md={5}>
      <img className='logo-top' src={logo} alt="logo" />
      <p>
      Ethics Advanced Technology Limited (EATL) is a private company and a leading enterprise with innovative ICT services and solutions provider in Bangladesh established in November 1999.
      </p>
      <div className='social-icon'>
       
          <a href='https://www.facebook.com/EatlApps' target="_blank">
          <Button variant="btn">
          <FaFacebookF/>
          </Button>
          </a>
        
        
        <a href='https://twitter.com/i/flow/login?redirect_after_login=%2Featlbd' target="_blank">
          <Button variant="btn">
          <FaTwitter/>
          </Button>
          </a>
          <a href='' target="_blank">
          <Button variant="btn">
          <FaLinkedinIn/>
          </Button>
        </a>
      </div>
      </Col>
      <Col md={4}>
      <h3>Get In Touch</h3>
      <ul className='list-group list-inline company-list-link'>
        <li> <span>
          <FaPeriscope/>
          </span>
          <label> Corporate Office: Rupayan Shopping Square, Level-5, Plot-2, Block-G, Sayem Sobhan Anvir Road, Bashundhara R/A, Dhaka-1229, Bangladesh. </label>
        </li>
        <li> <span>
          <FaRegEnvelope/>
          </span>
          <label>info@eatlbd.com </label>
        </li>
        <li> <span>
          <FaMobileAlt/>
          </span>
          <label>+88-09614-444000 </label>
        </li>
      </ul>

      </Col>
      <Col md={3} className="link-list company-list-link">
      <h3>Useful links</h3>
      <ul className='list-group list-inline'>
       
        <li>
          <a  href="http://www.edutubebd.com/" target='_blank'>Edutube</a>
        </li>
        <li>
          <NavLink exact to="">EATLApps </NavLink>
        </li>
        <li>
          <NavLink exact to="">Career </NavLink>
        </li>
        <li>
        <a  href="https://greenearthquest.org/" target='_blank'>Green Earth Quest</a>
        </li>
        <li>
          <NavLink exact to="">Sitemap </NavLink>
        </li>
        <li>
        <a  href="https://www.eatlbd.com:2096/" target='_blank'>webmail</a>
        </li>
      </ul>
      </Col>
  
    </Row>
   <br/>   <br/>
    <Row className="footer-bottom">
      <Col md={12} className='text-center'>
      <p>© Copyright 2023 Ethics Advance Technology Limited (EATL). All Rights Reserved. </p>
      </Col>
      
    </Row>
  </Container>

   <BackToTop
showOnScrollDown
showAt={1000}
speed={1500}
easing="easeInOutQuint"
>
<button className='back-to-top'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24"><path d="M0 16.67l2.829 2.83 9.175-9.339 9.167 9.339 2.829-2.83-11.996-12.17z"/></svg></button>
</BackToTop> 
</div>
);
};

export default Footer;