import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import elibrary from '../../../assets/images/e-library.png';

const ELibrary = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Establish e-Library Management and Set up web-based Medical Student Facilities Management</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Projects</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={8}>
                        <div className='about-company mt-70 left-0' data-aos="fade-right">
                            <h5>Establish e-Library Management and Set up web-based Medical Student Facilities Management</h5>
                            <p>
                                EATL started the "Establish e-Library Management and Set Up Web-Based Medical Student Facilities Management" project for the Directorate General of Medical Education (DGME) in March 2023, and it was successfully completed in June 2021. The total value of this project is $385 Thousand USD. The aim of this project was to develop an (i) e-library Management System that can handle & and manage the activities involved in a library in an efficient & and reliable way for all Public Medical Colleges and Dental Colleges of Bangladesh. for 37 Medical Colleges and 8 Dental Unit. The following services included
                            </p>

                            <Col md={12} data-aos="fade-left">
                        <div className=' '>
                            <h5 class="mt-5">The aim of this project is to develop a:</h5>
                            <ul className='list-style-one mt-2'>
                                <li>e-Library Management System (LMS) that can handle & manage the activities involved in a library in an efficient & reliable way and also develop a web-based </li>
                                <li>Medical Student Facility Management System for all Public Medical Colleges and Dental Colleges to manage & monitor academic activities effectively. Both the system developed </li>
                                <li>Design, development and implementation of Dynamic Web Portal</li>
                                <li>Mobile Application Development (Android and iOS)</li>
                                <li>Design, development and implementation of Dashboard</li>
                                <li>Design & Development of a Medical Student Facility Management System</li>
                                <li>Design & Development of a e-Library Management System</li>
                                <li>Supply and Delivered related hardware</li>
                                <li>Medical eBook & eJournal resource availability</li>
                                <li>Organize Workshop & Training</li>
                                <li>Provide 24 months support services, and </li>
                                <li>SMS services possessing.</li>
                            </ul>
                        </div>
                    </Col>

                            <h5 className='mt-4'>Name of Client:</h5>
                            <p>
                                Directorate General of Medical Education (DGME)
                            </p>

                        </div>

                    </Col>
                    <Col md={4} data-aos="fade-left">

                        <div className='about-company mt-70'>
                            <img className='nu-all mt-5' src={elibrary} alt="elibrary" />

                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default ELibrary;