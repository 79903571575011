import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import edu from '../../../assets/images/edu.svg';

const Edutube = () => {
return (
<Common>
   <section className='sub-page-banner'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Edutube</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Projects</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row>
         <Col md={6}>
         <div className='about-company mt-70 left-0' data-aos="fade-right">
            <h5>EDUTUBE BACKGROUND</h5>
            <p>
            The purpose of edutubebd is to remove every barrier between a similar level students in Bangladesh so that everyone gets same benefit out of learning and sharing. Majority students of Bangladesh lacks from many education facilities, contents, lectures, notes, demonstration because of proper guidance, support & infrastructure. Few affluent students get touch of excellent education materials mostly in urban areas, thus can enhance their knowledge, learning to develop & nourish their talent extraordinary. With the help of technology if we can build a platform where these facilitated students, well known teachers will provide their materials absolutely free just to help & enhance another student who couldn't access it because of location & affordability, within just a click in the portal a student of class10 , though residing in village, far way, will be able to view the same note, lectures, suggestions, that Dhaka’s best school A+ student has. In this portal anyone can have an account or subscribe for free. Then any student, teachers, parents or guardians can log in to upload their education materials & anyone can download to access.
            </p>
<h5 className='mt-4'>COMPANY INFORMATION</h5>
            <p>
            edutubebd is a part of the product portfolio of Ethics Advanced Technologies Limited (EATL). Ethics Advanced Technology Limited (EATL) is a private company and a leading enterprise with innovative ICT services and solutions provider in Bangladesh established in November 1999. EATL’s business strategy combines application support, system design, database administration, feasibility study, software development, hardware solution, training, social media consultancy and ICT services in development sectors as well. EATL launched the first ever mobile application store in Bangladesh in 2012 which is branded as ‘EATLApps’. Find more about EATL in www.eatlbd.com and get exciting android mobile apps from Bangladesh first mobile apps store launched by EATL in www.eatlapps.com Company head Office Contact House#9 (5th floor), Road#16, Gulshan-1, Dhaka-1212, Bangladesh +88-02-8153220, +88-02-8153219 For any queries regarding edutubebd mail us at edutubebd.com or join at our facebook page
            </p>
     

          

            <p className='mt-3'>
            The major focus of EATLapps is to develop apps form the young talent of our country. Currently there are around 100 apps in the appstore comprising of 12-13 categories. “EATL Apps” hosts apps based on different mobile device configurations and safe payment procedure for users from both home and abroad. The apps are made to emphasize users' demand in all classes to attract more developers to come up with innovative ideas. Currently GP subscribers can download our free apps without data charge. And other paid apps can also be downloaded without paying any data charge for the downloads. DBBL credit card and Bkash online payment is also applicable for our apps purchase. Every year, our sister company - EATL organizes a nationwide apps development contest to search the brightest talent in apps development area. The top 10 apps from every year's contest can also be found in our store. Following documents may be helpful for you to get more idea about out contest
            </p>
         </div>
     
         </Col>
         <Col md={6} data-aos="fade-left">
       
         <div className='about-company mt-70'>
         <img className='edu mt-5' src={edu} alt="edu" />

         <Row>
         <Col md={6} data-aos="fade-left">
         <h5>Educational Content Sharing</h5>
           <ul trype="square" className='ps-3 ms-1'>
            <li>
            Class materials
            </li>

            <li>
            Assignment & quiz

            </li>

            <li>
            Teaching-learning process virtualization

            </li>
           </ul>
           <h5>Educational Content Sharing</h5>
            <ul trype="square" className='ps-3 ms-1'>
            <li>
            Teacher, student, course information
            </li>

            <li>
            Sharing of course materials, assignment
            </li>

            <li>
            Attendance, online quiz, performance records 
            </li>
            <li>
            Performance tracking
            </li>
            <li>
            Management/administration
            </li>
            <li>
           Reporting and delivery of educational programs
            </li>
           </ul>
         </Col>

         <Col md={6} data-aos="fade-right">
         <h5>Virtual Classroom (Online Training)</h5>
           <li>Students attend classes remotely</li>
           <li>Real-time interaction between students and teachers</li>
           <li>Third-party online class integration (zoom)</li>
            <ul className='list-style-one mt-5'>
               <li> <strong>Edutube Content</strong></li>
               <li> <strong>Edutube LMS</strong></li>
               <li> <strong>Edutube Live</strong></li>
        
            </ul>
         </Col>
         </Row>
        
          

           
          
            
         </div>
         </Col>
      
      </Row>
   </Container>
</Common>
);
};
export default Edutube;