import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import serbmoa from '../../../assets/images/serbmoa.png';
import servma2 from '../../../assets/images/servma2.png';
const MobileApps = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Service based Mobile Apps</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Service</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>Service based Mobile Apps</h5>
                            <p className='line-h'>
                                The limitations of mobile devices (small screen, small keyboard, limited memory, battery powered, unstable connections, ...) as well as its advantages (always available, personal device, location-aware) has put new and different requirements on the development of mobile applications compared to desktop applications.
                            </p>
                            <p className='line-h'>
                                EATL is a mobile application development and consulting company, which supports the development of mobile applications that are aware of the user's context (e.g., profile, device characteristics), his current (physical) environment, and the people, objects and places in it. By exploiting this knowledge on the user and his environment, such applications are able to provide personalized information and services to the user.
                            </p>
                            <p className='line-h'>
                                EATL provides mobile applications with an integrated and extensive view on the user's environment, by acquiring and integrating data from different, decentralized sources. Moreover, EALT apps supports different sensing technologies to become aware of the user's surrounding environment (and the physical entities in it), and is primarily based on Web technologies for communication and data acquisition, and Semantic Web technologies for integrating and enriching the knowledge present in the decentralized data sources.
                            </p>
                            <p className='line-h'>
                                The Detection option is responsible for detecting identifiable physical entities in the vicinity of the user; by employing sensing technologies which are increasingly available in modern mobile devices (e.g., RFID, Bluetooth, GPS, WIFI, QR code, Microsoft Tag) or taking require entities through communicate with relevant server (usually in the form of a URL pointing to an existing Website or data source).
                            </p>

                        </div>
                    </Col>
                    <Col md={6} data-aos="fade-left ">
                        <Row className='mt-70'>
                            <Col md={6}>
                                <img className='services-box' src={serbmoa} alt="serbmoa" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={servma2} alt="servma2" />
                            </Col>
                            <p className='line-h'>
                                We also provides mobile solution for some basic services that provide access of: pull-based data retrieval, where arbitrary data queries are issued over the different data sources using the Query Service, and push-based data retrieval, where a Notification Service monitors changes in the environment and alerts registered applications of specific changes. Finally, after censorious analyzing on the local context and demand presently EATL has been developing apps for the following major categories field:
                            </p>
                            <ul className='list-style-one'>
                                <li>Agriculture</li>
                                <li>Business and finances</li>
                                <li>Education</li>
                                <li>Health and Fitness</li>
                                <li>Food</li>
                                <li>Games and Fun</li>
                                <li>Life Style</li>
                                <li>News</li>
                                <li>Romance</li>
                                <li>Tourism</li>
                            </ul>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Common>
    );
};
export default MobileApps;