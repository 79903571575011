import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Common from "../../layouts/Common";
import { NavLink } from "react-router-dom";
import news1 from "../../assets/images/news1.jpeg";
import ns2 from "../../assets/images/ns2.jpg";
import ns3 from "../../assets/images/nws2.jpg";
import ns4 from "../../assets/images/ns4.jpg";
import anapp from "../../assets/images/anapp.jpg";
import norts from "../../assets/images/norts.jpg";
import nua from "../../assets/images/nua.jpg";
import korms from "../../assets/images/korms.jpeg";
import uits from "../../assets/images/uits.jpg";
import aiub from "../../assets/images/aiub.jpg";
import womin from "../../assets/images/womin.jpg";
import mou from "../../assets/images/mou.jpg";
import diu from "../../assets/images/diu.jpg";
import minapp from "../../assets/images/minapp.jpg";
import dmana from "../../assets/images/dmana.jpg";
import signd from "../../assets/images/signd.jpg";
import family from "../../assets/images/family.jpg";
import devpro from "../../assets/images/devpro.jpg";
import gal2 from '../../assets/images/2_1691310830.jpg';
import ednews from '../../assets/images/ednews.png';
import edutun from '../../assets/images/edutun.png';




import Breadcrumb from "react-bootstrap/Breadcrumb";

const Newsroom = () => {
  return (
    <Common>
      <section className="sub-page-banner newsr-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner ">
              <h2>Newsroom</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container className="mb-5">
        <div class="about-company mt-5  left0-all">
          <h5 className="ms-0 mb-4 fonsize-news">News</h5>
        </div>

        <Row className="ms-0">
        <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <a href="https://www.banglanews24.com/national/news/bd/1162045.details"  rel="noreferrer" target="_blank">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={gal2} alt="img" />
                </div>
                <div className="news-right">
                  <h5>পরিবেশ সচেতনতায় শিক্ষার্থীদের প্রতিযোগিতা, সেরা দল পাবে ৩
লাখ টাক</h5>
                  <p>
                  ঢাকা: পরিবেশ নিয়ে শিক্ষার্থীদের সচেতনতা বাড়াতে ‘গ্রিন আর্থকোয়েস্ট’ প্রতিযোগিতার আয়োজন করেছে
তথ্যপ্রযুক্তি...
                  </p>
                </div>
              </div>
            </a>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <a href="https://www.banglanews24.com/national/news/bd/1162045.details" rel="noreferrer" target="_blank">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={ednews} alt="img" />
                </div>
                <div className="news-right">
                  <h5>এডুটিউব কুইজ প্রতিযোগিতায় চ্যাম্পিয়ন দল পাবে ৫ লাখ টাকা</h5>
                  <p>
                  মাধ্যমিক পর্যায়ের শিক্ষার্থীদের অংশগ্রহণে শুরু হয়েছে এডুটিউব কুইজ প্রতিযোগিতা–২০২২। গত মঙ্গলবার সন্ধ্যায় রাজধানীর...
                  </p>
                </div>
              </div>
            </a>
          </Col>

    

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <a href="https://www.banglanews24.com/national/news/bd/929142.details?fbclid=IwAR0MWJZZWf5uyx9HvkqofKbwmMpIgp6GvcX3xGCAvav9SFpbEgAXAhML0SA" rel="noreferrer" target="_blank">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={edutun} alt="img" />
                </div>
                <div className="news-right">
                  <h5>এডুটিউব কুইজ কনটেস্ট: ২৬ লাখ টাকার পুরস্কার</h5>
                  <p>
                  এথিকস এডভান্স টেকনোলজি লিমিডেট (ইএটিএল) আয়োজিত মাধ্যমিক পর্যায়ের স্কুল শিক্ষার্থীদের অংশগ্রহণে এডুটিউব কুইজ কনটেস্ট ২০২২-এর উদ্বোধন...
                  </p>
                </div>
              </div>
            </a>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/elearning">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={news1} alt="img" />
                </div>
                <div className="news-right">
                  <h5>E-Learning fair at Jessore.</h5>
                  <p>
                    A girl of class five student, expressed her feelings of
                    astonishment, on watching the beautiful animations made
                    on...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/digital-book">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={ns2} alt="img" />
                </div>
                <div className="news-right">
                  <h5>নতুন বছরে ডিজিটাল বই পাচ্ছেন স্কুল শিক্ষার্থীরা</h5>
                  <p>
                    নবম ও দশম শ্রেণির জন্য দেশে প্রথমবারের মতো নির্মিত ই-লার্নিং
                    ও ই-ম্যানুয়েল...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/ict-mobile-app">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={ns3} alt="img" />
                </div>
                <div className="news-right">
                  <h5>আইসিটি বিভাগের উদ্যোগে ৫০০ মোবাইল এ্যাপস এর উদ্বোধন</h5>
                  <p>
                    ঢাকা:(২৬ জুলাই): মোবাইল আমাদের পথচলার নিত্য সঙ্গী। জীবন
                    বাঁচাতে আর...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/signed-brac-university">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={ns4} alt="img" />
                </div>
                <div className="news-right">
                  <h5>EATL has signed an agreement with BRAC University.</h5>
                  <p>
                    Ethics Advanced Technology Limited (EATL) has signed an
                    agreement with BRAC ...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/training-program">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={anapp} alt="img" />
                </div>
                <div className="news-right">
                  <h5>Android Apps Development Training Program at UAP</h5>
                  <p>
                    গত ১৬ জানুয়ারী ২০১৪, ইউনিভার্সিটি অফ এশিয়া প্যাসিফিক-এ শুরু
                    হয়েছে ৫ দিন ব্যাপি প্রশিক্ষন কর্মশালা...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/closing-ceremony">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={norts} alt="img" />
                </div>
                <div className="news-right">
                  <h5>
                    Closing Ceremony of Android Apps Development Training
                    Program at NSU
                  </h5>
                  <p>
                    গত ১৫ জানুয়ারী ২০১৪, মঙ্গলবার নর্থ সাউথ ইউনিভার্সিটিতে
                    অনুষ্ঠিত হয়ে গেল জাতীয় পর্যায়ে মোবাইল এ্যাপ্লিকেশন
                    উন্নয়নে...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/mou-signed-nsu">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={nua} alt="img" />
                </div>
                <div className="news-right">
                  <h5>MOU signed with NSU</h5>
                  <p>
                    EATL has signed an agreement with North South University
                    (NSU) – The top private university in Bangladesh, for
                    collaboration in ICT and its development. It was signed
                    in...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/digital-birth-certificate">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={korms} alt="img" />
                </div>
                <div className="news-right">
                  <h5>ডিজিটাল জন্মনিবন্ধন নিয়ে কর্মশালা </h5>
                  <p>
                    গত ১লা সেপ্টেম্বর, ২০১৫ বিয়াম ফাউন্ডেশন, ঢাকায় প্ল্যান
                    ইন্টারন্যাশনাল বাংলাদেশ ও এথিকস এডভান্স টেকনোলজি লিমিটেড (
                    ইএটিএল) এর যৌথ উদ্যোগে ডিজিটাল জন্ম...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/training-program-uits">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={uits} alt="img" />
                </div>
                <div className="news-right">
                  <h5>
                    Android Apps Development Workshop and Training Program at
                    UITS
                  </h5>
                  <p>
                    ঢাকা:(২৬ জুলাই): মোবাইল আমাদের পথচলার নিত্য সঙ্গী। জীবন
                    বাঁচাতে আর জীবন সাজাতে মোবাইলের...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/eatl-signed-aiub">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={aiub} alt="img" />
                </div>
                <div className="news-right">
                  <h5>
                    EATL has signed an agreement with American International
                    University – Bangladesh (AIUB)
                  </h5>
                  <p>
                    Ethics Advanced Technology Limited (EATL) has signed an
                    agreement with American International University...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>

 

          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/mou-signing-eatl-uap">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={mou} alt="img" />
                </div>
                <div className="news-right">
                  <h5>
                    MoU signing between EATL & University of Asia Pasific (UAP)
                  </h5>
                  <p>
                    Ethics Advanced Technology Limited (EATL) has signed an
                    agreement with University of Asia Pacific (UAP) today...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/training-program-diu">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={diu} alt="img" />
                </div>
                <div className="news-right">
                  <h5>
                  Android Apps Development Training Program at DIU
                  </h5>
                  <p>
                  EATL started training in Daffodil University on Mobile application development, this project is financed by MOICT...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/workshop-ministry-education">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={minapp} alt="img" />
                </div>
                <div className="news-right">
                  <h5>
                  Workshop with Ministry of Education
                  </h5>
                  <p>
                  অনুষ্ঠিত হয়ে গেল শিক্ষা মন্ত্রণালয় সংশ্লিষ্ট প্রতিষ্ঠানগুলোর জন্য মোবাইল এ্যাপ্লিকেশন আইডিয়া ইনভেনশন কর্মশালা আজ সকাল ৯টায়, নিউমার্কেটের ন্যাশনাল অথরিটি অব এডুকেশন...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/workshop-food-disaster-management">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={dmana} alt="img" />
                </div>
                <div className="news-right">
                  <h5>
                  Workshop with Food and Disaster Management
                  </h5>
                  <p>
                  খাদ্য ও দুর্যোগ ব্যবস্থাপনা সংশ্লিষ্ট প্রতিষ্ঠানগুলোর জন্য মোবাইল এ্যাপ্লিকেশন আইডিয়া উদ্ভাবন কর্মশালা আজ সকাল ৯টায়, পল্টনের হাউজ বিল্ডিং ফাইনান্স কর্পোরেশনের...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/workshop-with-ministry-agriculture">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={womin} alt="img" />
                </div>
                <div className="news-right">
                  <h5>
                  Workshop with Ministry of Agriculture
                  </h5>
                  <p>
                  বাংলাদেশের কৃষি মন্ত্রণালয়ের জন্য মোবাইল এ্যাপ্লিকেশন আইডিয়া উদ্ভাবন কর্মশালা অনুষ্ঠিত গণ প্রজাতন্ত্রী বাংলাদেশ সরকারের তথ্য ও যোগাযোগ প্রযুক্তি মন্ত্রণালয়...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>


          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/mou-signed-diu">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={signd} alt="img" />
                </div>
                <div className="news-right">
                  <h5>
                  MOU signed with DIU
                  </h5>
                  <p>
                  ATL and Daffodil International University signed an agreement on 30 th November 2013. As per this agreement both of the parties will work closely in three areas. Which...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>

          
          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/health-family-welfare">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={family} alt="img" />
                </div>
                <div className="news-right">
                  <h5>
                  Workshop with Ministry of Health and Family Welfare
                  </h5>
                  <p>
                  বাংলাদেশের স্বাস্থ্য ও পরিবার পরিকল্পনা সেবার জন্য মোবাইল এ্যাপ্লিকেশন আইডিয়া উদ্ভাবন কর্মশালা অনুষ্ঠিত গণ প্রজাতন্ত্রী...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/mou-mobile-application-development">
              <div className="newsrom-block">
                <div className="news-thumb ">
                  <img src={devpro} alt="img" />
                </div>
                <div className="news-right">
                  <h5>
                  MOU signing for Mobile Application Development Program
                  </h5>
                  <p>
                  MOU signing ceremony for "Mobile Application Development Program at the national level to increase awareness and...
                  </p>
                </div>
              </div>
            </NavLink>
          </Col>
        </Row>

        <Row className="border-topn row ms-0 mt-4">
        <div class="about-company mt-4 left0-all">
          <h5 className="ms-0 mb-4 fonsize-news">Media section</h5>
        </div>



        <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/prothom-alo-app">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতা গ্র্যান্ড ফিনালে-২০১৫</h5>
                 <span className="date-sp">28 December, 2015</span>
                </div>
              </div>
            </NavLink>
          </Col>

          
        <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/mobile-apps-bangla">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>Govt to make 500 mobile apps in Bangla</h5>
                 <span className="date-sp">02 September, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>


     
          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/new-year">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>এ বৈশাখে অ্যান্ড্রয়েড অ্যাপ ‘নববর্ষ ১৪২১’
</h5>
                 <span className="date-sp">12 April, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>
               
        <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/gp-subscribers">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>30 EATL Health Apps in Java & Android Mobile launched for GP Subscribers</h5>
                 <span className="date-sp">08 April, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/prothom-alo-result">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।</h5>
                 <span className="date-sp">30 March, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/eatl-26-mobile-app">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>এথিক্স অ্যাডভান্সড টেকনোলজি লি. (ইএটিএল) বাজারে এনেছে স্বাধীনতা ২৬ নামক মোবাইল অ্যাপ।</h5>
                 <span className="date-sp">27 March, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/top-10-apps">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>শীর্ষ ১০ অ্যাপসের নাম ঘোষণা</h5>
                 <span className="date-sp">03 March, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/top-ten-available">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>এখন শীর্ষ দশ পাওয়া যাবে আজ</h5>
                 <span className="date-sp">02 March, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/new-apple-support">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>নতুন অ্যাপস ‘ল সাপোর্ট’ ও ‘টিউশন ম্যাপ’</h5>
                 <span className="date-sp">19 February, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/touch-of-love">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>মোবাইল অ্যাপসে ভালোবাসা আর বসন্তের ছোঁয়া</h5>
                 <span className="date-sp">17 February, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>

        <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/agreement-with-eatl">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>ইএটিএলের সঙ্গে চুক্তিবদ্ধ হলো চুয়েট</h5>
                 <span className="date-sp">16 February, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/twenty-one-app">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>মোবাইল অ্যাপস 'একুশ আমার' তৈরি করল অ্যাপ তৈরির প্রতিষ্ঠান ইএটিএল।</h5>
                  <span className="date-sp">02 February, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/prothomalo-apps-competition">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতা ২০১৪, হয়ে গেল দিনব্যাপী ‘গ্রুমিং সেশন’</h5>
                  <span className="date-sp">02 February, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/agreemen-with-brac-university">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>ইএটিএল ও ব্র্যাক ইউনিভার্সিটির মধ্যে চুক্তি</h5>
                  <span className="date-sp">23 January, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>


          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/innovate-mobile-apps">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>UAP, EATL sign MoU to innovate mobile apps</h5>
                  <span className="date-sp">22 January, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/second-phase-results-announced">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>অ্যাপস প্রতিযোগিতার দ্বিতীয় পর্যায়ের ফল প্রকাশ</h5>
                  <span className="date-sp">20 January, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact >
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>এথিকস অ্যাডভান্সড টেকনোলজি লিমিটেড (ইএটিএল) ও প্রথম আলো গোলটেবিল বৈঠক: কৃষি খাতে যোগাযোগ ও তথ্যপ্রযুক্তির ব্যবহার।</h5>
                  <span className="date-sp">19 January, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/apps-development-workshop">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>ঢাকায় শুরু হয়েছে পাঁচ দিনের অ্যাপস উন্নয়ন কর্মশালা। তথ্য ও যোগাযোগপ্রযুক্তি মন্ত্রণালয়ের এই উদ্যোগটি ব্যাস্তবায়নে সহায়তা করছে ইএটিএল ।</h5>
                  <span className="date-sp">13 January, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>


          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/uap-student-develops-new-app">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>নতুন মোবাইল অ্যাপস তৈরী করল ইউএপি শিক্ষার্থীরা</h5>
                  <span className="date-sp">17 January, 2014</span>
                </div>
              </div>
            </NavLink>
          </Col>

          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/concept-papers-apps-competition">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>হয়ে গেল অ্যাপস প্রতিযোগিতার ১২৫ ধারণাপত্রের উপস্থাপনা</h5>
                  <span className="date-sp">02 November, 2013</span>
                </div>
              </div>
            </NavLink>
          </Col>




          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact >
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>Campus activation at IUBAT</h5>
                  <span className="date-sp">23 October, 2013</span>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact>
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>অ্যাপসের ৮০৫টি ধারণাপত্র জমা পড়েছে</h5>
                  <span className="date-sp">03 October, 2013</span>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/sahjalal-universit-activation-news">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>Sahjalal University Activation News on Prothom alo</h5>
                  <span className="date-sp">26 September, 2013</span>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink >
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>Campus activation at RUET</h5>
                  <span className="date-sp">17 September, 2013</span>
                </div>
              </div>
            </NavLink>
          </Col>



          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink exact to="/campus-activation-ru">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>Campus activation at Rajshahi University</h5>
                  <span className="date-sp">16 September, 2013</span>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink >
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>GP Launched Mobile Apps for Subscribers</h5>
                  <span className="date-sp">10 September, 2013</span>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-left">
            <NavLink >
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>DU Apps Activation</h5>
                  <span className="date-sp">01 September, 2013</span>
                </div>
              </div>
            </NavLink>
          </Col>
          <Col md={12} lg={6} className="text-center" data-aos="fade-right">
            <NavLink exact to="/eatl-apps-contest-prothomalo">
              <div className="newsrom-block border-left-news">
            
                <div className="news-right w-100">
                  <h5>EATL apps contest advertisement Prothom-Alo 30-AUG-2013 & 1-SEP-2013
</h5>
                  <span className="date-sp">30 August, 2013</span>
                </div>
              </div>
            </NavLink>
          </Col>

        </Row>
      </Container>
    </Common>
  );
};
export default Newsroom;
