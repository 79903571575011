import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import wordb from '../../../assets/images/wordb.jpg';

const CompetitionsManagement = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>ICT-based Students Competitions
                                Management Consultant -- Creating an enabling understanding among the students to discover green growth leaders for the next generations in Bangladesh
                            </h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Projects</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={8}>
                        <div className='about-company mt-70 left-0' data-aos="fade-right">
                            <h5>ICT-based Students Competitions
                                Management Consultant -- Creating an enabling understanding among the students to discover green growth leaders for the next generations in Bangladesh
                            </h5>
                            <p>
                                In 2023, EATL began a project funded by World Bank. Project title is “ICT-based Students Competitions Management Consultant == Creating an enabling understanding among the students to discover green growth leaders for the next generations in Bangladesh”. Value of this project is $600 Thousand USD. The World Bank will support green education and enhance understanding of the green economy among the youths of Bangladesh through this project.
                            </p>
                            <h5 className='mt-4'>Name of Client:</h5>
                            <p>
                                World Bank
                            </p>

                        </div>

                    </Col>
                    <Col md={4} data-aos="fade-left">

                        <div className='about-company mt-70'>
                            <img className='nu-all mt-5' src={wordb} alt="wordb" />

                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default CompetitionsManagement;