import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import lmsnew from '../../../assets/images/lmsnew.png';

const LMS = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>
                                Design, Development, Implementation, and Maintenance of a Learning Management System (LMS) for National University, Bangladesh
                            </h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Projects</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={8}>
                        <div className='about-company mt-70 left-0' data-aos="fade-right">
                            <h5>
                                Design, Development, Implementation, and Maintenance of a Learning Management System (LMS) for National University, Bangladesh
                            </h5>
                            <p>
                                In 2023, EATL also began the project "Design, Development, Implementation and Maintenance of Learning Management System (LMS) for National University, Bangladesh", the development is currently ongoing. The total value of this project is $265 Thousand USD. The client of this project is the College Education Development Project (CEDP), funded by the World Bank and managed by the Ministry of Education.                             </p>
                            <h5 className='mt-4'>Name of Client:</h5>
                            <p>
                                College Education Development Project (CEDP)
                            </p>

                        </div>

                    </Col>
                    <Col md={4} data-aos="fade-left">

                        <div className='about-company mt-70'>
                            <img className='nu-all mt-5' src={lmsnew} alt="lmsnew" />

                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default LMS;