import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Button from 'react-bootstrap/Button';
import { FaArrowRight, FaPlay } from 'react-icons/fa';


import '../../common/slider/Slider.css';
// import apps from '../../assets/images/apps.png';
import edu from '../../assets/images/edu.png';
import his from '../../assets/images/his.png';
import phar from '../../assets/images/phar.png';
import info from '../../assets/images/info.png';
import eatlteam from '../../assets/images/eatlteam.png';
import cmi3 from '../../assets/images/cmi3.png';
import tech from '../../assets/images/tech.png';
import ennhub from '../../assets/images/ennhub.png';
import Modal from 'react-bootstrap/Modal';

import { NavLink } from 'react-router-dom';

const Nofound = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
    return (
      <>
      <Modal className='modalall' size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
         
        </Modal.Header>
        <Modal.Body>
        <video
        src="https://eatlbd.com/innovation.mp4"
        autoPlay
        loop
        muted
        controls 
      />
        </Modal.Body>
      
      </Modal>
       <div className="slider-banner slide-arrow">
      
          <Carousel>

            
          <Carousel.Item>
                <Row className='column-reverse'>
                <Col md={7}>
                <h1 className="text-white">EATL Innovation <strong> <span> Hub </span></strong>
              <br/>
              <label>An Innovative Initiative of EATL</label>
              </h1>
             <div className='btn-all-banner pos-re'>
             <NavLink exact to="/eatl-innovation-hub">
              <Button variant="primary btn-custom mt-3 btn-big rounded-pill">Read More
              <FaArrowRight/>
              </Button>
              
              </NavLink>
              <button className='btn-video' onClick={handleShow}>
                <FaPlay/>
              </button>
             </div>
                </Col>
                <Col md={5} className='text-end'>
                <img className='banner-img' src={ennhub} alt="img" />
                </Col>
                </Row>

  
            </Carousel.Item>



            
 <Carousel.Item>
                <Row className='column-reverse'>
                <Col md={7}>
                <h1 className="text-white">We Innovate Technology  <strong> <span> and </span></strong>
              <br/>
              <label>formulate the best IT solutions for your company’s Digital Transformation.
</label>
              </h1>
            
                </Col>
                <Col md={5} className='text-end'>
                <img className='banner-img' src={eatlteam} alt="img" />
                </Col>
                </Row>

  
            </Carousel.Item>

            <Carousel.Item>
                <Row className='column-reverse'>
                <Col md={7}>
                <h1 className="text-white">We are a CMMI Level  3<strong> <span>Certified Company. </span></strong>
              <br/>
              <label>We have taken all the precautionary measures to build great products or deliver great services in a comprehensive model.
</label>
              </h1>
            
                </Col>
                <Col md={5} className='text-end'>
                <img className='banner-img' src={cmi3} alt="img" />
                </Col>
                </Row>

  
            </Carousel.Item>

            <Carousel.Item>
                <Row className='column-reverse'>
                <Col md={7}>
                <h1 className="text-white">We manage the next generation technology - <strong> <span>Right Technology  </span></strong>
              <br/>
              <label>one step at a time.
</label>
              </h1>
            
                </Col>
                <Col md={5} className='text-end'>
                <img className='banner-img' src={tech} alt="img" />
                </Col>
                </Row>

  
            </Carousel.Item>
           
            <Carousel.Item>
                <Row className='column-reverse'>
                <Col md={7}>
                <h1 className="text-white">Educational Content<strong> <span> Sharing Portal</span></strong>
              <br/>
              <label>An initiative by EATL on e-education</label>
              </h1>
              <NavLink exact to="/edutube">
              <Button variant="primary btn-custom mt-3 btn-big rounded-pill">Read More
              <FaArrowRight/>
              </Button>
              </NavLink>
                </Col>
                <Col md={5} className='text-end'>
                <img className='banner-img' src={edu} alt="img" />
                </Col>
                </Row>

  
            </Carousel.Item>

            <Carousel.Item>
                <Row className='column-reverse'>
                <Col md={7}>
                <h1 className="text-white">Hospital Information<strong> <span> Management System</span></strong>
              <br/>
              <label>An ERP Solution for Medical Colleges and Hospitals </label>
              </h1>
             
              <Button variant="primary btn-custom mt-3 btn-big rounded-pill">Read More
              <FaArrowRight/>
              </Button>
                </Col>
                <Col md={5} className='text-end'>
                <img className='banner-img' src={his} alt="his" />
                </Col>
                </Row>

  
            </Carousel.Item>

            <Carousel.Item>
                <Row className='column-reverse'>
                <Col md={7}>
                <h1 className="text-white">ERP<strong> <span> Solution</span></strong>
              <br/>
              <label> Pharmaceutical ERP Solution for Manufacturers </label>
              </h1>
             
              <Button variant="primary btn-custom mt-3 btn-big rounded-pill">Read More
              <FaArrowRight/>
              </Button>
                </Col>
                <Col md={5} className='text-end'>
                <img className='banner-img' src={phar} alt="phar" />
                </Col>
                </Row>

  
            </Carousel.Item>


            
            <Carousel.Item>
                <Row className='column-reverse'>
                <Col md={7}>
              
                <h1 className="text-white">Looking back<strong> <span> 2016</span></strong>
              <br/>
              <label> EATL is a proud partner of developing <strong>500 mobile apps</strong> for different ministry services</label>
              </h1>
             
              <Button variant="primary btn-custom mt-3 btn-big rounded-pill">Read More
              <FaArrowRight/>
              </Button>
                </Col>
                <Col md={5} className='text-end'>
                <img className='banner-img' src={info} alt="info" />
                </Col>
                </Row>

  
            </Carousel.Item> 

        

           

           
            {/* <Carousel.Item>
                <Row className='column-reverse'>
                <Col md={7}>
                <h1 className="text-white"><strong>The first Bangladeshi <span>mobile apps store</span></strong>
              <br/>
              <label>An initiative by EATL</label>
              </h1>
              <NavLink exact to="/mobile-app">
              <Button variant="primary btn-custom mt-3 btn-big rounded-pill">Read More
              <FaArrowRight/>
              </Button>
              </NavLink>
            
                </Col>
                <Col md={5} className='text-end'>
                <img className='banner-img' src={apps} alt="img" />
                </Col>
                </Row>

  
            </Carousel.Item> */}
          </Carousel>
         
        </div>
      </>     
    );
};

export default Nofound;