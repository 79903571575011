import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import client0 from '../../assets/images/client0.png';
import client1 from '../../assets/images/client1.png';
import client2 from '../../assets/images/client2.png';
import client4 from '../../assets/images/client4.png';
import client5 from '../../assets/images/client5.jpg';
import client6 from '../../assets/images/client6.png';
import client7 from '../../assets/images/client7.png';
import client8 from '../../assets/images/client8.png';
import client9 from '../../assets/images/client9.png'
import clee from '../../assets/images/clee.png';
import cld from '../../assets/images/cld.jpg';
import clc from '../../assets/images/clc.jpg';
import cle from '../../assets/images/cle.png';
import cla from '../../assets/images/cla.png';
import clb from '../../assets/images/clb.jpg';
import clf from '../../assets/images/clf.jpg';
import clff from '../../assets/images/clff.png';
import clg from '../../assets/images/clg.jpg';
import clh from '../../assets/images/clh.png';


import cpa from '../../assets/images/cpa.jpg';
import cpb from '../../assets/images/cpb.jpg';
import cpc from '../../assets/images/cpc.jpg';
import cpd from '../../assets/images/cpd.jpg';
import cpe from '../../assets/images/cpe.jpg';
import cpf from '../../assets/images/cpf.jpg';
import cpg from '../../assets/images/cpg.jpg';
import cph from '../../assets/images/cph.jpg';
import cpi from '../../assets/images/cpi.jpg';
import cpj from '../../assets/images/cpj.jpg';
import cpk from '../../assets/images/cpk.jpg';
import cpl from '../../assets/images/cpl.jpg';
import cpm from '../../assets/images/cpm.jpg';
import cpn from '../../assets/images/cpn.jpg';
import cpo from '../../assets/images/cpo.jpg';
import cpp from '../../assets/images/cpp.jpg';
import cpq from '../../assets/images/cpq.jpg';
import cpr from '../../assets/images/cpr.jpg';
import cps from '../../assets/images/cps.jpg';
import cpt from '../../assets/images/cpt.jpg';
import cpu from '../../assets/images/cpu.jpg';
import cpv from '../../assets/images/cpv.jpg';
import cpw from '../../assets/images/cpw.jpg';
import cpx from '../../assets/images/cpx.jpg';
import cpy from '../../assets/images/cpy.jpg';
import cpz from '../../assets/images/cpz.jpg';

const PartnersClients = () => {
return (
<Common>
   <section className='sub-page-banner  part-client-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Partners & Clients</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>
               Partners & Clients
               </Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container>
      <Row>

      <Row className='text-center ms-0'>
         <Col md={12}>
         <section className='work-process'>
           
            <h2 data-aos="flip-up">Our Partners & Clients</h2>
         </section>
         </Col>
      </Row>
         <Col md={12} className='par-client mb-5 pb-5 pe-0'>
        
         <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client0} alt="client0" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client1} alt="client1" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client2} alt="client2" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client4} alt="client3" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client5} alt="client5" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client6} alt="client6" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client7} alt="client7" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client8} alt="client8" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={client9} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clee} alt="client0" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cld} alt="client1" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clc} alt="client2" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cle} alt="client3" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cla} alt="client5" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clb} alt="client6" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clf} alt="client7" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clff} alt="client8" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clg} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clh} alt="client9" /> </div>
               </div>
            </div>


            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpa} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpb} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpc} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpd} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpe} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpf} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpg} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cph} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpi} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpj} alt="client9" /> </div>
               </div>
            </div>



            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpk} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpl} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpm} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpn} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpo} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpp} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpq} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpr} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cps} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpt} alt="client9" /> </div>
               </div>
            </div>


            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpu} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpv} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpw} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpx} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpy} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpz} alt="client9" /> </div>
               </div>
            </div>
         </Col>
{/* 
         <Row className='text-center'>
         <Col md={12}>
         <section className='work-process'>
           
            <h2 data-aos="flip-up">Our Clients</h2>
         </section>
         </Col>
      </Row> */}
         {/* <Col md={12}  className='par-client mb-5'>
        
         <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clee} alt="client0" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cld} alt="client1" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clc} alt="client2" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cle} alt="client3" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-right">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cla} alt="client5" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clb} alt="client6" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clf} alt="client7" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clff} alt="client8" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clg} alt="client9" /> </div>
               </div>
            </div>
            <div class='item' data-aos="fade-left">
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={clh} alt="client9" /> </div>
               </div>
            </div>
         </Col> */}
      </Row>


      

   </Container>
</Common>
);
};
export default PartnersClients;