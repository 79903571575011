import React from 'react';
import Footer from '../common/footer/Footer';
// import Navtop from '../common/nav/Navtop';
import Header from '../common/header/Header';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();
const Common = (props) => {
    return (
        <div>
            <Header/>
         {/* <Navtop/> */}
             {props.children}
             <Footer/>
        </div>
    );
};

export default Common;