import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Common from "../../../layouts/Common";
import ResponsiveGallery from "react-responsive-gallery";
import ma from "../../../assets/images/ma.jpg";
import ma2 from "../../../assets/images/ma2.jpg";
import ma3 from "../../../assets/images/ma3.jpg";
import ma4 from "../../../assets/images/ma4.jpg";
import ma5 from "../../../assets/images/ma5.jpg";
import ma6 from "../../../assets/images/ma6.jpg";
import ma7 from "../../../assets/images/ma7.jpg";

import ma8 from "../../../assets/images/ma8.jpg";
import ma9 from "../../../assets/images/ma9.jpg";
import ma10 from "../../../assets/images/ma10.jpg";
import ma11 from "../../../assets/images/ma11.jpg";



const ProthomAloResult = () => {
  return (
    <Common>
      <section className="sub-page-banner newsr-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <h2>Media section</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Media section</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="text-start mt-3">
          <Col md={12}>
            <section className="work-process mt-5">
              <h4 data-aos="flip-up">
              ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।
              </h4>
              <label className="text-gray mb-2 w-100">
              Dated : 30 March, 2014
              </label>
            </section>
          </Col>
        </Row>
        <Col md={12} className="mb-5 border-topn pt-3 media-galery">
          <ResponsiveGallery
            useLightBox
            images={[
              {
                src: `${ma}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 1,
                title:
                "ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।’",
              },
              {
                src: `${ma2}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 2,
                title:
                  "ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।’",
              },
              {
                src: `${ma3}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 3,
                title:
                "ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।’",
              },
              {
                src: `${ma4}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 4,
                title:
                "ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।’",
              },

              {
                src: `${ma5}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 5,
                title:
                "ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।’",
              },
              {
                src: `${ma6}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                "ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।’",
              },

              {
                src: `${ma7}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।’",
              },

              {
                src: `${ma8}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।’",
              },
              {
                src: `${ma9}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।’",
              },
              {
                src: `${ma10}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।’",
              },
              {
                src: `${ma11}`,
                alt: "gallery",
                orderS: 6,
                orderM: 6,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "ঘোঘিত হল ইএটিএল-প্রথম আলো অ্যাপস প্রতিযোগিতার ফল।’",
              },
              
             
              
            ]}
          />
        </Col>
      </Container>
    </Common>
  );
};

export default ProthomAloResult;
