import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../layouts/Common";
import Button from 'react-bootstrap/Button';
import Slider from "../../common/slider/Slider"
import com from '../../assets/images/eatlall.png';
import daweb from '../../assets/images/daweb.svg';
import ittr from '../../assets/images/ittr.svg';
import app from '../../assets/images/app.svg';
import customer from '../../assets/images/customer.svg';
import projectsa from '../../assets/images/projecta.svg';
import edum from '../../assets/images/edum.jpg';
import hiss2 from '../../assets/images/hiss2.png';
import round from '../../assets/images/round.png';
import about1 from '../../assets/images/about1.png';
import learningbig from '../../assets/images/learningsmall.jpg';
import pr2 from '../../assets/images/pr2.png';
import pr3 from '../../assets/images/pr3.jpg';
import pr4 from '../../assets/images/pr4.png';
import mdpic from '../../assets/images/mdpic.png';
import edc1 from '../../assets/images/edc1.png';
import client0 from '../../assets/images/client0.png';
import client1 from '../../assets/images/client1.png';
import client2 from '../../assets/images/client2.png';
import client4 from '../../assets/images/client4.png';
import client5 from '../../assets/images/client5.jpg';
import client6 from '../../assets/images/client6.png';
import client7 from '../../assets/images/client7.png';
import client8 from '../../assets/images/client8.png';
import client9 from '../../assets/images/client9.png';
import clee from '../../assets/images/clee.png';
import cld from '../../assets/images/cld.jpg';
import clc from '../../assets/images/clc.jpg';
import cle from '../../assets/images/cle.png';
import cla from '../../assets/images/cla.png';
import clb from '../../assets/images/clb.jpg';
import clf from '../../assets/images/clf.jpg';
import clff from '../../assets/images/clff.png';
import clg from '../../assets/images/clg.jpg';
import clh from '../../assets/images/clh.png';
import workp from '../../assets/images/work-p-chart.svg';
import nu from '../../assets/images/nu.png';
import elibrary from '../../assets/images/e-library.png';
import cpa from '../../assets/images/cpa.jpg';
import cpb from '../../assets/images/cpb.jpg';
import cpc from '../../assets/images/cpc.jpg';
import cpd from '../../assets/images/cpd.jpg';
import cpe from '../../assets/images/cpe.jpg';
import cpf from '../../assets/images/cpf.jpg';
import cpg from '../../assets/images/cpg.jpg';
import cph from '../../assets/images/cph.jpg';
import cpi from '../../assets/images/cpi.jpg';
import cpj from '../../assets/images/cpj.jpg';
import cpk from '../../assets/images/cpk.jpg';
import cpl from '../../assets/images/cpl.jpg';
import cpm from '../../assets/images/cpm.jpg';
import cpn from '../../assets/images/cpn.jpg';
import cpo from '../../assets/images/cpo.jpg';
import cpp from '../../assets/images/cpp.jpg';
import cpq from '../../assets/images/cpq.jpg';
import cpr from '../../assets/images/cpr.jpg';
import cps from '../../assets/images/cps.jpg';
import cpt from '../../assets/images/cpt.jpg';
import cpu from '../../assets/images/cpu.jpg';
import cpv from '../../assets/images/cpv.jpg';
import cpw from '../../assets/images/cpw.jpg';
import cpx from '../../assets/images/cpx.jpg';
import cpy from '../../assets/images/cpy.jpg';
import cpz from '../../assets/images/cpz.jpg';
import lmsnew from '../../assets/images/lmsnew.png';
import cme from '../../assets/images/cme.png';
import por from '../../assets/images/por.jpg';
// import nonlinc from '../../assets/images/onlinc.jpg';
import ieims from '../../assets/images/ieims.png';
import onlineclass from '../../assets/images/onlineclass.jpg';



// import {FaCalendarAlt}from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import { FaArrowRight } from 'react-icons/fa';
import Counter from "../../common/Counter";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Home = () => {
   const options = {
      margin: 50,
      responsiveClass: true,
      nav: true,
      autoplay: true,
      // navText: ["Prev", "Next"],
      smartSpeed: 1000,
      responsive: {
         0: {
            items: 2,
         },
         400: {
            items: 2,
         },
         600: {
            items: 3,
         },
         700: {
            items: 2,
         },
         1000: {
            items: 6,
         }
      },
   };
   // const optionsp = {
   //    margin: 50,
   //    responsiveClass: true,
   //    nav: true,
   //    autoplay: true,
      
   //    smartSpeed: 1000,
   //    responsive: {
   //       0: {
   //          items: 1,
   //       },
   //       400: {
   //          items: 1,
   //       },
   //       600: {
   //          items: 2,
   //       },
   //       700: {
   //          items: 2,
   //       },
   //       1000: {
   //          items: 6,
   //       }
   //    },
   // };

   const [activeTab, setActiveTab] = useState('edutubeElearning');

   // An "enum"
   const tabNamesEnum = {
      edutubeElearning: 'edutubeElearning',
      lms: 'lms',
      onlinetra:'onlinetra',
      campaign: 'campaign',
      onlinelms: 'onlinelms',
      nue: 'nue',
      banbase: 'banbase',
      miss: 'miss',
      elibary: 'elibary',
      webmis:'webmis',
      procurement:'procurement',
      development:'development',
   };
   return (
      <Common>

         {/* banner-section */}
         <section className="banner-section">
            <div class="line-inner style2">
               <div class="line"></div>
               <div class="line"></div>
               <div class="line"></div>
            </div>
            <Container>
               <Row className='m-0'>
                  <Col md={12}>
                     <Slider />
                  </Col>
               </Row>
            </Container>
         </section>
         {/* end banner-section */}
        
         {/* company-status */}
         <section className="company-status">
            <Container>
               <Row className='text-center'>
                  <Col md={3}>
                     <div className='status-box'>
                        <Counter number={150} title="Partners" />
                     </div>
                  </Col>
                  <Col md={3}>
                     <div className='status-box'>
                        <Counter number={100} title="IT Professionals" />
                     </div>
                  </Col>
                  <Col md={3}>
                     <div className='status-box'>
                        <Counter number={22} title="Years of Experience" />
                     </div>
                  </Col>
                  <Col md={3}>
                     <div className='status-box'>
                        <Counter number={250} title="Projects Completed" />
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>
         {/* end company-status */}

         {/* Our Works Process */}
         <Container>
            <Row className='text-center ms-0'>
               <Col md={12}>
                  <section className='work-process'>
                     <h4 data-aos="flip-up">How We Work</h4>
                     <h2 data-aos="flip-up">
                     Our Work Process
                        </h2>
                  </section>
               </Col>
            </Row>
            {/* <Row className="feature-area-inner"> */}
            <img src={workp} className='work-p' alt="img" />
          
               {/* <div className="feature-s4">
                  <div className="badge" data-aos="fade-up"
                     data-aos-duration="3000">01</div>
                  <div className="content">
                     <h3>Create a Plan</h3>
                     <p>There are many variation of passages of Lorem Ips available, but them</p>
                  </div>
               </div>
               <div className="feature-s4">
                  <div className="badge" data-aos="fade-up"
                     data-aos-duration="3000">02</div>
                  <div className="content">
                     <h3>Start Working</h3>
                     <p>There are many variation of passages of Lorem Ips available, but them</p>
                  </div>
               </div>
               <div className="feature-s4 third">
                  <div className="badge" data-aos="fade-up"
                     data-aos-duration="3000">03</div>
                  <div className="content">
                     <h3>Publish Busines</h3>
                     <p>There are many variation of passages of Lorem Ips available, but them</p>
                  </div>
               </div> */}
            {/* </Row> */}
         </Container>
         {/*end  Our Works Process */}

         {/* eatl services */}



<section className='major-project' >


<Container>
<Col md={12} className='text-center'>
                  <section className='work-process'>
                     <h4 data-aos="flip-up">Our Major Projects</h4>
                     <h2 data-aos="flip-up">Our Major Projects in Education </h2>
                  </section>
               </Col>

               <Row className="mejor-row">
  <Col md={6}>
  
  <div className='left-project-pan'
        role="tabpanel"
        id="Edutubeel-panel"
        aria-labelledby="Edutubeel-tab"
        hidden={activeTab !== tabNamesEnum.edutubeElearning}
      >
    <div className='img-majore-project'> <img src={edum} alt="edum" />
      <h4 className='mt-3'>Edutube</h4>
      <p> 
      Ethics Advanced Technology Limited (EATL) has launched Bangladesh First educational content sharing portal
       www.edutubebd.com on 11 am of 29 march, 2016, Tuesday at the Pan pacific hotel Sonargaon of the capital Dhaka,
        Bangladesh. In this occasion honorable Education Minister of Government of Republic Bangladesh,
         Mr. Nurul Islam Nahid was present as the chief guest. Professor Dr. Jamilu reza Chwodhury, VC University of Asia Pacific was present as special guest. 
         Mr. Jamiulr Reza Chowdhury has always provided his positive response, valuable advice, support and co-operation to every initiative of EATL.
          Prime Minister’s Office Director Nilufar Ahmed was present as Guest of Honor. MP Zunaid AHmed Palak, ICT Division was present as special guest. 
          Managing Director, and Chairman EATL, Mr. M A Mubin Khan was also present in the panel edutubebd is one of the key initiative of Ethics Advanced
           Technology Limited (EATL) for the development of country’s education sector to change them in a different level. It is the only education portal
            focused on local context and syllabus. In this...
   
      
      <div className='clearfix'></div>
      <NavLink className="link-all" exact to="/edutube">
         Read more...
      </NavLink>
      </p>
    </div>
  </div>
  <div className='left-project-pan'
        role="tabpanel"
        id="lms-panel"
        aria-labelledby="lms-tab"
        hidden={activeTab !== tabNamesEnum.lms}
      >
    <div className='img-majore-project'> <img src={lmsnew} alt="lmsnew" />
      <h4 className='mt-3'>Learning Management System (LMS)</h4>
      <p> Under the World Bank funded College Education Development Project (CEDP), the proposed cloud-based Learning Management System and e-Learning platform is being developed for the complete management of National University education system for honor's/master's College education and Teachers training management. The LMS will be a web-based application, provides facility for educational institutions to perform activities like, administration, documentation, tracking, reporting, automation and delivery of courses to leverage the learning process, manage the communication between students and teachers and report generation and analysis. </p>
    </div>
  </div>
  <div className='left-project-pan'
        role="tabpanel"
        id="onlinetra-panel"
        aria-labelledby="onlinetra-tab"
        hidden={activeTab !== tabNamesEnum.onlinetra }
      >
    <div className='img-majore-project'> 
      <img src={cme} alt="lmsnew" />
      
      <h4 className='mt-3'>Online Training Consulting Firm for Subject Based Training</h4>
      <p> The major objective of Online Training for Subject Based Training is to organize on line Subject Based training for the teachers of
          the government and non-government Honours teaching colleges under National University. The training platform will also ensure recording
           the sessions and use it for future purposes. It will also measure the trainees/trainer's performance. Following objectives are required 
           to be achieved through this platform 
           <ol className="p-0 m-0 mt-4">
              <li>Identify an appropriate and convenient (user-friendly) eLearning platform which can be used to organize training for the teachers of the government and non-government Honours teaching colleges under National University</li>
         <li>
         	Conduct effectively and efficiently online Subject Based Training without any interruption so that purpose of the training is achieved
         </li>
         
           </ol>
           </p>
      <div className='clearfix'></div>
      <NavLink className="link-all" exact to="/online-training-consulting">
         Read more...
      </NavLink>
    </div>
  </div>


  <div className='left-project-pan'
        role="tabpanel"
        id="campaign-panel"
        aria-labelledby="campaign-tab"
        hidden={activeTab !== tabNamesEnum.campaign }
      >
    <div className='img-majore-project'> 
      <img src={nu} alt="nu" />
      
      <h4 className='mt-3'>Development of E-learning Campaign</h4>
      <p> 
      The task was to aware the students, teachers, parents and broader public about the e-learning material development and plan, so that they visit the web-portal containing the developed e-learning contents under the SESIP project and use the content. A campaign throughout the country among the students, teachers and other stakeholders will ensure dissemination of the e-learning materials among them. Considering the audience, the campaign strategy must include all sorts of available channel such as event hosting, traditional marketing, social media ads and targeted marketing.  Naturally the process will start with design and development of content, digitized campaign management platform and sustainability plan.
      </p>
      
    </div>
  </div>

  <div className='left-project-pan'
        role="tabpanel"
        id="onlinelms-panel"
        aria-labelledby="onlinelms-tab"
        hidden={activeTab !== tabNamesEnum.onlinelms }
      >
    <div className='img-majore-project'> 
      <img src={onlineclass} alt="onlineclass" />
      
      <h4 className='mt-3'>Learning Management System (LMS) with online Class</h4>
      <p> 
      Edutube E-Learning Content Portal and Learning Management System (LMS) 
EATL launched country’s first e-learning web portal edutubebd.com in April 2015 where students of different academic levels and candidates of competitive examinations find supplementary educational contents. Laster on, we have integrated learning management system (LMS) with it. Edutube LMS enables taking online classes and recording attendance during the online classes. 
The following are the major features of Edutube platform:

      </p>
      <div className='clearfix'></div>
      <NavLink className="link-all" exact to="/learning-management-system">
         Read more...
      </NavLink>
    </div>
  </div>
  
  <div className='left-project-pan'
        role="tabpanel"
        id="nue-panel"
        aria-labelledby="nue-tab"
        hidden={activeTab !== tabNamesEnum.nue }
      >
    <div className='img-majore-project'> 
      <img src={nu} alt="onlinc" />
      
      <h4 className='mt-3'>Development & Implementation of Examination Management System for National University</h4>
      <p> 
      The goal of the proposed Examination Management System (EMS) is to automate the entire process of the Examination Division to provide better services in a paperless environment to serve the students, staff and stakeholders of the University. It will reduce the result processing time to a minimum level and ensure transparency and accountability in the process through introduction of state-of-the-art data logging at all event points. The main parts of the EMS is to
      <ol className="p-0 m-0 mt-4">
              <li>Integrate the hall attendance & verification of examinee in a near real-time system</li>
         <li>
         Make the process real time at site and to automate the script distribution to reduce current logistics and resources pressure
         </li>
         
           </ol>
      </p>
      <div className='clearfix'></div>
      <NavLink className="link-all" exact to="/national-university">
         Read more...
      </NavLink>
    </div>
  </div>



  <div className='left-project-pan'
        role="tabpanel"
        id="banbase-panel"
        aria-labelledby="banbase-tab"
        hidden={activeTab !== tabNamesEnum.banbase }
      >
    <div className='img-majore-project'> 
      <img src={ieims} alt="ieims" />
      
      <h4 className='mt-3'>Software design and development for Educational Information Management to be used by MOE, BANBEIS, DSHE, DTE, DME, NTRCA and others with maintenance support for 3 (three) years</h4>
      <p> 
      BANBEIS, being a government organization in the education sector, has a mandate of establishing Integrated EIMS (IEIMS) through integration and storing of data of all stakeholders in the education sector. The project outlined for establishing an online automated and integrated education management system for Ministry of Education (Secondary and Higher Education Division and 

      </p>
      <div className='clearfix'></div>
      <NavLink className="link-all" exact to="/ieims">
         Read more...
      </NavLink>
    </div>
  </div>

  <div className='left-project-pan'
        role="tabpanel"
        id="miss-panel"
        aria-labelledby="miss-tab"
        hidden={activeTab !== tabNamesEnum.miss }
      >
    <div className='img-majore-project'> 
      <img src={edc1} alt="edc1" />
      
      <h4 className='mt-3'>Web based Application and Data Management Software for SEQAEP</h4>
      <p> 
      Ever-largest web-based MIS systems in Bangladesh, covering 64 districts and 134 Upazilas with over 7000 schools for monitoring and evaluation of results and investments. It is large & complex project with multiple stakeholders (MOE, LGED, BANBISE, PIACT & Schools) involved and coordinated by Secondary Education, funded by World Bank. Technical task force reviewed and utilizes systems and data for decision making. System developed, integrated with central server and migration of data from stakeholders completed. Capacity assessment, Training plan designed and conducted training for user friendly support and operation of the web based MIS effectively. 
      </p>
      <div className='clearfix'></div>

   
      <a className="link-all" href="www.seqaep.gov.bd">
      http://www.seqaep.gov.bd
      </a>
    </div>
  </div>

  <div className='left-project-pan'
        role="tabpanel"
        id="elibary-panel"
        aria-labelledby="elibary-tab"
        hidden={activeTab !== tabNamesEnum.elibary }
      >
    <div className='img-majore-project'> 
      <img src={elibrary} alt="elibrary" />
      
      <h4 className='mt-3'>Establish e-Library Management and Set up web-based Medical Student Facilities Management</h4>
      <p> 
      EATL started the "Establish e-Library Management and Set Up Web-Based Medical Student Facilities Management" project for the Directorate General of Medical Education (DGME) in March 2023, and it was successfully completed in June 2021. The total value of this project is $385 Thousand USD. The aim of this project was to develop an (i) e-library Management System that can handle & and manage the activities involved in a library in an efficient & and reliable way for all Public Medical Colleges and Dental Colleges of Bangladesh.
<ol className="p-0 m-0 mt-4">
   <h6>The aim of this project is to develop a:</h6>
   <li>
   e-Library Management System (LMS) that can handle & manage the activities involved in a library in an efficient & reliable way and also develop a web-based 
   </li>




</ol>
      </p>
      <div className='clearfix'></div>
      <NavLink className="link-all" exact to="/e-library">
         Read more...
      </NavLink>
    </div>
  </div>


  <div className='left-project-pan'
        role="tabpanel"
        id="webmis-panel"
        aria-labelledby="webmis-tab"
        hidden={activeTab !== tabNamesEnum.webmis }
      >
    <div className='img-majore-project'> 
      <img src={hiss2} alt="hiss2" />
      
      <h4 className='mt-3'>Hospital Information Management Solutions for Private Medical Colleges & Hospitals</h4>
      <p> 
      “WebHIMS” – Web based Hospital Information Management System is an ERP suite of software modules to computerize any large scale medical college, hospital and its branches. It is fully a web based software. The information is available anytime, anywhere. There are plenty of exhaustive modules available in WebHIMS V 1.0, which make it user friendly, highly versatile, quick installable and completely database driven.
<ol className="p-0 m-0 mt-4">
   <h6>Key Facilities:</h6>
   <li>
   WebHIMS is a comprehensive solution designed to automate all activities of a medical college or a hospital.
   </li>

   <li>
   Designed with intuitive user interface, the system is capable of administering the total enterprise and manages information exchange efficiently.
   </li>

 


</ol>
      </p>
      <div className='clearfix'></div>
      <NavLink className="link-all" exact to="/webHIMS">
         Read more...
      </NavLink>
    </div>
  </div>

  <div className='left-project-pan'
        role="tabpanel"
        id="procurement-panel"
        aria-labelledby="procurement-tab"
        hidden={activeTab !== tabNamesEnum.procurement }
      >
    <div className='img-majore-project'> 
      <img src={por} alt="por" />
      
      <h4 className='mt-3'>Procurement of Development and supply of e-Manuals in Physics, Chemistry and Bangladesh & Global Studies</h4>
      <p> 
   
<ol className="p-0 m-0 mt-4">
   <h6>Key Facilities:</h6>
   <li>
   Development of e-learning contents such as simulation, videos, animations, images, diagrams and interactive test for some selected chapters of 3 subject of class 9-10. 
   </li>

   <li>
   Development and deployment of a web based e-learning platform to host the e-learning contents.
   </li>

 <li>
 This platform will be accessible from any platform including windows, android and IOS. 
 </li>
<li>
   This project also includes training of the project staff 
EATL in house technical team developing the e-learning content.

</li>

</ol>
      </p>
     
    </div>
  </div>


  <div className='left-project-pan'
        role="tabpanel"
        id="development-panel"
        aria-labelledby="development-tab"
        hidden={activeTab !== tabNamesEnum.development}
      >
    <div className='img-majore-project'> 
      <img src={edc1} alt="edc1" />
      
      <h4 className='mt-3'>Development of Web Based Automation System for the Educational Institutions & Directorate of Inspection and Audit (DIA)</h4>
      <p> 
   
      The purpose of the development of Web Based Automation System which is the electronic processing of the DIA’s overall inspection and audit activities allows to publish, communicate, access, receive and submit all related information and documentation via Internet. Through this automated system DIA can monitor the overall activities of the 36000 institutions centrally and abiding of the government rules and regulations, proper utilization of government fund and improvement of the quality education.
      </p>
     
    </div>
  </div>

  
  
  </Col>
  <Col md={6}>
  <ul className='tab-list-project' role="tablist">
    <li
          tabindex="0"
          role="tab"
          id="Edutubeel-tab"
          aria-selected={activeTab === tabNamesEnum.edutubeElearning}
          aria-controls="Edutubeel-panel"
          onClick={() => setActiveTab(tabNamesEnum.edutubeElearning)}
      >
      <label> <span> <img src={projectsa} alt="img" /> </span> </label>
      <p> Edutube E-Learning </p>
    </li>
    <li
          role="tab"
          id="lms-tab"
          aria-selected={activeTab === tabNamesEnum.lms}
          aria-controls="lms-panel"
          onClick={() => setActiveTab(tabNamesEnum.lms)}
      >
      <label> <span> <img src={projectsa} alt="img" /> </span> </label>
      <p>Learning Management System (LMS)</p>
    </li>

    <li
          role="tab"
          id="onlinetra-tab"
          aria-selected={activeTab === tabNamesEnum.onlinetra}
          aria-controls="onlinetra-panel"
          onClick={() => setActiveTab(tabNamesEnum.onlinetra)}
      >
      <label> <span> <img src={projectsa} alt="img" /> </span> </label>
      <p className='pt-0'>Online Training Consulting Firm for Subject Based Training</p>
    </li>

    <li
          role="tab"
          id="campaign-tab"
          aria-selected={activeTab === tabNamesEnum.campaign}
          aria-controls="campaign-panel"
          onClick={() => setActiveTab(tabNamesEnum.campaign)}
      >
      <label> <span> <img src={projectsa} alt="img" /> </span> </label>
      <p>Development of E-learning Campaign</p>
    </li>



    <li
          role="tab"
          id="onlinelms-tab"
          aria-selected={activeTab === tabNamesEnum.onlinelms}
          aria-controls="onlinelms-panel"
          onClick={() => setActiveTab(tabNamesEnum.onlinelms)}
      >
      <label> <span> <img src={projectsa} alt="img" /> </span> </label>
      <p className='pt-0'>Learning Management System (LMS) with online Class</p>
    </li>
    <li
          role="tab"
          id="nue-tab"
          aria-selected={activeTab === tabNamesEnum.nue}
          aria-controls="nue-panel"
          onClick={() => setActiveTab(tabNamesEnum.nue)}
      >
      <label> <span> <img src={projectsa} alt="img" /> </span> </label>
      <p className='pt-0'>Development & Implementation of EMS for NU </p>
    </li>

    <li
          role="tab"
          id="banbase-tab"
          aria-selected={activeTab === tabNamesEnum.banbase}
          aria-controls="banbase-panel"
          onClick={() => setActiveTab(tabNamesEnum.banbase)}
      >
      <label> <span> <img src={projectsa} alt="img" /> </span> </label>
      <p className='mt-2'>BANBEIS</p>
    </li>
    <li
          role="tab"
          id="miss-tab"
          aria-selected={activeTab === tabNamesEnum.miss}
          aria-controls="miss-panel"
          onClick={() => setActiveTab(tabNamesEnum.miss)}
      >
      <label> <span> <img src={projectsa} alt="img" /> </span> </label>
      <p className='mt-2'>Web Based MIS Systems </p>
    </li>

    <li
          role="tab"
          id="elibary-tab"
          aria-selected={activeTab === tabNamesEnum.elibary}
          aria-controls="elibary-panel"
          onClick={() => setActiveTab(tabNamesEnum.elibary)}
      >
      <label> <span> <img src={projectsa} alt="img" /> </span> </label>
      <p className='mt-0 pt-0'>Establish e-Library Management & Set up web-based MSFM
</p>
    </li>
    
    <li
          role="tab"
          id="webmis-tab"
          aria-selected={activeTab === tabNamesEnum.webmis}
          aria-controls="webmis-panel"
          onClick={() => setActiveTab(tabNamesEnum.webmis)}
      >
      <label> <span> <img src={projectsa} alt="img" /> </span> </label>
      <p>WebHIMS
</p>
    </li>

    <li
          role="tab"
          id="procurement-tab"
          aria-selected={activeTab === tabNamesEnum.procurement}
          aria-controls="procurement-panel"
          onClick={() => setActiveTab(tabNamesEnum.procurement)}
      >
      <label> <span> <img src={projectsa} alt="img" /> </span> </label>
      <p className="pt-2">Procurement of Development and supply 
</p>
    </li>

    <li
          role="tab"
          id="development-tab"
          aria-selected={activeTab === tabNamesEnum.development}
          aria-controls="development-panel"
          onClick={() => setActiveTab(tabNamesEnum.development)}
      >
      <label> <span><img src={projectsa} alt="img" /> </span> </label>
      <p className="pt-2">Development of Web Based Automation </p>
    </li>
    
  </ul>
  </Col>
</Row>   




    </Container>
</section>




         <section className='services mt-0 bg-white'>
            <Container>
               <Col md={12} className='text-center'>
                  <section className='work-process'>
                     <h4 data-aos="flip-up">More About Our Company</h4>
                     <h2 data-aos="flip-up">Services we provide </h2>
                  </section>
               </Col>
               <Row className='ms-0'>
                  <Col lg={6} >
                     <div className='left-block'>
                        <img src={com} alt="img" />
                        <p> EATL is one of the leading private enterprise and social change-maker organization of Bangladesh that
                           is solely focused on Information and Communication Technology. The key purpose of EATL is to
                           provide wide range of premium quality ICT services dedicated for the socio-economic development of
                           the country. Since inception, EATL’s dedication and diligence in remaining technically astute has been the key factor of providing success to a large number of clienteles within the government, development agencies, international donor agencies, international and local NGOs, MNCs, business organizations, universities/institutes, hospitals, pharmaceuticals and private sector organizations.  </p>
                     </div>
                  </Col>
                  <Col lg={6}>
                     <Row>
                     <Col md={6}>
                           <div className='service-box-five2'>
                              <div className='service-icon-five' data-aos="fade-left"> <img src={app} alt="img" /> </div>
                              <h4> Customized Web Application </h4>
                              <p> EATL builds custom web applications according to your business logic. </p>
                              <span>
                                 <NavLink exact to="/customized-web-application">Read more...</NavLink>
                              </span>
                           </div>
                        </Col>
                        <Col md={6} >
                           <div className='service-box-five2'>
                              <div className='service-icon-five' data-aos="fade-right"> <img src={daweb} alt="img" /> </div>
                              <h4> Dynamic Web Site </h4>
                              <p> EATL offers services in appropriate web design, website hosting and web marketing... </p>
                              <span>
                                 <NavLink exact to="/dynamic-web">Read more...</NavLink>
                              </span>
                           </div>
                        </Col>
                        <Col md={6}>
                           <div className='service-box-five2'>
                              <div className='service-icon-five' data-aos="fade-right"> <img src={ittr} alt="img" /> </div>
                              <h4> IT Training </h4>
                              <p>  Build your career with our Hands-On IT Training and maximize your IT investment... </p>
                              <span>
                                 <NavLink exact to="">Read more...</NavLink>
                              </span>
                           </div>
                        </Col>
                     
                        <Col md={6}>
                           <div className='service-box-five2'>
                              <div className='service-icon-five' data-aos="fade-left"> <img src={customer} alt="img" /> </div>
                              <h4>Customized Software </h4>
                              <p>EATL is a custom software development company that provides... </p>
                              <span>
                                 <NavLink exact to="/customized-software">Read more...</NavLink>
                              </span>
                           </div>
                        </Col>
                     </Row>
                     <Col md={12} className='text-center mt-red-re' data-aos="fade-up"
                        data-aos-duration="3000">
                        <NavLink exact to="/services">
                           <Button variant="primary btn-custom mt-0 btn-big rounded-pill">
                           View All Services 
                              <FaArrowRight />
                           </Button>
                        </NavLink>
                     </Col>
                  </Col>
               </Row>
            </Container>
         </section>
         {/*end  eatl services */}
         {/* managing director */}
         <Container>
            <Row className='ms-0'>
               <Col lg={4} className='text-center mt-5'>
                  <img class="md mt-5 md-pic" data-aos="fade-right" src={mdpic} alt="img" />
               </Col>
               <Col lg={7} className='text-start'>
                  <section className='work-process mb-details' data-aos="fade-left">
                     <h4 className='mt-3'>Message</h4>
                     <div className='md-mesaage'>
                        <h3 className='mb-1'><i>M. A. Mubin Khan</i></h3>
                        <h5><i>Managing Director</i></h5>
                        <p className='p-text-justi mb-2'> <i> 
                        The drive for sustainability begins with desired performance and results, adhering to commitments and technological innovation. EATL has been working on these aspects for more than two decades and focusing on customer satisfaction.<br/><br/>
                        I believe in the technically qualified resources that we manage. I believe in the products and software services that we offer to meet our client needs. I believe in our employees, whose energy and ingenuity continue to drive our company forward. I believe in quality as well as an approach to continuous improvement. I honor the commitments with our partners and community.

                              </i> </p>
                        <span>
                           <NavLink exact to="/md-details">Read more...</NavLink>
                        </span>
                     </div>
                  </section>
               </Col>
            </Row>
         </Container>
         {/* end managing director */}

         {/* eatl Products */}
         <section className='services productall mt-50'>
            <Container>
               <Col md={12} className='text-center'>
                  <section className='work-process'>
                     <h4 data-aos="flip-up">Products</h4>
                     <h2 data-aos="flip-up">Our Products</h2>
                  </section>
               </Col>
               <Row className='ms-0'>
                  {/* <Col xs={12} md={6} lg={3}>
                     <div className='left-block p-3 product' data-aos="fade-up"
                        data-aos-duration="3000">
                        <img src={pr1} alt="pr1" />
                        <h3>Mobile Apps</h3>
                        <p> EATLapps is the first ever Bangladeshi Mobile Appstore. The company is established in 2012 under the umbrella of EATL ICT company... </p>
                        <span>
                           <NavLink exact to="/mobile-app">Read more...</NavLink>
                        </span>
                     </div>
                  </Col> */}

<Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-up"
                     data-aos-duration="3000">
                     <img src={learningbig} alt="pr1" />
                     <h3>Online Learning Management System</h3>
                     <p> Our array of services... </p>
                     <span>
                        <NavLink exact to="">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
                  <Col xs={12} md={6} lg={3}>
                     <div className='left-block p-3 product' data-aos="fade-up"
                        data-aos-duration="3000">
                        <img src={pr2} alt="pr1" />
                        <h3>WebHIMS</h3>
                        <p> “WebHIMS” – Web based Hospital Information Management System is an ERP suite of software modules to computerize any large scale... </p>
                        <span>
                           <NavLink exact to="/webHIMS">Read more...</NavLink>
                        </span>
                     </div>
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                     <div className='left-block p-3 product' data-aos="fade-up"
                        data-aos-duration="3000">
                        <img src={pr3} alt="pr1" />
                        <h3>Edutube</h3>
                        <p>
                           Ethics Advanced Technology Limited (EATL) has launched Bangladesh First educational content sharing portal
                           <NavLink exact to="www.edutubebd.com"> www.edutubebd.com</NavLink>  on 11...

                        </p>
                        <span>
                           <NavLink exact to="/edutube">Read more...</NavLink>
                        </span>
                     </div>
                  </Col>
                  <Col xs={12} md={6} lg={3}>
                     <div className='left-block p-3 product' data-aos="fade-up"
                        data-aos-duration="3000">
                        <img src={pr4} alt="pr1" />
                        <h3>Community Clinic Based MIS</h3>
                        <p> EATL developed database management system and dynamic website (Content management System-CMS)... </p>
                        <span>
                           <NavLink exact to="/clinic-based-mis">Read more...</NavLink>
                        </span>
                     </div>
                  </Col>
                  <Col md={12} className='text-center mt-4' data-aos="fade-up"
                     data-aos-duration="3000">
                           <NavLink exact to="/products-list">
                           <Button variant="primary btn-custom mt-0 btn-big rounded-pill">
                           View All Products
                        <FaArrowRight />
                     </Button>
                           </NavLink>
                   
                  </Col>
               </Row>
            </Container>
         </section>
         {/* end eatl Products */}

         {/* company-area */}
         <section className='company-area'>
            <Container>
               <Row className='column-reverse2'>
                  <Col lg={6} className='text-start'>
                     <section className='work-process'>
                        <h4 className='mt-3' data-aos="flip-up">Our Clients</h4>
                        <h2 className='mb-2' data-aos="flip-up">Client Success Story</h2>
                        <h5>EFDMS Project, NBR.</h5>
                        <p className='p-text-justi'> 
                        “Ethics Advance Technology Ltd (EATL) is an active member in the JV, and jointly developed the micro-service based EFDMS system. The JV has deployed the EFDMS sustem in the national data center and the service was officially launched on 25th August 2020.” – Mohammad Shafiqur Rahman, System Manager, National Board of Revenue (NBR). 
                        </p>

                        <p className='p-text-justi'> 
                       E-Library management and web based medical student facilities management Project, DGME.
“EATL has been maintaining this application with expertise and skills of their well-equipped technical resources. We wish EATL all the best and every success.” – Prof. Dr. Md. Al-Amin Mridha, Line Director, ME & HMD, DGME. 

                        </p>
                        <p className='p-text-justi'> 
                        	Project of Development of Web Based Automation System, DIA.
“EATL has successfully completed all modules for system and provided maintenance and support service for 3 years up to September, 2021. We wish EATL all the best and every success in future.” – Prof. Waliullah Md. Ajmogir, Director, Directorate of Infection and Audit (DIA)

                        </p>

                        <p className='p-text-justi'> 
                        	EMS Project, National University, Bangladesh.
“EATL team has completed the software development and testing phase and handed over the software applications to NU IT department. So far we are very much pleased with their quality of work and commitment.” – Abu Hanif, Director (Incharge), Engineering Department, NU.

                        </p>

                        <p className='p-text-justi'> Project of Online Certificate Course on Medical Education (e-CMEd), CME/WHO. 
“We are fully satisfied with the services provided by EATL and we wish them every success.” – Prof. Dr. Md. Humayun Kabir Talukder, Professor (Curriculum Development & Evaluation), Course Co-ordinator (e-CMEd), CME.

                        </p>
                     </section>
                  </Col>
                  <Col lg={6} className='text-start pt-5'>
                     <div className="rs-animation-image">
                        <div className="pattern-img"> <img src={round} alt="img" /> </div>
                        <div className="middle-img"> <img class="dance3" src={about1} alt="img" /> </div>
                     </div>
                  </Col>
               </Row>
            </Container>
         </section>
         {/*end  company-area */}

         {/* vblog */}
         <section className='vblog'>
            <Container>
               <Row className='ms-0'>
                  <div className='vblog-inner'>
                     <Col md={12} className='text-center mt-5'>
                        <h3 data-aos="flip-up">Launching Ceremony of EATL-Prothom Alo <br />
                           Apps Contest 2016
                        </h3>
                     </Col>
                     <iframe data-aos="flip-up" src="https://www.youtube.com/embed/CIxq1yYi64o?si=btGvur7v5-tOjHNw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                     {/*
               <Col md={12} className='text-center mt-5' data-aos="fade-up"
                  data-aos-duration="3000">
               <Button variant="primary btn-custom mt-0 btn-big rounded-pill">
                  View all
                  <FaArrowRight/>
               </Button>
               </Col>
               */}
                  </div>
               </Row>
            </Container>
         </section>
         {/* end vblog */}

         {/* Partners */}
         <section className='client-bg bg-white'>
            <Col md={12} className='text-center mb-5'>
               <h4 className='mt-3' data-aos="flip-up"> Partners & Clients</h4>
            </Col>
            <Container>
               <OwlCarousel className='owl-theme slider-custom slider-custom-two slide-img2' {...options} loop margin={3} nav>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={client0} alt="client0" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={client1} alt="client1" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={client2} alt="client2" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={client4} alt="client3" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={client5} alt="client5" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={client6} alt="client6" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={client7} alt="client7" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={client8} alt="client8" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={client9} alt="client9" /> </div>
                     </div>
                  </div>

                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clee} alt="client0" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={cld} alt="client1" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clc} alt="client2" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={cle} alt="client3" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={cla} alt="client5" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clb} alt="client6" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clf} alt="client7" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clff} alt="client8" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clg} alt="client9" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clh} alt="client9" /> </div>
                     </div>
                  </div>



                  <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpa} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpb} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpc} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpd} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpe} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpf} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpg} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cph} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpi} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpj} alt="client9" /> </div>
               </div>
            </div>



            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpk} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpl} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpm} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpn} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpo} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpp} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpq} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpr} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cps} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpt} alt="client9" /> </div>
               </div>
            </div>


            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpu} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpv} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpw} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpx} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpy} alt="client9" /> </div>
               </div>
            </div>
            <div class='item'>
               <div className="company-slide">
                  <div className="slider-icon2"> <img src={cpz} alt="client9" /> </div>
               </div>
            </div>

                  
               </OwlCarousel>
            </Container>
         </section>
         {/* end Partners */}


         {/* Clients */}
         {/* <section className='client-bg partners'>
            <Col md={12} className='text-center mb-5'>
               <h4 className='mt-3 text-white' data-aos="flip-up"> Clients</h4>
            </Col>
            <Container>
               <OwlCarousel className='owl-theme slider-custom slider-custom-two slide-img2' {...optionsp} loop margin={3} nav>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clee} alt="client0" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={cld} alt="client1" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clc} alt="client2" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={cle} alt="client3" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={cla} alt="client5" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clb} alt="client6" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clf} alt="client7" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clff} alt="client8" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clg} alt="client9" /> </div>
                     </div>
                  </div>
                  <div class='item'>
                     <div className="company-slide">
                        <div className="slider-icon2"> <img src={clh} alt="client9" /> </div>
                     </div>
                  </div>
               </OwlCarousel>
            </Container>
         </section> */}
         {/*end Clients */}
      </Common>
   );
};
export default Home;