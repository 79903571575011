import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import hrm1 from '../../../assets/images/hrm1.png';
import hrm2 from '../../../assets/images/hrm2.png';
const HRMS = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>HRMS</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Products</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={6} data-aos="fade-right">
                        <div className='about-company mt-70 left-0'>
                            <h5>HRMS</h5>
                            <p className='line-h'>
                                A Human Resource Management System (HRMS) or Human Resource Information System (HRIS), refers to the systems and processes at the intersection between human resource management (HRM) and information technology.


                            </p>
                        </div>

                    </Col>
                    <Col md={6} data-aos="fade-left">

                        <Row className='mt-70'>
                            <Col md={6}>
                                <img className='services-box' src={hrm1} alt="hrm1" />
                            </Col>
                            <Col md={6}>
                                <img className='services-box' src={hrm2} alt="hrm2" />
                            </Col>

                        </Row>
                    </Col>
                    <Col md={12} data-aos="fade-left">
                        <div className=' '>
                            <h5 class="mt-5">Features :</h5>
                            <ul className='list-style-one mt-2'>
                                <li>Administration Module: The admin module is the central controller of the system where the HR Manager or other appointed personnel perform all system administration tasks. This includes defining organization structure‚ pay scale, maintaining project information and other information that serves as the backbone for the rest of the system. Security issues are taken care of through this module as well by defining user rights, roles and permissions. </li>
                                <li>Personal Information Management Module: A complete employee information management system which acts as a central employee database. This enables HR administrators to utilize all employee information productively. </li>
                                <li>Leave Module: A comprehensive holiday management module‚ with extensive possibilities of defining leave types and more. It caters for all leave application and approval processes and is able to display information on leave entitlement‚ balance‚ history etc.. Thanks to the web-enabled and self-service concepts‚ it significantly streamlines all leave related procedures‚ eliminates paperwork and saves cost. </li>
                                <li>Attendance Module: This module automates timekeeping related processes. The offered functionality enhances the organization's performance by eliminating paperwork and manual processes associated with project timekeeping and attendance management. The sophisticated module helps to efficiently organize labor data‚ improve the workforce management and minimize errors in enforcement of company's attendance policies. </li>
                                <li>Transfer Module: This module tracks each employee’s transfer related information. The system can easily track and record all transfer information of any particular employee. </li>
                                <li>Report Module: Personalized report for each employee information, attendance etc. </li>
                                <li>Pay Scale Module: This module handles with the employees payment, increment and other monetary issues. </li>
                                <li>Award and Punishment Module: This module handles all award and punishment related activities of the HRMIS. </li>
                                <li>Recruitment/ Applicant Tracking Module: A comprehensive solution for the entire recruitment process. The module allows HR professionals to streamline the entire applicant tracking process effectively. </li>
                                <li>Performance Module: This module simplifies the performance review process. Key performance indicators are communicated for each job title via this module, allowing the employees to understand and achieve the expectations on their job roles. </li>
                            </ul>
                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default HRMS;