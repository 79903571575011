import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import ns2 from '../../../assets/images/ns2.jpg';



const DigitalBook = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={6} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>নতুন বছরে ডিজিটাল বই পাচ্ছেন স্কুল শিক্ষার্থীরা</h5>
            <span className='date-title'>Dated : 02 August, 2015</span>
            <p className='line-h'>
            A girl of class five student, expressed her feelings of astonishment, on watching the beautiful animations made on the topic of her books. She expressed that the science based animations were so beautiful that she cannot imagine all these hard core topic can be such nicely explained, presented in a an interesting video. One parents of jessore Government Girls’ High School said, “Now a days students are mostly engaged with mobile phone at home or free time. Such initiative of government on making beautiful animation video content lectures will certainly enhance their knowledge giving more option to learn though mobile and internet in their free time.” She really appreciated the idea.
            </p>
         </div>
         </Col>
         <Col md={6} data-aos="fade-left">
         <img className='news-details' src={ns2} alt="com" />
         </Col>
      </Row>

   </Container>
</Common>
);
};
export default DigitalBook;