import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../layouts/Common";
// import Breadcrumb from 'react-bootstrap/Breadcrumb';
import EatlbdProfile from '../../assets/images/eatlbd-profile.pdf';


const CompanyProfile = () => {
return (
<Common>
<section className='sub-page-banner com-profile'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Company Profile</h2>
            
            </Col>
         </Row>
      </Container>
   </section>
<Container className='mb-5'>
      <Row>
         
         <a
        href={EatlbdProfile}
        download="eatlbd-profile"
        target="_blank"
        rel="noreferrer"
      >
        <button className='btn mt-3 mb-2 btn-pdf'>Download Company Profile</button>
      </a>
      
      <iframe className='iframe' src='https://eatlbd.com/eatlbd-profile.pdf' ></iframe>
      </Row>
   </Container>
</Common>
);
};
export default CompanyProfile;