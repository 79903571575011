import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../layouts/Common";
import { NavLink } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import wordb from '../../assets/images/wordb.jpg';
// import project_nu from '../../assets/images/project_nu.jpg';
import pr3 from '../../assets/images/pr3.jpg';
import project_nbr from '../../assets/images/project_nbr.jpg';
// import project_dghs from '../../assets/images/project_dghs.jpg';
import lms from '../../assets/images/lms.png';
import UCEP from '../../assets/images/UCEP.png';
import placeholder from '../../assets/images/placeholder.png';
import efdms from '../../assets/images/nbrnew.jpg.png';
import elibrary from '../../assets/images/e-library.png';
import nu from '../../assets/images/nu.png';
import ieims from '../../assets/images/ieims.png';
import lmsnew from '../../assets/images/lmsnew.png';
import dghs from '../../assets/images/dghs.jpg';
import dia from '../../assets/images/dia.png';
import sesip from '../../assets/images/sesip.png';
import cme from '../../assets/images/cme.png';

const Projects = () => {
   return (
      <Common>
         <section className='sub-page-banner'>
            <Container>
               <Row>
                  <Col md={12} className='text-center sup-page-inner'>
                     <h2>Projects </h2>
                     <Breadcrumb className='text-center custom-breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Projects</Breadcrumb.Item>
                     </Breadcrumb>
                  </Col>
               </Row>
            </Container>
         </section>
         <Container className='mb-5 pt-5 mt-5 mtpt-custom'>
            <Row className='justify-content-md-center ms-0 project-box'>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={efdms} alt="wordb" />
                     <div className='ds-part'>
                        <h3>EFDMS</h3>
                        <p>
                           EATL-SZZT-KMMT-Synesis IT JV started a nationwide project titled "Supply, Installation and Commissioning and Operation of Electronic Fiscal Device Management Systems (EFDMS) along with Electronic Fiscal Device (EFD), ....
                        </p>
                     </div>
                     <span>
                        <NavLink exact to="/efdms">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={elibrary} alt="placeholder" />
                     <div className='ds-part'>
                        <h3>Establish e-Library Management and Set up web based Medical Student Facilities Management</h3>
                        <p>
                           EATL started the &quot;Establish e-Library Management and
                           Set Up Web Based Medical Student Facilities
                           Management&quot;...

                        </p>
                     </div>

                     <span>
                        <NavLink exact to="/e-library">Read more...</NavLink>
                     </span>
                  </div>
               </Col>

               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-left"
                     data-aos-duration="3000">
                     <img src={nu} alt="nu" />
                     <div className='ds-part'>
                        <h3>Development & Implementation of Examination Management System for National University</h3>
                        <p>
                           “NU EMS” –  In May 2019, EATL began the project "Development and Implementation of Examination Management System for National University", which was successfully completed in June 2021....
                        </p>
                     </div>

                     <span>
                        <NavLink exact to="/national-university">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-left"
                     data-aos-duration="3000">
                     <img src={ieims} alt="placeholder" />
                     <div className='ds-part'>
                        <h3>IEIMS</h3>
                        <p>
                           EATL started the "Software design and development for Educational Information Management to be used by MOE, BANBEIS, DSHE, DTE, DME, NTRCA and others with maintenance support for 3 (three) years...


                        </p>
                     </div>

                     <span>
                        <NavLink exact to="/ieims">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={wordb} alt="wordb" />
                     <div className='ds-part'>
                        <h3>ICT-based Students Competitions</h3>
                        <p>
                           The purpose of edutubebd is to remove every barrier between a similar level students in Bangladesh so...
                        </p>
                     </div>
                     <span>
                        <NavLink exact to="/competitions">Read more...</NavLink>
                     </span>
                  </div>
               </Col>

               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={lmsnew} alt="lmsnew" />
                     <div className='ds-part'>
                        <h3>LMS</h3>
                        <p>
                           In 2023, EATL also began the project "Design, Development, Implementation and Maintenance of Learning Management System (LMS)
                           ............

                        </p>
                     </div>
                     <span>
                        <NavLink exact to="/lms">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-left"
                     data-aos-duration="3000">
                     <img src={dghs} alt="project_dghs" />
                     <div className='ds-part'>
                        <h3>DGHS</h3>
                        <p>
                           EATL completed the "Establishing Digital Birth and Death Notification System and Capacity Building in Health System for Strengthening EPI and CVRS in Bangladesh" project for MIS of Directorate General of Health Services (DGHS)
                        </p>
                     </div>

                     <span>
                        <NavLink exact to="/dghs">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-left"
                     data-aos-duration="3000">
                     <img src={dia} alt="dia" />
                     <div className='ds-part'>
                        <h3>DIA</h3>
                        <p>
                           In Dec 2019, EATL began the project "Development of Web Based Automation System" for the Educational Institutions & Directorate of Inspection and Audit (DIA),

                        </p>
                     </div>

                     <span>
                        <NavLink exact to="/dia">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={sesip} alt="lms" />
                     <div className='ds-part'>
                        <h3>SESIP</h3>
                        <p>
                           EATL completed the educational content development project for the Secondary Education Sector Investment Program (SESIP) named "Development Of E-Learning Modules for Six Subjects" ...
                        </p>
                     </div>
                     <span>
                        <NavLink exact to="/sesip">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={cme} alt="cme" />
                     <div className='ds-part'>
                        <h3>Support conducting online teaching-learning of undergraduate students of the Government Medical Colleges in Bangladesh</h3>
                        <p>
                           EATL successfully completed the "Support conducting online teaching-learning of undergraduate students of the Government Medical Colleges in Bangladesh" project for the Centre for Medical Education (CME) in March 2021.                         </p>
                     </div>
                     <span>
                        <NavLink exact to="/cme">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-left"
                     data-aos-duration="3000">
                     <img src={lms} alt="lms" />
                     <div className='ds-part'>
                        <h3>College Education
                           Development Project (CEDP)</h3>
                        <p>
                           Online Training Consulting Firm for Subject
                           Based Training...
                        </p>
                     </div>
                     <span>
                        <NavLink exact to="/">Read more...</NavLink>
                     </span>
                  </div>
               </Col>

               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-left"
                     data-aos-duration="3000">
                     <img src={placeholder} alt="placeholder" />
                     <div className='ds-part'>
                        <h3>DGME</h3>
                        <p>
                           4. Procurement of / Burn, Casualty, Diabetic Foot
                           Care, Chest Tube, IVF, Sexual Assult Etc.
                           Manikin, Model and Trainers for MC...
                        </p>
                     </div>

                     <span>
                        <NavLink exact to="/">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={UCEP} alt="lms" />
                     <div className='ds-part'>
                        <h3>UCEP</h3>
                        <p>
                           Development web-based Program Management
                           Information System (PMIS) of UCEP Bangladesh...
                        </p>
                     </div>

                     <span>
                        <NavLink exact to="/">Read more...</NavLink>
                     </span>
                  </div>
               </Col>

               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-right"
                     data-aos-duration="3000">
                     <img src={pr3} alt="pr1" />
                     <div className='ds-part'>
                        <h3>Edutube</h3>
                        <p>
                           Ethics Advanced Technology Limited (EATL) has launched Bangladesh First educational content sharing portal
                           <NavLink exact to="www.edutubebd.com"> dwww.edutubebd.com</NavLink>
                           on 11...
                        </p>
                     </div>

                     <span>
                        <NavLink exact to="/edutube">Read more...</NavLink>
                     </span>
                  </div>
               </Col>


               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-left"
                     data-aos-duration="3000">
                     <img src={placeholder} alt="placeholder" />
                     <div className='ds-part'>
                        <h3>CVRS</h3>
                        <p>
                           Establishing Digital Birth and Death Notification
                           System and Capacity Building in Health System
                           for Strengthening EPI and CVRS in Bangladesh

                        </p>
                     </div>

                     <span>
                        <NavLink exact to="/">Read more...</NavLink>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6} lg={3}>
                  <div className='left-block p-3 product' data-aos="fade-left"
                     data-aos-duration="3000">
                     <img src={project_nbr} alt="project_nbr" />
                     <div className='ds-part'>
                        <h3>NBR</h3>
                        <p>
                           Supply, Installation and Commissioning and
                           Operation of Electronic Fiscal Device
                           Management Systems (EFDMS) along with
                           Electronic Fiscal Device (EFD), Sales Data
                           Controller (SDC) and Managed Services

                        </p>
                     </div>

                     <span>
                        <NavLink exact to="/nbr">Read more...</NavLink>
                     </span>
                  </div>
               </Col>

            </Row>
         </Container>
      </Common>
   );
};
export default Projects;