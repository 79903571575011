import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import nua from '../../../assets/images/nua.jpg';



const MouSignedNsu = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={8} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>MOU signed with NSU</h5>
            <span className='date-title'>Dated : 04 January, 2014</span>
            <p className='line-h'>
            EATL has signed an agreement with North South University (NSU) – The top private university in Bangladesh, for collaboration in ICT and its development. It was signed in NSU VC conference room in presence of Prof. Dr. Amin U. Sarkar, Vice Chancellor & CEO, Prof. Dr. Miftahur Rahman, Dean, School of Engineering & Applied Sciences, Assoc. Prof. Dr. Syed Saad, Dean, School of Business, Dr. Kazi Abu Sayeed, Proctor of university, Dr. Kazi MA Salam, Assoc. Prof. & Chairman, Dr. Rajesh Palit, Asst. Prof. of CSC, Mr. M A Mubin Khan, Chairman, EATL and Dr. Nizam Uddin Ahmed, CEO, EATL and other professionals. This is an excellent collaboration between academia and ICT industry (EATL) for engagement of students and teachers in training, mobile apps development, Research & Development activities. EATL and NSU will work together for utilization of knowledge and skills in socio-economic development and supporting business sectors.
            </p>
         </div>
         </Col>
         <Col md={4} data-aos="fade-left">
         <img className='news-details' src={nua} alt="com" />
         </Col>
      </Row>

   </Container>
</Common>
);
};
export default MouSignedNsu;