import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import dia from '../../../assets/images/dia.png';

const Dia = () => {
   return (
      <Common>
         <section className='sub-page-banner'>
            <Container>
               <Row>
                  <Col md={12} className='text-center sup-page-inner'>
                     <h2>Development of Web Based Automation System for the Educational Institutions & Directorate of Inspection and Audit (DIA)</h2>
                     <Breadcrumb className='text-center custom-breadcrumb'>
                        <Breadcrumb.Item>Home</Breadcrumb.Item>
                        <Breadcrumb.Item>Projects</Breadcrumb.Item>
                     </Breadcrumb>
                  </Col>
               </Row>
            </Container>
         </section>
         <Container className='mb-5'>
            <Row>
               <Col md={8}>
                  <div className='about-company mt-70 left-0' data-aos="fade-right">
                     <h5>
                        Development of Web Based Automation System for the Educational Institutions & Directorate of Inspection and Audit (DIA)            </h5>
                     <p>
                        In Dec 2019, EATL began the project "Development of Web Based Automation System" for the Educational Institutions & Directorate of Inspection and Audit (DIA), which was successfully completed in March 2020. Through this automated system, DIA can monitor the overall activities of the 37,000 schools, colleges and educational institutions centrally for improvement of the quality education.                     </p>

                     <h5 className='mt-4'>Name of Client:</h5>
                     <p>
                        Directorate of Inspection and Audit (DIA), Ministry of Education
                     </p>

                  </div>

               </Col>
               <Col md={4} data-aos="fade-left">

                  <div className='about-company mt-70'>
                     <img className='nu-all mt-5' src={dia} alt="dia" />

                  </div>
               </Col>

            </Row>
         </Container>
      </Common>
   );
};
export default Dia;