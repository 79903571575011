import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Common from "../../layouts/Common";
import ava from "../../assets/images/ava.png";
import raihan from "../../assets/images/raihan.jpg";
import mohs from "../../assets/images/mohs.jpg";
import tamim from "../../assets/images/tamim.jpg";
import lock from "../../assets/images/lock.jpg";
import san from "../../assets/images/san.jpg";
import mahmudur from "../../assets/images/mahmudur.jpg";
import naj from "../../assets/images/naj.jpg";
import tanim from "../../assets/images/tanim.jpg";
import tonmoy from "../../assets/images/tonmoy.jpg";
import ridwan from "../../assets/images/ridwan.jpg";
import fahim from "../../assets/images/fahim.jpg";
import arifur from "../../assets/images/arifur.jpg";
import sadman from "../../assets/images/sadman.jpg";
import rafat from "../../assets/images/rafat.jpg";
import kausarv from "../../assets/images/kausarv.jpg";
import prangan from "../../assets/images/prangan.jpg";
import sanjida from "../../assets/images/sanjida.jpg";
import selim from "../../assets/images/selim.jpg";
import rifat from "../../assets/images/rifat.jpg";
import masud from "../../assets/images/masud.jpg";
import parvez from "../../assets/images/parvez.jpg";
import ibrahim from "../../assets/images/ibrahim.jpg";
import satu from "../../assets/images/satu.jpg";
import minarul from "../../assets/images/minarul.jpg";
import rasel from "../../assets/images/rasel.jpg";
import sujit from "../../assets/images/sujit.jpg";
import arif from "../../assets/images/arif.jpg";
import arman from "../../assets/images/arman.jpg";
import raju from "../../assets/images/raju.png";
import shahin from "../../assets/images/shahin.jpg";
import noman from "../../assets/images/noman.jpg";
import salekin from "../../assets/images/salekin.jpg";
import diti from "../../assets/images/diti.jpg";
import shawon from "../../assets/images/shawon.jpg";
import shuvo from "../../assets/images/shuvo.jpg";
import allh from "../../assets/images/allh.jpg";
 import riyad from "../../assets/images/rayed.jpg";
import noor from "../../assets/images/noor.jpg";
import apob1 from "../../assets/images/apob1.jpg";
 import rajes from "../../assets/images/rajes.jpg";
import shab2 from "../../assets/images/shab2.jpg";
import masum from "../../assets/images/masum.jpg";
import sibly from "../../assets/images/sibly.jpg";

// import pabon from "../../assets/images/pabon.jpg";
//import debashis from "../../assets/images/debashis.jpg";
// import anoy from "../../assets/images/anoy.jpg";
//import sondhi from "../../assets/images/sondhi.jpg";
//import obayed from "../../assets/images/obayed.jpg";
//import shifat from "../../assets/images/shifat.jpg";

const Team = () => {
  return (
    <Common>
      <section className="sub-page-banner  team-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <label className="label-team">WE ARE DEDICATED</label>
              <h2>Team Members</h2>
            </Col>
          </Row>
        </Container>
      </section>
      <Container className="mb-5">
        <Row className="justify-content-md-center ms-0-team">
          <Row className="text-center ms-0">
            <Col md={12}>
              <section className="work-process team-top-head">
                <h2 className="team-head mb-4 pb-3" data-aos="flip-up">
                  Technology Department
                </h2>
              </section>
            </Col>
          </Row>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={rajes} alt="team" />
              <div class="figcaption">
                <p> Director, Technology
 </p>
                <h4 class="sx-title">Dr. Rajesh Palit</h4>
              </div>
            </div>
          </Col>
          
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={mohs} alt="team" />
              <div class="figcaption">
                <p> Engineering Manager </p>
                <h4 class="sx-title">Md. Mohsin Ali</h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={raihan} alt="team" />
              <div class="figcaption">
                <p> Sr. Database Administrator </p>
                <h4 class="sx-title">Md. Raihan Sharif Mridha</h4>
              </div>
            </div>
          </Col>

          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={allh} alt="team" />
              <div class="figcaption">
                <p> Sr. Software Engineer </p>
                <h4 class="sx-title">Md. Alhaj Uddin</h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={kausarv} alt="team" />
              <div class="figcaption">
                <p>Sr. Software Engineer </p>
                <h4 class="sx-title">Mahabubur Rahman Kausar</h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={tamim} alt="team" />
              <div class="figcaption">
                <p>Sr. Software Engineer</p>
                <h4 class="sx-title"> Takbirul Haque Tamim</h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={mahmudur} alt="team" />
              <div class="figcaption">
                <p>Sr. Software Engineer </p>
                <h4 class="sx-title">Mahmudur Rahman</h4>
              </div>
            </div>
          </Col>
  
     
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={lock} alt="team" />
              <div class="figcaption">
                <p>Sr. Software Engineer </p>
                <h4 class="sx-title">Mohammad Lockman</h4>
              </div>
            </div>
          </Col>  
          
                
          {/* <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={debashis} alt="team" />
              <div class="figcaption">
                <p> BI & Database Administrator </p>
                <h4 class="sx-title">Debashis Kumar Dakua</h4>
              </div>
            </div>
          </Col> */}
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={san} alt="team" />
              <div class="figcaption">
                <p>Sr. Software Engineer </p>
                <h4 class="sx-title">Md. Sanowar Ali</h4>
              </div>
            </div>
          </Col>
     

          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={naj} alt="team" />
              <div class="figcaption">
                <p>Software Engineer </p>
                <h4 class="sx-title">Khondokar Nazran Ahmod</h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={noor} alt="team" />
              <div class="figcaption">
                <p>Software Engineer  </p>
                <h4 class="sx-title">Md. Noor Alam
</h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={ridwan} alt="team" />
              <div class="figcaption">
                <p>Software Engineer </p>
                <h4 class="sx-title">Ridwanur Rahim
</h4>
              </div>
            </div>
          </Col>
       
          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={shawon} alt="team" />
              <div class="figcaption">
                <p>Software Engineer  </p>
                <h4 class="sx-title">Enamul Haque</h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={tonmoy} alt="team" />
              <div class="figcaption">
                <p>Software Engineer </p>
                <h4 class="sx-title">Tanmoy Tushar</h4>
              </div>
            </div>
          </Col>

          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={tanim} alt="team" />
              <div class="figcaption">
                <p>Software Engineer </p>
                <h4 class="sx-title">Md. Manirul Islam</h4>
              </div>
            </div>
          </Col>

     
     

          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={ava} alt="team" />
              <div class="figcaption">
                <p>Software Engineer  </p>
                <h4 class="sx-title">Ummey Kulsum
</h4>
              </div>
            </div>
          </Col>
          
          {/* <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={obayed} alt="team" />
              <div class="figcaption">
                <p>Software Engineer  </p>
                <h4 class="sx-title">Obayed Bin Mahfuz


</h4>
              </div>
            </div>
          </Col> */}
          {/* <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={anoy} alt="team" />
              <div class="figcaption">
                <p>Software Engineer  </p>
                <h4 class="sx-title">Anoy Chowdhury


</h4>
              </div>
            </div>
          </Col> */}

          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={fahim} alt="team" />
              <div class="figcaption">
                <p>Software Engineer  </p>
                <h4 class="sx-title">A. R. M. Fahim

</h4>
              </div>
            </div>
          </Col>
          
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={shuvo} alt="team" />
              <div class="figcaption">
                <p>Software Engineer  </p>
                <h4 class="sx-title">Golam Mahiuddin

</h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={raju} alt="team" />
              <div class="figcaption">
                <p>Software Engineer </p>
                <h4 class="sx-title">Md. Moktadirul Islam ( Raju )</h4>
              </div>
            </div>
          </Col>
          
          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={arifur} alt="team" />
              <div class="figcaption">
                <p>Software Engineer  </p>
                <h4 class="sx-title">Arifur Rahman</h4>
              </div>
            </div>
          </Col>

          {/* <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={sondhi} alt="team" />
              <div class="figcaption">
                <p>Software Engineer (Mobile Application Developer)</p>
                <h4 class="sx-title">Md. Jubier Ali</h4>
              </div>
            </div>
          </Col> */}
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={ riyad} alt="team" />
              <div class="figcaption">
                <p>System Administrator & DevOps</p>
                <h4 class="sx-title">Zillur Rahman Riyad</h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={masum} alt="team" />
              <div class="figcaption">
                <p>Jr. Software Engineer </p>
                <h4 class="sx-title">Mehedi Hasan Masum</h4>
              </div>
            </div>
          </Col>

          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={ava} alt="team" />
              <div class="figcaption">
                <p>Jr. Software Engineer </p>
                <h4 class="sx-title">S.M. Tanvir Siddique
</h4>
              </div>
            </div>
          </Col>

          {/* <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={shifat} alt="team" />
              <div class="figcaption">
                <p>Jr. Software Engineer </p>
                <h4 class="sx-title">Md. Abu Naeem Sifat

</h4>
              </div>
            </div>
          </Col> */}
          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={rafat} alt="team" />
              <div class="figcaption">
                <p>Jr. Software Engineer </p>
                <h4 class="sx-title">Tanvir Mahtab Rafat


</h4>
              </div>
            </div>
          </Col>
    
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={sadman} alt="team" />
              <div class="figcaption">
                <p>Jr. Software Engineer </p>
                <h4 class="sx-title">Sadman Sakib
</h4>
              </div>
            </div>
          </Col>

          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={prangan} alt="team" />
              <div class="figcaption">
                <p>Jr. Software Engineer </p>
                <h4 class="sx-title">Prangan Sen

</h4>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="justify-content-md-center ms-0-team">
          <Row className="text-center ms-0">
            <Col md={12}>
              <section className="work-process team-top-head">
                <h2 className="team-head mb-4 pb-3" data-aos="flip-up">
                Design Department

                </h2>
              </section>
            </Col>
          </Row>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={apob1} alt="team" />
              <div class="figcaption">
                <p>Head of Graphics </p>
                <h4 class="sx-title">Apurba Roy </h4>
              </div>
            </div>
          </Col>

          {/* <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={apob} alt="team" />
              <div class="figcaption">
                <p>Head of Graphics </p>
                <h4 class="sx-title">Apurba Roy </h4>
              </div>
            </div>
          </Col> */}
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={masud} alt="team" />
              <div class="figcaption">
                <p>Lead UI/UX Designer</p>
                <h4 class="sx-title">Md. Masud Ahmed</h4>
              </div>
            </div>
          </Col> 

          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={sanjida} alt="team" />
              <div class="figcaption">
                <p>Sr. UI/UX Designer</p>
                <h4 class="sx-title">Sanjida Sultana</h4>
              </div>
            </div>
          </Col>


          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={arman} alt="team" />
              <div class="figcaption">
                <p>Video Editor & Cinematographer</p>
                <h4 class="sx-title">Md Aminur Rahman</h4>
              </div>
            </div>
          </Col>

        </Row>

        <Row className="justify-content-md-center ms-0-team">
          <Row className="text-center ms-0">
            <Col md={12}>
              <section className="work-process team-top-head">
                <h2 className="team-head mb-4 pb-3" data-aos="flip-up">
                SQA Department
                </h2>
              </section>
            </Col>
          </Row>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={selim} alt="team" />
              <div class="figcaption">
                <p>Sr. SQA Engineer </p>
                <h4 class="sx-title">Selim Ahmed </h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={salekin} alt="team" />
              <div class="figcaption">
                <p>SQA Engineer</p>
                <h4 class="sx-title">Md. Sirajus Salekin</h4>
              </div>
            </div>
          </Col>

          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={rifat} alt="team" />
              <div class="figcaption">
                <p>SQA Engineer</p>
                <h4 class="sx-title">Refat Mahmuda</h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={diti} alt="team" />
              <div class="figcaption">
                <p>SQA Engineer</p>
                <h4 class="sx-title">Ruvina Parvin Dity</h4>
              </div>
            </div>
          </Col>
          
        </Row>


        <Row className="justify-content-md-center ms-0-team">
          <Row className="text-center ms-0">
            <Col md={12}>
              <section className="work-process team-top-head">
                <h2 className="team-head mb-4 pb-3" data-aos="flip-up">
                Marketing & Business Development 
                </h2>
              </section>
            </Col>
          </Row>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={sibly} alt="team" />
              <div class="figcaption">
                <p>Sr. General Manager </p>
                <h4 class="sx-title">Sharif Shibly Sadique </h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={parvez} alt="team" />
              <div class="figcaption">
                <p>Manager, Event and Communication </p>
                <h4 class="sx-title">Parvez Ahmed </h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={shahin} alt="team" />
              <div class="figcaption">
                <p>Sr. Manager, Business Development </p>
                <h4 class="sx-title">Mohammad Shahin Raza</h4>
              </div>
            </div>
          </Col>

          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={ibrahim} alt="team" />
              <div class="figcaption">
                <p>Asst. Manager, Business Development </p>
                <h4 class="sx-title">Md. Ibrahim Khandaker</h4>
              </div>
            </div>
          </Col>

          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={noman} alt="team" />
              <div class="figcaption">
                <p>Digital Marketing & Branding</p>
                <h4 class="sx-title">Abdullah Al Noman</h4>
              </div>
            </div>
          </Col>
          
        </Row>


        <Row className="justify-content-md-center ms-0-team">
          <Row className="text-center ms-0">
            <Col md={12}>
              <section className="work-process team-top-head">
                <h2 className="team-head mb-4 pb-3" data-aos="flip-up">
                HR & Admin Department
                </h2>
              </section>
            </Col>
          </Row>
          {/* <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={shob} alt="team" />
              <div class="figcaption">
                <p>Manager, HR & Admin </p>
                <h4 class="sx-title">Moeen Uddin Sabuz </h4>
              </div>
            </div>
          </Col> */}
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={shab2} alt="team" />
              <div class="figcaption">
                <p>Manager, HR & Admin </p>
                <h4 class="sx-title">Moeen Uddin Sabuz </h4>
              </div>
            </div>
          </Col>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={satu} alt="team" />
              <div class="figcaption">
                <p>Communication Officer </p>
                <h4 class="sx-title">Sharmila Nasrin Satu</h4>
              </div>
            </div>
          </Col>

          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={minarul} alt="team" />
              <div class="figcaption">
                <p>Office Assistant </p>
                <h4 class="sx-title">Md. Minarul Haque</h4>
              </div>
            </div>
          </Col>
          
          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={rasel} alt="team" />
              <div class="figcaption">
                <p>Office Assistant Computer Operator</p>
                <h4 class="sx-title">Md. Rasel Mia</h4>
              </div>
            </div>
          </Col>
          
        </Row>

        <Row className="justify-content-md-center ms-0-team">
          <Row className="text-center ms-0">
            <Col md={12}>
              <section className="work-process team-top-head">
                <h2 className="team-head mb-4 pb-3" data-aos="flip-up">
                Accounts & Finance Department
                </h2>
              </section>
            </Col>
          </Row>
          <Col md={3} data-aos="fade-right">
            <div className="team-block">
              <img src={sujit} alt="team" />
              <div class="figcaption">
                <p>Coordinator
 </p>
                <h4 class="sx-title">Sujit Kumar Saha
 </h4>
              </div>
            </div>
          </Col>
         
          <Col md={3} data-aos="fade-left">
            <div className="team-block">
              <img src={arif} alt="team" />
              <div class="figcaption">
                <p>Asst. Manager </p>
                <h4 class="sx-title">Md. Ariful Islam
</h4>
              </div>
            </div>
          </Col>
         
          
        </Row>


      </Container>
    </Common>
  );
};
export default Team;
