import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import ieims from '../../../assets/images/ieims.png';

const IEIMS = () => {
    return (
        <Common>
            <section className='sub-page-banner'>
                <Container>
                    <Row>
                        <Col md={12} className='text-center sup-page-inner'>
                            <h2>Software design and development for Educational Information Management to be used by MOE, BANBEIS, DSHE, DTE, DME, NTRCA and others with maintenance support for 3 (three) years</h2>
                            <Breadcrumb className='text-center custom-breadcrumb'>
                                <Breadcrumb.Item>Home</Breadcrumb.Item>
                                <Breadcrumb.Item>Projects</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Container className='mb-5'>
                <Row>
                    <Col md={8}>
                        <div className='about-company mt-70 left-0' data-aos="fade-right">
                            <h5>Software design and development for Educational Information Management to be used by MOE, BANBEIS, DSHE, DTE, DME, NTRCA and others with maintenance support for 3 (three) years</h5>
                            <p>
                                {/* EATL started the "Software design and development for Educational Information Management to be used by MOE, BANBEIS, DSHE, DTE, DME, NTRCA and others with maintenance support for 3 (three) years" project for IEIMS, Bangladesh Bureau of Educational Information & Statistics (BANBEIS), MoE on February 2021. Total value of this project is $535 Thousand USD. */}
                    
                                BANBEIS, being a government organization in the education sector, has a mandate of establishing Integrated EIMS (IEIMS) through integration and storing of data of all stakeholders in the education sector. The project outlined for establishing an online automated and integrated education management system for Ministry of Education (Secondary and Higher Education Division and Madrasah and Technical Education Division), Directorate of Secondary and Higher Education (DSHE), Directorate of Technical Education (DTE), Directorate of Madrasah Education (DME), Directorate of Inspection & Audit (DIA), Bangladesh Bureau of Educational Information and Statistics (BANBEIS), nine general Boards of Intermediate and Secondary Education (BISEs), Bangladesh Madrasah Education board (BMEB), Bangladesh Technical Education Board (BTEB), District level and Upazila level Education Offices, Schools, Colleges, Madrasahs and Technical Education Institutions. There are 34 modules in IEIMS project and EATL is developing 13 modules that is known as Lot 3. The main objectives of the project are
                    </p>


                    <Col md={12} data-aos="fade-left">
                        <div className=' '>
                            
                            <ul className='list-style-one mt-2'>
                                <li>Facilitate data collection from educational institutions under the organization mentioned above</li>
                                <li>Manage overall activities of the institutions such as attendance and result processing  </li>
                                <li>Summary data and analytics generation for the education managers</li>
                                <li>Data exchange among the stakeholders</li>
                                <li>Creating a central repository of data at BANBEIS</li>
                                
                            </ul>
                        </div>
                    </Col>

                            <h5 className='mt-4'>Name of Client:</h5>
                            <p>
                                IEIMS, Bangladesh Bureau of Educational Information & Statistics (BANBEIS), MoE
                            </p>

                        </div>

                    </Col>
                    <Col md={4} data-aos="fade-left">

                        <div className='about-company mt-70'>
                            <img className='nu-all mt-5' src={ieims} alt="ieims" />

                        </div>
                    </Col>

                </Row>
            </Container>
        </Common>
    );
};
export default IEIMS;