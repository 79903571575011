import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Common from "../../../layouts/Common";
import ResponsiveGallery from "react-responsive-gallery";
import brc1 from "../../../assets/images/brc1.jpg";
import brc2 from "../../../assets/images/brc2.jpg";
import brc3 from "../../../assets/images/brc3.jpg";
import brc4 from "../../../assets/images/brc4.jpg";
import brc5 from "../../../assets/images/brc5.jpg";
import brc6 from "../../../assets/images/brc6.jpg";





const BracUn = () => {
  return (
    <Common>
      <section className="sub-page-banner newsr-bg">
        <Container>
          <Row>
            <Col md={12} className="text-center sup-page-inner">
              <h2>Media section</h2>
              <Breadcrumb className="text-center custom-breadcrumb">
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Media section</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <Row className="text-start mt-3">
          <Col md={12}>
            <section className="work-process mt-5">
              <h4 data-aos="flip-up">
              ইএটিএল ও ব্র্যাক ইউনিভার্সিটির মধ্যে চুক্তি
              </h4>
              <label className="text-gray mb-2 w-100">
              Dated : 23 January, 2014
              </label>
            </section>
          </Col>
        </Row>
        <Col md={12} className="mb-5 border-topn pt-3 media-galery">
          <ResponsiveGallery
            useLightBox
            images={[
              {
                src: `${brc1}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 1,
                title:
                "ইএটিএল ও ব্র্যাক ইউনিভার্সিটির মধ্যে চুক্তি",
              },
         
              
              {
                src: `${brc2}`,
                alt: "med",
                orderS: 1,
                orderM: 1,
                orderL: 2,
                title:
                "ইএটিএল ও ব্র্যাক ইউনিভার্সিটির মধ্যে চুক্তি",
              },
              {
                src: `${brc3}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 3,
                title:
                "ইএটিএল ও ব্র্যাক ইউনিভার্সিটির মধ্যে চুক্তি",
              },

              {
                src: `${brc4}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 4,
                title:
                "ইএটিএল ও ব্র্যাক ইউনিভার্সিটির মধ্যে চুক্তি",
              },
              {
                src: `${brc5}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 5,
                // imgClassName: 'img6-style',
                title:
                "ইএটিএল ও ব্র্যাক ইউনিভার্সিটির মধ্যে চুক্তি",
              },

              {
                src: `${brc6}`,
                alt: "gallery",
                orderS: 1,
                orderM: 1,
                orderL: 6,
                // imgClassName: 'img6-style',
                title:
                  "ইএটিএল ও ব্র্যাক ইউনিভার্সিটির মধ্যে চুক্তি",
              },

              
              
            ]}
          />
        </Col>
      </Container>
    </Common>
  );
};

export default BracUn;
