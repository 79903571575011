import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/esm/Col';
import Row from 'react-bootstrap/esm/Row';
import Common from "../../../layouts/Common";
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import devpro from '../../../assets/images/devpro.jpg';



const MouMobileApplicationDevelopment = () => {
return (
<Common>
   <section className='sub-page-banner newsr-bg'>
      <Container>
         <Row>
            <Col md={12} className='text-center sup-page-inner'>
            <h2>Newsroom</h2>
            <Breadcrumb className='text-center custom-breadcrumb'>
               <Breadcrumb.Item>Home</Breadcrumb.Item>
               <Breadcrumb.Item>Newsroom</Breadcrumb.Item>
            </Breadcrumb>
            </Col>
         </Row>
      </Container>
   </section>
   <Container className='mb-5'>
      <Row className='column-reverse2'>
         <Col md={8} data-aos="fade-right">
         <div className='about-company mt-70 left-0'>
            <h5>MOU signing for Mobile Application Development Program</h5>
            <span className='date-title'>Dated : 18 November, 2013</span>
            <p className='line-h'>
            MOU signing ceremony for "Mobile Application Development Program at the national level to increase awareness and knowledge" between MoICT, EATL and MCC was held on 17th November 2013 at Bangladesh computer Council.This project is a complete 360 degree approach to serve all aspect like: 
            </p>
           
            <div className='about-company mt-4 left-0'>
                     
                     <ul className='list-style-one'>
                        <li> Provide training , camping, national level fare and awareness program to create app developer</li>
                        <li>Develop apps based on the idea generated from large government ministries who understand and have the data on what service basically requires automation that can positively change the way we work </li>
                        <li>A national contest for the develop apps, to have more motivation to be a part of this platform — at<br/> <br/><a href='https://www.facebook.com/BDComputerCouncil?ref=stream&viewer_id=100000584337631' target='_blank'>Bangladesh Computer Council - BCC.</a> </li>
                       
                     </ul>
                    
                  </div>
         </div>
         </Col>
         <Col md={4} data-aos="fade-left">
         <img className='news-details' src={devpro} alt="com" />
         </Col>

      </Row>

   </Container>
</Common>
);
};
export default MouMobileApplicationDevelopment;